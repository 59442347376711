/* eslint-disable no-unused-vars */
import {
	AUTH_REQUEST,
	AUTH_ERROR,
	AUTH_SUCCESS,
	AUTH_LOGOUT
} from "../actions/auth";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
// eslint-disable-next-line no-unused-vars
const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3000/api";
// console.log("using base ", API_URL)
import API from "../../lib/backend/axios";

const state = {
	userdata: JSON.parse(localStorage.getItem("userData")) || {},
	token: localStorage.getItem("userToken") || "",
	status: "",
	hasLoadedOnce: false,
	loggedin: false
};

const getters = {
	token: state => state.token,
	clid: state => state.clid,
	isAuthenticated: state => !!state.token,
	authStatus: state => state.status,
	hasLoggedOut: state => state.loggedout
};

const actions = {
	[AUTH_REQUEST]: ({ commit, dispatch }, user) => {
		commit(AUTH_REQUEST);
		return API.post("/login", user)
			.then(resp => {
				if (resp.data.error) {
					return { loginError: resp.data.error };
				} else {
					const token = resp.data;
					// console.log(resp.headers)
					localStorage.setItem("userToken", token);
					const decodedToken = VueJwtDecode.decode(resp.data);
					var userdata = decodedToken.context.user;
					localStorage.setItem("userData", JSON.stringify(userdata)); // store the token in localstorage

					commit(AUTH_SUCCESS, {
						token: token,
						userdata: userdata
					});
					// you have your token, now log in your user :)
					return resp.data;
				}
			})
			.catch(err => {
				commit(AUTH_ERROR, err);
				localStorage.removeItem("userToken"); // if the request fails, remove any possible user token if possible
				throw err.response;
			});
	},
	[AUTH_LOGOUT]: ({ commit, dispatch }) => {
		return new Promise((resolve, reject) => {
			commit(AUTH_LOGOUT);
			console.log("Logged out");
			console.log(state.loggedout);
			localStorage.removeItem("userToken");
			localStorage.removeItem("userData");
			localStorage.removeItem("users");
			localStorage.removeItem("departments");
			localStorage.removeItem("tickets");
			resolve();
		});
	}
};

const mutations = {
	[AUTH_REQUEST]: state => {
		state.status = "loading";
	},
	[AUTH_SUCCESS]: (state, resp) => {
		state.status = "success";
		state.token = resp.token;
		state.loggedin = true;
		state.userdata = resp.userdata;
		state.hasLoadedOnce = true;
	},
	[AUTH_ERROR]: state => {
		state.status = "error";
		state.hasLoadedOnce = true;
	},
	[AUTH_LOGOUT]: state => {
		state.token = "";
		state.userdata = {};
		state.loggedin = false;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
