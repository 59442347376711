var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "mailingsingle",
        "hide-footer": "",
        "hide-header": "",
        size: "xl"
      }
    },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.transmitForm)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container-fluid content" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "dark" },
                          [
                            _vm.maid
                              ? _c("b-col", { staticClass: "headline" }, [
                                  _vm._v(
                                    "Edit Mailing #" +
                                      _vm._s(_vm.mailing.MA_ID) +
                                      " "
                                  )
                                ])
                              : _c("b-col", { staticClass: "headline" }, [
                                  _vm._v(" New Mailing ")
                                ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "3" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      disabled: _vm.expired,
                                      name: "search_hasvoucher",
                                      switch: "",
                                      value: "1",
                                      size: "lg"
                                    },
                                    model: {
                                      value: _vm.searchparams.search_hasvoucher,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchparams,
                                          "search_hasvoucher",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchparams.search_hasvoucher"
                                    }
                                  },
                                  [_vm._v(" With Voucher ")]
                                ),
                                _vm._v(
                                  _vm._s(_vm.searchparams.search_hasvoucher) +
                                    " "
                                )
                              ],
                              1
                            ),
                            _vm.searchparams.search_hasvoucher == "1"
                              ? _c(
                                  "b-col",
                                  { attrs: { sm: "5", lg: "4" } },
                                  [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        disabled: _vm.expired,
                                        dark: "",
                                        size: "sm",
                                        name: "search_startd",
                                        "initial-date":
                                          _vm.searchparams.search_start,
                                        locale: "en",
                                        "date-format-options": {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric"
                                        }
                                      },
                                      model: {
                                        value: _vm.searchparams.search_start,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.searchparams,
                                            "search_start",
                                            $$v
                                          )
                                        },
                                        expression: "searchparams.search_start"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.searchparams.search_hasvoucher == "1"
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "text-center",
                                    attrs: { sm: "1", lg: "1" }
                                  },
                                  [_vm._v(" until ")]
                                )
                              : _vm._e(),
                            _vm.searchparams.search_hasvoucher == "1"
                              ? _c(
                                  "b-col",
                                  { attrs: { sm: "5", lg: "4" } },
                                  [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        disabled: _vm.expired,
                                        dark: "",
                                        name: "search_stopd",
                                        size: "sm",
                                        "initial-date":
                                          _vm.searchparams.search_stop,
                                        locale: "en",
                                        "date-format-options": {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric"
                                        }
                                      },
                                      model: {
                                        value: _vm.searchparams.search_stop,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.searchparams,
                                            "search_stop",
                                            $$v
                                          )
                                        },
                                        expression: "searchparams.search_stop"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "4" } },
                              [
                                _vm._v(" Dept: "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    disabled: _vm.expired,
                                    size: "sm",
                                    options: _vm.departments
                                  },
                                  model: {
                                    value: _vm.mailing.MA_DEPID,
                                    callback: function($$v) {
                                      _vm.$set(_vm.mailing, "MA_DEPID", $$v)
                                    },
                                    expression: "mailing.MA_DEPID"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "4" } },
                              [
                                _vm._v(" Language "),
                                _c("b-form-select", {
                                  attrs: {
                                    disabled: _vm.expired,
                                    id: "language",
                                    size: "sm",
                                    options: _vm.languages
                                  },
                                  model: {
                                    value: _vm.mailing.MA_LANG,
                                    callback: function($$v) {
                                      _vm.$set(_vm.mailing, "MA_LANG", $$v)
                                    },
                                    expression: "mailing.MA_LANG"
                                  }
                                })
                              ],
                              1
                            ),
                            !_vm.expired
                              ? _c(
                                  "b-col",
                                  { attrs: { sm: "12", lg: "4" } },
                                  [
                                    _c("p"),
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: { size: "sm", variant: "info" },
                                        on: { click: _vm.getRecipientAmount }
                                      },
                                      [_vm._v("Get # Recipients")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "bold",
                                          "padding-left": "20px"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.amount))]
                                    )
                                  ],
                                  1
                                )
                              : _c("b-col", { attrs: { sm: "12", lg: "4" } }, [
                                  _c("p"),
                                  _vm._v(" Recipients: "),
                                  _c("b", [_vm._v(_vm._s(_vm.mailing.MA_SENT))])
                                ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "3" } },
                              [
                                _vm._v(" From "),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-input", {
                                              attrs: {
                                                disabled: _vm.expired,
                                                id: "from",
                                                size: "sm"
                                              },
                                              model: {
                                                value: _vm.mailing.MA_FROM,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.mailing,
                                                    "MA_FROM",
                                                    $$v
                                                  )
                                                },
                                                expression: "mailing.MA_FROM"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "6" } },
                              [
                                _vm._v(" Subject "),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-input", {
                                              attrs: {
                                                disabled: _vm.expired,
                                                id: "subject",
                                                size: "sm"
                                              },
                                              model: {
                                                value: _vm.mailing.MA_SUBJECT,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.mailing,
                                                    "MA_SUBJECT",
                                                    $$v
                                                  )
                                                },
                                                expression: "mailing.MA_SUBJECT"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "3" } },
                              [
                                _vm._v(" Send Date "),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-datepicker", {
                                              attrs: {
                                                disabled: _vm.expired,
                                                dark: "",
                                                name: "senddate",
                                                size: "sm",
                                                "initial-date":
                                                  _vm.mailing.MA_SENDDATE,
                                                locale: "en",
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "numeric",
                                                  day: "numeric"
                                                }
                                              },
                                              model: {
                                                value: _vm.mailing.MA_SENDDATE,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.mailing,
                                                    "MA_SENDDATE",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "mailing.MA_SENDDATE"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "12" } },
                              [
                                _vm._v(" Text "),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-textarea", {
                                              attrs: {
                                                disabled: _vm.expired,
                                                name: "ma_text",
                                                id: "ma_text",
                                                rows: "10",
                                                size: "sm"
                                              },
                                              model: {
                                                value: _vm.mailing.MA_TEXT,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.mailing,
                                                    "MA_TEXT",
                                                    $$v
                                                  )
                                                },
                                                expression: "mailing.MA_TEXT"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "12" } },
                              [
                                _vm._v(" HTML "),
                                _c("b-form-textarea", {
                                  attrs: {
                                    disabled: _vm.expired,
                                    name: "ma_html",
                                    id: "ma_html",
                                    rows: "10",
                                    size: "sm"
                                  },
                                  model: {
                                    value: _vm.mailing.MA_HTML,
                                    callback: function($$v) {
                                      _vm.$set(_vm.mailing, "MA_HTML", $$v)
                                    },
                                    expression: "mailing.MA_HTML"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "6" } },
                              [
                                _vm._v(" Testrecipients "),
                                _c("validation-provider", {
                                  attrs: { rules: "emails" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-input", {
                                              attrs: {
                                                disabled: _vm.expired,
                                                id: "MA_TESTRECIPIENTS",
                                                size: "sm"
                                              },
                                              model: {
                                                value:
                                                  _vm.mailing.MA_TESTRECIPIENTS,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.mailing,
                                                    "MA_TESTRECIPIENTS",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "mailing.MA_TESTRECIPIENTS"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { sm: "12", lg: "5" } },
                              [
                                _c("p"),
                                !_vm.expired
                                  ? _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          size: "sm",
                                          variant: "secondary"
                                        },
                                        on: { click: _vm.sendTestMail }
                                      },
                                      [_vm._v("Send Test mail")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.expired
                          ? _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "text-center mt-2" },
                                  [
                                    _vm.maid
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "submit",
                                              variant: "primary"
                                            }
                                          },
                                          [_vm._v("Change")]
                                        )
                                      : _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "submit",
                                              variant: "primary"
                                            }
                                          },
                                          [_vm._v("Add")]
                                        )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("hr")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }