<template>
	<div class="container-fluid depform" id="seasons">
		<b-row class="head filterbar">
			<b-col
				><h3>
					Seasons for <i>{{ dep_name }}</i>
				</h3></b-col
			>
		</b-row>
		<b-row>
			<b-col
				xs="12"
				lg="9"
				xl="8"
				offset-xl="2"
				class="mt-4"
				v-for="(item, key, r_index) in ranges"
				:key="r_index"
			>
				<b>{{ item }}:</b>
				<div v-if="seasons !== null">
					<b-row
						class="seasonentry"
						v-for="(entry, index) in seasons[key]"
						:key="index"
					>
						<b-col xs="12" lg="4">
							<span style="padding-right:1rem;min-width:80px">From:</span>
							<validation-provider
								:ref="entry.SE_ID"
								mode="lazy"
								rules="monthday"
								v-slot="{ errors }"
							>
								<b-form-input
									class="inner"
									size="sm"
									style="width:70%"
									v-model="entry.SE_START"
									placeholder=""
								></b-form-input>
								<span class="formerror"><br />{{ errors[0] }}</span>
							</validation-provider>
						</b-col>
						<b-col xs="12" lg="4">
							<span style="padding-right:1rem;min-width:80px">To:</span>
							<b-form-input
								class="inner"
								size="sm"
								style="width:70%"
								v-model="entry.SE_STOP"
								placeholder=""
							></b-form-input>
						</b-col>
						<b-col xs="12" lg="4">
							<b-btn
								size="sm"
								class="setbutton"
								@click="editSeasonEntry(entry)"
							>
								Set
							</b-btn>
							<b-btn
								size="sm"
								class="setbutton"
								variant="danger"
								@click="deleteSeasonEntry(entry)"
							>
								Delete
							</b-btn>
						</b-col>
					</b-row>
				</div>
				<b-row class="seasonentry">
					<b-col xs="12" lg="4">
						<span style="padding-right:1rem;min-width:80px">From:</span>
						<b-form-input
							class="inner"
							size="sm"
							style="width:70%"
							v-model="newentry[key].SE_START"
							placeholder=""
						></b-form-input>
					</b-col>
					<b-col xs="12" lg="4">
						<span style="padding-right:1rem;min-width:80px">To:</span>
						<b-form-input
							class="inner"
							size="sm"
							style="width:70%"
							v-model="newentry[key].SE_STOP"
							placeholder=""
						></b-form-input>
					</b-col>
					<b-col xs="12" lg="4">
						<b-btn
							variant="success"
							size="sm"
							class="setbutton"
							@click="addSeasonEntry(key, newentry[key])"
						>
							Add
						</b-btn>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import { ValidationProvider, extend, validate } from "vee-validate";
import settingsSource from "@/lib/backend/settings";

extend("monthday", {
	validate: value => {
		if (!value.match(/\d+-\d+/)) {
			console.error(value + " BS");
		}
		let [month, day] = value.split("-");
		if (isNaN(parseInt(month)) || isNaN(parseInt(day))) {
			console.error(month + " or " + day + " NaN");
			return false;
		}
		if (parseInt(month) > 12 || parseInt(day) > 31) {
			console.error(month + " or " + day + " NaN");
			return false;
		}
		return parseInt(value) >= 0;
	},
	message: "Invalid date format"
});

export default {
	props: {
		department: String
	},
	components: {
		ValidationProvider
	},

	data() {
		return {
			isBusy: false,
			seasons: {},
			newentry: {
				L: { SE_START: null, SE_STOP: null },
				M: { SE_START: null, SE_STOP: null },
				H: { SE_START: null, SE_STOP: null }
			},
			ranges: {
				L: "Low",
				M: "Mid",
				H: "High"
			}
		};
	},

	computed: {
		...mapState({
			departments: state => state.helpers.departments
		})
	},

	watch: {
		department: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.getSeasonSettings(newVal);
			},
			deep: true
		}
	},

	mounted: function() {},

	methods: {
		getSeasonSettings(dept) {
			this.isBusy = true;
			const dpentry = this.departments.find(x => x.value === dept);
			this.dep_name = dpentry.text;
			settingsSource
				.getSeasonSettings(dept)
				.then(res => {
					this.seasons = res.data;
					// console.log(this.seasons);
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.e(error, "Error");
				});
		},

		async editSeasonEntry(data) {
			let startOK = await this.isOK(data.SE_START);
			let stopOK = await this.isOK(data.SE_STOP);
			if (startOK.valid && stopOK.valid) {
				settingsSource
					.updateSeasonSettings(data)
					// eslint-disable-next-line no-unused-vars
					.then(res => {
						this.$toastr.s("", "Settings updated");
					})
					.catch(error => {
						this.isBusy = false;
						this.$toastr.e(error, "Error");
					});
			} else {
				this.$toastr.e("Invalid date", "Error");
			}
		},

		deleteSeasonEntry(data) {
			if (!data.SE_ID) {
				this.$toastr.e("No ID passed", "Error");
				return false;
			}
			if (confirm("Are you sure?")) {
				settingsSource
					.deleteSeasonSettings(data.SE_ID)
					// eslint-disable-next-line no-unused-vars
					.then(res => {
						this.seasons[data.SE_TYPE].splice(
							this.seasons[data.SE_TYPE].findIndex(x => x.SE_ID == data.SE_ID),
							1
						);
						this.$toastr.s("", "Season deleted");
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			}
		},

		async addSeasonEntry(season, data) {
			let setter = {
				SE_TYPE: season,
				SE_DEPT: this.department,
				SE_START: data.SE_START,
				SE_STOP: data.SE_STOP
			};
			let startOK = await this.isOK(data.SE_START);
			let stopOK = await this.isOK(data.SE_STOP);
			if (startOK.valid && stopOK.valid) {
				settingsSource
					.addSeasonSettings(setter)
					.then(res => {
						if (!res.data.SE_ID) {
							this.$toastr.e("Insert Error", "Error");
						} else {
							setter["SE_ID"] = res.data.SE_ID;
							if (this.seasons[season] && this.seasons[season].length > 0) {
								this.seasons[season].push(setter);
							} else {
								this.seasons[season] = [setter];
							}
							this.newentry[season] = { SE_START: null, SE_STOP: null };
							this.$toastr.s("", "Settings updated");
						}
					})
					.catch(error => {
						this.isBusy = false;
						this.$toastr.e(error, "Error");
					});
			} else {
				this.$toastr.e("Invalid date", "Error");
			}
		},

		isOK: async val => {
			return validate(val, "monthday");
		}
	}
};
</script>
