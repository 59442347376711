// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getSingleBooking(clid) {
		return API.get("/v1/admin/bookings/" + clid);
	},
	getContractData(clid) {
		return API.get("/v1/admin/contracts/" + clid);
	},
	updateBooking(data) {
		return API.post("/v1/admin/bookings/edit", data);
	},
	createBooking(data) {
		return API.post("/v1/admin/bookings/add", data);
	},
	getFreeCars(BO_ID, DEP_ID, BO_STARTD, BO_STOPD) {
		return API.get(
			"/v1/admin/bookings/freecars/?BO_ID=" +
				BO_ID +
				"&DEP_ID=" +
				DEP_ID +
				"&BO_STARTD=" +
				BO_STARTD +
				"&BO_STOPD=" +
				BO_STOPD
		);
	},
	getVoucher(TI_ID) {
		return API.get("/v1/admin/bookings/voucherpdf/" + TI_ID, {
			responseType: "arraybuffer"
		});
	},
	makeVoucher(BO_ID) {
		return API.post("/v1/admin/vouchers/create/" + BO_ID);
	},
	setCCPDF(TI_ID, CC_TEXT, CC_PDF, TI_DEPT, TI_CLIENTID) {
		return API.post("/v1/admin/bookings/setccpdf", {
			TI_ID: TI_ID,
			CC_TEXT: CC_TEXT,
			CC_PDF: CC_PDF,
			TI_DEPT: TI_DEPT,
			TI_CLIENTID: TI_CLIENTID
		});
	},
	getContract(data) {
		return API.post("/v1/admin/contracts/create", data, {
			responseType: "arraybuffer"
		});
	}
};
