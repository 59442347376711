var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content", attrs: { id: "newticket" } },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Ticket",
          description: "Ticket System"
        }
      }),
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("v-icon", {
                    staticClass: "icon-warning withhover",
                    attrs: { name: "backspace", scale: "3", id: "icon-back" },
                    on: { click: _vm.zurueck }
                  })
                ],
                1
              ),
              _c("b-col", [_c("h1", [_vm._v("Create new Ticket")])])
            ],
            1
          )
        ],
        1
      ),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.transmitForm)
                      }
                    }
                  },
                  [
                    _c(
                      "b-container",
                      { staticStyle: { "margin-top": "30px" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "8" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-status",
                                      "label-for": "status"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("Client:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c(
                                      "vue-bootstrap-typeahead",
                                      {
                                        ref: "typeahead",
                                        staticClass: "inner",
                                        attrs: {
                                          data: _vm.clients,
                                          size: "sm",
                                          serializer: function(s) {
                                            return (
                                              s.fromname +
                                              "(" +
                                              s.CL_EMAIL +
                                              ")"
                                            )
                                          },
                                          placeholder:
                                            "Type a client name/email..."
                                        },
                                        on: {
                                          hit: function($event) {
                                            return _vm.setClientDataForTicket(
                                              $event
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "suggestion",
                                              fn: function(ref) {
                                                var data = ref.data
                                                var htmlText = ref.htmlText
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center"
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "ml-4",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            htmlText
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.clientSearch,
                                          callback: function($$v) {
                                            _vm.clientSearch = $$v
                                          },
                                          expression: "clientSearch"
                                        }
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "append" },
                                          [
                                            _c(
                                              "b-btn",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.clientsingle",
                                                    modifiers: {
                                                      clientsingle: true
                                                    }
                                                  }
                                                ],
                                                attrs: { size: "sm" }
                                              },
                                              [
                                                _c("v-icon", {
                                                  attrs: {
                                                    name: "user",
                                                    scale: "1"
                                                  }
                                                }),
                                                _vm._v("New Client ")
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-dept",
                                      "label-for": "dept"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("Department:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-select", {
                                                  attrs: {
                                                    id: "dept",
                                                    size: "sm",
                                                    options: _vm.departments
                                                  },
                                                  model: {
                                                    value: _vm.ticket.TI_DEPT,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ticket,
                                                        "TI_DEPT",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "ticket.TI_DEPT"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-status",
                                      "label-for": "status"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("Status:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-select", {
                                                  attrs: {
                                                    id: "status",
                                                    size: "sm",
                                                    options: _vm.statusOptions
                                                  },
                                                  model: {
                                                    value: _vm.ticket.TI_STATUS,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ticket,
                                                        "TI_STATUS",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ticket.TI_STATUS"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-language",
                                      "label-for": "language"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("Language:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-select", {
                                                  attrs: {
                                                    id: "language",
                                                    size: "sm",
                                                    options: _vm.languages
                                                  },
                                                  model: {
                                                    value: _vm.ticket.TI_LANG,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ticket,
                                                        "TI_LANG",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "ticket.TI_LANG"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-assigned",
                                      label: "User:",
                                      "label-for": "assigned"
                                    }
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "assigned",
                                        size: "sm",
                                        options: _vm.users
                                      },
                                      model: {
                                        value: _vm.ticket.TI_ASSIGNEDTO,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ticket,
                                            "TI_ASSIGNEDTO",
                                            $$v
                                          )
                                        },
                                        expression: "ticket.TI_ASSIGNEDTO"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-container",
                      { staticClass: "second" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "12", xl: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-subject",
                                      "label-for": "subject"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("Subject:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "subject",
                                                    size: "sm"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ticket.TI_SUBJECT,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ticket,
                                                        "TI_SUBJECT",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ticket.TI_SUBJECT"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "6", xl: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-fromname",
                                      "label-for": "fromname"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("From:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "fromname",
                                                    size: "sm"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ticket.TI_FROMNAME,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ticket,
                                                        "TI_FROMNAME",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ticket.TI_FROMNAME"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "6", xl: "3" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "input-group-frommail",
                                      "label-for": "frommail"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v("Sender:"),
                                      _c(
                                        "span",
                                        { staticClass: "required-star" },
                                        [_vm._v("*")]
                                      )
                                    ]),
                                    _c("validation-provider", {
                                      attrs: { rules: "email|required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "frommail",
                                                    size: "sm"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ticket.TI_FROMMAIL,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.ticket,
                                                        "TI_FROMMAIL",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ticket.TI_FROMMAIL"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-container",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm._v(" First message"),
                                _c("span", { staticClass: "required-star" }, [
                                  _vm._v("*")
                                ]),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-textarea", {
                                              attrs: { size: "sm", rows: "5" },
                                              model: {
                                                value: _vm.firstmessage,
                                                callback: function($$v) {
                                                  _vm.firstmessage = $$v
                                                },
                                                expression: "firstmessage"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt25" },
                          [
                            _c(
                              "b-col",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    }
                                  },
                                  [_vm._v("Create Ticket")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" " + _vm._s(_vm.ticket.TI_CLIENTID) + " "),
      _c("ClientSingle", { attrs: { finalize: _vm.addClient } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }