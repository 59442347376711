<template>
	<div class="container-fluid depform" id="users">
		<b-row align-h="between" class="head filterbar">
			<b-col><h3>Users</h3></b-col>
			<b-col class="text-right">
				<b-form-checkbox
					v-model="showHidden"
					name="check-button"
					switch
					@change="filterVisible"
				>
					Show Hidden
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-table
			striped
			bordered
			foot-clone
			:items="visibleusers"
			:fields="fields"
			:busy="isBusy"
			primary-key="USR_ID"
			small
			tbody-tr-class="usertable"
		>
			<template v-slot:cell(USR_STATUS)="data">
				<div v-if="dataChanger == data.item.USR_ID">
					<b-form-select
						size="sm"
						v-model="data.item.USR_STATUS"
						:options="userstatusselecter"
					></b-form-select>
				</div>
				<div v-else>
					{{ userstatusarray[data.item.USR_STATUS] }}
				</div>
			</template>

			<template v-slot:cell(USR_NAME)="data">
				<div v-if="dataChanger == data.item.USR_ID">
					<validation-provider
						:ref="data.item.USR_NAME"
						mode="lazy"
						rules="required"
						v-slot="{ errors }"
					>
						<b-form-input
							class="inner"
							size="sm"
							style="width:50%"
							v-model="data.item.USR_NAME"
							placeholder=""
						></b-form-input>
						<span class="formerror"><br />{{ errors[0] }}</span>
					</validation-provider>
				</div>
				<div v-else>
					{{ data.item.USR_NAME }}
				</div>
			</template>

			<template v-slot:cell(NEWPASSWD)="data">
				<div v-if="dataChanger == data.item.USR_ID">
					<validation-provider
						:ref="data.item.USR_PASSWORD"
						mode="lazy"
						rules="strongpass"
						v-slot="{ errors }"
					>
						<b-form-input
							type="password"
							class="inner"
							size="sm"
							style="width:70%"
							v-model="data.item.USR_PASSWORD"
							placeholder=""
						></b-form-input>
						<span class="formerror"><br />{{ errors[0] }}</span>
					</validation-provider>
				</div>
				<div v-else>
					************
				</div>
			</template>

			<template v-slot:cell(USR_EMAIL)="data">
				<div v-if="dataChanger == data.item.USR_ID">
					<validation-provider
						:ref="data.item.USR_EMAIL"
						mode="lazy"
						rules="required|email"
						v-slot="{ errors }"
					>
						<b-form-input
							type="email"
							class="inner"
							size="sm"
							style="width:70%"
							v-model="data.item.USR_EMAIL"
							placeholder=""
						></b-form-input>
						<span class="formerror"><br />{{ errors[0] }}</span>
					</validation-provider>
				</div>
				<div v-else>
					{{ data.item.USR_EMAIL }}
				</div>
			</template>

			<template v-slot:cell(USR_DEPT)="data">
				<div v-if="dataChanger == data.item.USR_ID">
					<b-form-select
						size="sm"
						v-model="data.item.USR_DEPT"
						:options="departments"
					></b-form-select>
				</div>
				<div v-else>
					{{ departmentNames[data.item.USR_DEPT] }}
				</div>

				<div v-else @click="setStatusChanger(data.item.USR_ID)">
					{{ userstatusarray[data.item.USR_STATUS] }}
				</div>
			</template>

			<template v-slot:cell(MUELL)="data">
				<b-btn
					v-if="dataChanger == data.item.USR_ID"
					variant="primary"
					@click="editUser(data.item)"
				>
					<v-icon name="arrow-circle-right" scale="1.5" />
				</b-btn>
				<b-btn v-else @click="setDataChanger(data.item.USR_ID)"
					><v-icon name="user-edit" scale="1.5"
				/></b-btn>
			</template>

			<template v-slot:cell(USR_HIDDEN)="data">
				<b-form-checkbox
					v-model="data.item.USR_HIDDEN"
					value="1"
					unchecked-value="0"
					name="check-button"
					switch
					@change="toggleHidden(data.item)"
				>
				</b-form-checkbox>
			</template>
		</b-table>
		<h3>Add User</h3>
		<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
			<b-form @submit.prevent="handleSubmit(addUser)">
				<b-row>
					<b-col>
						Name
						<validation-provider rules="required" v-slot="{ errors }">
							<b-form-input
								size="sm"
								id="USR_NAME"
								v-model="newuser.USR_NAME"
								placeholder="Name"
							></b-form-input>
							<span class="formerror">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
					<b-col>
						Login
						<validation-provider rules="required" v-slot="{ errors }">
							<b-form-input
								size="sm"
								id="USR_LOGIN"
								v-model="newuser.USR_LOGIN"
								placeholder="Login"
							></b-form-input>
							<span class="formerror">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
					<b-col>
						Email
						<validation-provider
							rules="required"
							mode="lazy"
							v-slot="{ errors }"
						>
							<b-form-input
								type="email"
								size="sm"
								id="USR_EMAIL"
								v-model="newuser.USR_EMAIL"
								placeholder="Email"
							></b-form-input>
							<span class="formerror">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
					<b-col>
						Password
						<validation-provider
							mode="lazy"
							rules="required|strongpass"
							v-slot="{ errors }"
						>
							<b-form-input
								type="password"
								size="sm"
								id="USR_PASSWORD"
								v-model="newuser.USR_PASSWORD"
								placeholder="Password"
							></b-form-input>
							<span class="formerror">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
					<b-col>
						Status
						<b-form-select
							size="sm"
							v-model="newuser.USR_STATUS"
							:options="userstatusselecter"
						></b-form-select>
					</b-col>
					<b-col>
						Department
						<b-form-select
							size="sm"
							v-model="newuser.USR_DEPT"
							:options="departments"
						></b-form-select>
					</b-col>
					<b-col>
						<span style="color:transparent">Pla</span>
						<br />
						<b-btn size="sm" type="submit" variant="primary">
							<v-icon name="arrow-circle-right" scale="1.5" />
						</b-btn>
					</b-col>
				</b-row>
			</b-form>
		</ValidationObserver>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import {
	ValidationProvider,
	ValidationObserver,
	extend,
	validate
} from "vee-validate";
import userSource from "@/lib/backend/users";
import misc from "@/lib/misc";
import "vue-awesome/icons/user-edit";
import "vue-awesome/icons/check-circle";
import "vue-awesome/icons/arrow-circle-right";

extend("strongpass", {
	validate: value => {
		var strongRegex = new RegExp(
			"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
		);
		return strongRegex.test(value);
	},
	message:
		"The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)"
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},

	data() {
		return {
			isBusy: false,
			users: [],
			newuser: null,
			visibleusers: [],
			userstatusselecter: misc.getSelecter(misc.userstatusarray),
			userstatusarray: misc.userstatusarray,
			fields: misc.userListFields,
			dataChanger: null,
			showHidden: false
		};
	},

	computed: {
		...mapState({
			departments: state => state.helpers.departments
		}),
		departmentNames() {
			let n = {};
			for (let x in this.departments) {
				n[this.departments[x].value] = this.departments[x].text;
			}
			return n;
		}
	},

	created: function() {
		this.newuser = this.getDummyUserEntry();
		this.getUsers();
	},
	mounted: function() {
		// console.log(this.departments);
	},

	methods: {
		getUsers() {
			this.isBusy = true;
			userSource
				.getUsers()
				.then(res => {
					this.isBusy = false;
					this.users = res.data;
					this.filterVisible();
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.e(error, "Error");
				});
		},

		async editUser(data) {
			let allOK = true;
			let userOK = await this.isOK(data.USR_NAME, "required");
			let emailOK = await this.isOK(data.USR_EMAIL, "email");
			let passwordOK = { valid: true };
			if (data.USR_PASSWORD) {
				passwordOK = await this.isOK(data.USR_PASSWORD, "strongpass");
			}
			allOK = userOK.valid && emailOK.valid && passwordOK.valid;
			if (allOK) {
				userSource
					.editUser(data)
					.then(res => {
						this.$toastr.s("", "Settings updated");
						this.dataChanger = null;
					})
					.catch(error => {
						this.isBusy = false;
						this.$toastr.e(error, "Error");
					});
			} else {
				this.$toastr.e("Invalid Form Data", "Error");
			}
		},

		async addUser() {
			userSource
				.addUser(this.newuser)
				.then(res => {
					if (!res.data.USR_ID) {
						this.$toastr.e("Insert Error", "Error");
					} else {
						this.getUsers();
						this.$refs.observer.reset();
						this.newuser = this.getDummyUserEntry();
						this.$toastr.s("", "User added");
					}
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.e(error, "Error");
				});
		},

		setDataChanger(value) {
			this.dataChanger = value;
		},

		filterVisible() {
			this.$nextTick(() => {
				if (this.showHidden) this.visibleusers = this.users;
				else {
					this.visibleusers = this.users.filter(user => user.USR_HIDDEN == 0);
				}
			});
		},

		toggleHidden(data) {
			data.USR_HIDDEN = data.USR_HIDDEN == 0 ? 1 : 0;
			userSource
				.editUser({ USR_ID: data.USR_ID, USR_HIDDEN: data.USR_HIDDEN })
				.then(this.filterVisible())
				.catch(error => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		getDepartmentName(id) {
			let depp = this.departments.find(x => x.value == id);
			console.log("!!", depp);
			return depp.text;
		},

		getDummyUserEntry() {
			return {
				USR_NAME: null,
				USR_LOGIN: null,
				USR_PASSWORD: null,
				USR_EMAIL: null,
				USR_STATUS: null,
				USR_DEPT: null,
				USR_HIDDEN: 0
			};
		},

		async isOK(val, test) {
			return validate(val, test);
		}
	}
};
</script>
