<template>
	<div
		class="menuitem h1 mb-2"
		@mouseover="elementname = false"
		@mouseleave="elementname = false"
		v-if="isVisible"
	>
		<router-link :to="link" tag="div" class="icon">
			<b-icon :icon="icon" id="icon-tickets"></b-icon>
		</router-link>
		<div class="mtext" v-if="elementname">
			<router-link :to="link">{{ navname }}</router-link>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "NavElement",
	props: {
		link: String,
		icon: String,
		navname: String,
		vis: String
	},

	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			navstatus: state => state.helpers.navstatus,
			userdata: state => state.auth.userdata,
			username: state => state.auth.userdata.USR_LOGIN
		})
	},

	data() {
		return {
			elementname: false,
			isVisible: false
		};
	},

	mounted: function() {
		if (
			this.vis == "0" ||
			parseInt(this.vis) == parseInt(this.userdata.USR_STATUS)
		) {
			this.isVisible = true;
		} else {
			this.isVisible = false;
		}
		// console.log(process.env.VUE_APP_API_URL)
	},
	methods: {}
};
</script>
