var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tab",
    { attrs: { title: "Export Bookings w/Vouchers", active: "" } },
    [
      _c(
        "b-card-text",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "mb-3",
                  attrs: { xs: "6", lg: "3", "offset-lg": "1" }
                },
                [
                  _vm._v(" Dept: "),
                  _c("b-form-select", {
                    staticClass: "inner",
                    attrs: { options: _vm.departments },
                    model: {
                      value: _vm.department,
                      callback: function($$v) {
                        _vm.department = $$v
                      },
                      expression: "department"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                [
                  _c("span", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Text contains")
                  ]),
                  _c("b-form-input", {
                    staticClass: "inner",
                    model: {
                      value: _vm.textcontains,
                      callback: function($$v) {
                        _vm.textcontains = $$v
                      },
                      expression: "textcontains"
                    }
                  })
                ],
                1
              ),
              _vm.hasProviders
                ? _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _vm._v(" Provider "),
                      _c("b-form-select", {
                        staticClass: "inner",
                        attrs: { options: _vm.ProviderOptions },
                        model: {
                          value: _vm.carprovider,
                          callback: function($$v) {
                            _vm.carprovider = $$v
                          },
                          expression: "carprovider"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "6", lg: "3", "offset-lg": "1" } },
                [
                  _c("span", { staticClass: "fieldname" }, [_vm._v("From:")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-3 datebox" },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          "button-only": "",
                          dropright: "",
                          "initial-date": _vm.startDate,
                          locale: "nl"
                        },
                        on: { context: _vm.onContext },
                        model: {
                          value: _vm.fromdate,
                          callback: function($$v) {
                            _vm.fromdate = $$v
                          },
                          expression: "fromdate"
                        }
                      }),
                      _c("span", { staticClass: "fieldval" }, [
                        _vm._v(" " + _vm._s(_vm.fromdate || "YYYY-mm-dd") + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6", lg: "3" } },
                [
                  _c("span", { staticClass: "fieldname" }, [_vm._v("To:")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-3 datebox" },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          "button-only": "",
                          dropright: "",
                          locale: "nl",
                          "aria-controls": "example-input"
                        },
                        on: { context: _vm.onContext },
                        model: {
                          value: _vm.todate,
                          callback: function($$v) {
                            _vm.todate = $$v
                          },
                          expression: "todate"
                        }
                      }),
                      _c("span", { staticClass: "fieldval" }, [
                        _vm._v(" " + _vm._s(_vm.todate || "YYYY-mm-dd") + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                [
                  _vm._v(" Language: "),
                  _c("b-form-select", {
                    staticClass: "inner",
                    attrs: { options: _vm.languageOptions },
                    model: {
                      value: _vm.language,
                      callback: function($$v) {
                        _vm.language = $$v
                      },
                      expression: "language"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "switches",
                  attrs: { "offset-lg": "1", sm: "11" }
                },
                [_c("div", [_vm._v("Export Fields:")])]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "switches",
                  attrs: { sm: "4", lg: "2", "offset-lg": "1" }
                },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_STARTD", switch: "" },
                      model: {
                        value: _vm.fields.BO_STARTD,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_STARTD", $$v)
                        },
                        expression: "fields.BO_STARTD"
                      }
                    },
                    [_vm._v(" Start Date ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_NAME", switch: "" },
                      model: {
                        value: _vm.fields.BO_NAME,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_NAME", $$v)
                        },
                        expression: "fields.BO_NAME"
                      }
                    },
                    [_vm._v(" Name ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_TICKETID", switch: "" },
                      model: {
                        value: _vm.fields.BO_TICKETID,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_TICKETID", $$v)
                        },
                        expression: "fields.BO_TICKETID"
                      }
                    },
                    [_vm._v(" Ticket ID ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_HIREDAYS", switch: "" },
                      model: {
                        value: _vm.fields.BO_HIREDAYS,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_HIREDAYS", $$v)
                        },
                        expression: "fields.BO_HIREDAYS"
                      }
                    },
                    [_vm._v(" Total Days ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_PRICE", switch: "" },
                      model: {
                        value: _vm.fields.BO_PRICE,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_PRICE", $$v)
                        },
                        expression: "fields.BO_PRICE"
                      }
                    },
                    [_vm._v(" Price ")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "switches", attrs: { sm: "4", lg: "2" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_STOPD", switch: "" },
                      model: {
                        value: _vm.fields.BO_STOPD,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_STOPD", $$v)
                        },
                        expression: "fields.BO_STOPD"
                      }
                    },
                    [_vm._v(" Stop Date ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "CL_EMAIL", switch: "" },
                      model: {
                        value: _vm.fields.CL_EMAIL,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "CL_EMAIL", $$v)
                        },
                        expression: "fields.CL_EMAIL"
                      }
                    },
                    [_vm._v(" Email ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_HOTEL", switch: "" },
                      model: {
                        value: _vm.fields.BO_HOTEL,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_HOTEL", $$v)
                        },
                        expression: "fields.BO_HOTEL"
                      }
                    },
                    [_vm._v(" Hotel ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_CARCLASS", switch: "" },
                      model: {
                        value: _vm.fields.BO_CARCLASS,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_CARCLASS", $$v)
                        },
                        expression: "fields.BO_CARCLASS"
                      }
                    },
                    [_vm._v(" Car Class ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_PRICE_SUM", switch: "" },
                      model: {
                        value: _vm.fields.BO_PRICE_SUM,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_PRICE_SUM", $$v)
                        },
                        expression: "fields.BO_PRICE_SUM"
                      }
                    },
                    [_vm._v(" Price Sum ")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "switches", attrs: { sm: "4", lg: "2" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_STARTT", switch: "" },
                      model: {
                        value: _vm.fields.BO_STARTT,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_STARTT", $$v)
                        },
                        expression: "fields.BO_STARTT"
                      }
                    },
                    [_vm._v(" Start Time ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_PICKUP", switch: "" },
                      model: {
                        value: _vm.fields.BO_PICKUP,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_PICKUP", $$v)
                        },
                        expression: "fields.BO_PICKUP"
                      }
                    },
                    [_vm._v(" Pickup Location ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_FLIGHTNO", switch: "" },
                      model: {
                        value: _vm.fields.BO_FLIGHTNO,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_FLIGHTNO", $$v)
                        },
                        expression: "fields.BO_FLIGHTNO"
                      }
                    },
                    [_vm._v(" Flight No. ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_CAR", switch: "" },
                      model: {
                        value: _vm.fields.BO_CAR,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_CAR", $$v)
                        },
                        expression: "fields.BO_CAR"
                      }
                    },
                    [_vm._v(" Booked Car ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "TI_CD", switch: "" },
                      model: {
                        value: _vm.fields.TI_CD,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "TI_CD", $$v)
                        },
                        expression: "fields.TI_CD"
                      }
                    },
                    [_vm._v(" Request Date ")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "switches", attrs: { sm: "4", lg: "2" } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_STOPT", switch: "" },
                      model: {
                        value: _vm.fields.BO_STOPT,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_STOPT", $$v)
                        },
                        expression: "fields.BO_STOPT"
                      }
                    },
                    [_vm._v(" Stop Time ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_RETURN", switch: "" },
                      model: {
                        value: _vm.fields.BO_RETURN,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_RETURN", $$v)
                        },
                        expression: "fields.BO_RETURN"
                      }
                    },
                    [_vm._v(" Return Location ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "BO_STATUS", switch: "" },
                      model: {
                        value: _vm.fields.BO_STATUS,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "BO_STATUS", $$v)
                        },
                        expression: "fields.BO_STATUS"
                      }
                    },
                    [_vm._v(" Status ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "CAR_PLATE", switch: "" },
                      model: {
                        value: _vm.fields.CAR_PLATE,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "CAR_PLATE", $$v)
                        },
                        expression: "fields.CAR_PLATE"
                      }
                    },
                    [_vm._v(" Numberplate ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { name: "CL_VOUCHERS", switch: "" },
                      model: {
                        value: _vm.fields.CL_VOUCHERS,
                        callback: function($$v) {
                          _vm.$set(_vm.fields, "CL_VOUCHERS", $$v)
                        },
                        expression: "fields.CL_VOUCHERS"
                      }
                    },
                    [_vm._v(" Vouchers ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "mt-3 text-center",
                  attrs: { xs: "12", lg: "6" }
                },
                [
                  _c(
                    "b-button-group",
                    { attrs: { size: "lg" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: {
                            click: function($event) {
                              return _vm.exportData("bookings", "XML")
                            }
                          }
                        },
                        [_vm._v("Export XML")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "info" },
                          on: {
                            click: function($event) {
                              return _vm.exportData("bookings", "HTML")
                            }
                          }
                        },
                        [_vm._v("Export HTML")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.exportData("bookings", "CSV")
                            }
                          }
                        },
                        [_vm._v("Export CSV")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }