var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid depform", attrs: { id: "users" } },
    [
      _c(
        "b-row",
        { staticClass: "head filterbar", attrs: { "align-h": "between" } },
        [
          _c("b-col", [_c("h3", [_vm._v("Users")])]),
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "check-button", switch: "" },
                  on: { change: _vm.filterVisible },
                  model: {
                    value: _vm.showHidden,
                    callback: function($$v) {
                      _vm.showHidden = $$v
                    },
                    expression: "showHidden"
                  }
                },
                [_vm._v(" Show Hidden ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          striped: "",
          bordered: "",
          "foot-clone": "",
          items: _vm.visibleusers,
          fields: _vm.fields,
          busy: _vm.isBusy,
          "primary-key": "USR_ID",
          small: "",
          "tbody-tr-class": "usertable"
        },
        scopedSlots: _vm._u([
          {
            key: "cell(USR_STATUS)",
            fn: function(data) {
              return [
                _vm.dataChanger == data.item.USR_ID
                  ? _c(
                      "div",
                      [
                        _c("b-form-select", {
                          attrs: {
                            size: "sm",
                            options: _vm.userstatusselecter
                          },
                          model: {
                            value: data.item.USR_STATUS,
                            callback: function($$v) {
                              _vm.$set(data.item, "USR_STATUS", $$v)
                            },
                            expression: "data.item.USR_STATUS"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.userstatusarray[data.item.USR_STATUS]) +
                          " "
                      )
                    ])
              ]
            }
          },
          {
            key: "cell(USR_NAME)",
            fn: function(data) {
              return [
                _vm.dataChanger == data.item.USR_ID
                  ? _c(
                      "div",
                      [
                        _c("validation-provider", {
                          ref: data.item.USR_NAME,
                          attrs: { mode: "lazy", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      staticStyle: { width: "50%" },
                                      attrs: { size: "sm", placeholder: "" },
                                      model: {
                                        value: data.item.USR_NAME,
                                        callback: function($$v) {
                                          _vm.$set(data.item, "USR_NAME", $$v)
                                        },
                                        expression: "data.item.USR_NAME"
                                      }
                                    }),
                                    _c("span", { staticClass: "formerror" }, [
                                      _c("br"),
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(" " + _vm._s(data.item.USR_NAME) + " ")])
              ]
            }
          },
          {
            key: "cell(NEWPASSWD)",
            fn: function(data) {
              return [
                _vm.dataChanger == data.item.USR_ID
                  ? _c(
                      "div",
                      [
                        _c("validation-provider", {
                          ref: data.item.USR_PASSWORD,
                          attrs: { mode: "lazy", rules: "strongpass" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        type: "password",
                                        size: "sm",
                                        placeholder: ""
                                      },
                                      model: {
                                        value: data.item.USR_PASSWORD,
                                        callback: function($$v) {
                                          _vm.$set(
                                            data.item,
                                            "USR_PASSWORD",
                                            $$v
                                          )
                                        },
                                        expression: "data.item.USR_PASSWORD"
                                      }
                                    }),
                                    _c("span", { staticClass: "formerror" }, [
                                      _c("br"),
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(" ************ ")])
              ]
            }
          },
          {
            key: "cell(USR_EMAIL)",
            fn: function(data) {
              return [
                _vm.dataChanger == data.item.USR_ID
                  ? _c(
                      "div",
                      [
                        _c("validation-provider", {
                          ref: data.item.USR_EMAIL,
                          attrs: { mode: "lazy", rules: "required|email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      staticStyle: { width: "70%" },
                                      attrs: {
                                        type: "email",
                                        size: "sm",
                                        placeholder: ""
                                      },
                                      model: {
                                        value: data.item.USR_EMAIL,
                                        callback: function($$v) {
                                          _vm.$set(data.item, "USR_EMAIL", $$v)
                                        },
                                        expression: "data.item.USR_EMAIL"
                                      }
                                    }),
                                    _c("span", { staticClass: "formerror" }, [
                                      _c("br"),
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _c("div", [_vm._v(" " + _vm._s(data.item.USR_EMAIL) + " ")])
              ]
            }
          },
          {
            key: "cell(USR_DEPT)",
            fn: function(data) {
              return [
                _vm.dataChanger == data.item.USR_ID
                  ? _c(
                      "div",
                      [
                        _c("b-form-select", {
                          attrs: { size: "sm", options: _vm.departments },
                          model: {
                            value: data.item.USR_DEPT,
                            callback: function($$v) {
                              _vm.$set(data.item, "USR_DEPT", $$v)
                            },
                            expression: "data.item.USR_DEPT"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.departmentNames[data.item.USR_DEPT]) +
                          " "
                      )
                    ])
              ]
            }
          },
          {
            key: "cell(MUELL)",
            fn: function(data) {
              return [
                _vm.dataChanger == data.item.USR_ID
                  ? _c(
                      "b-btn",
                      {
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.editUser(data.item)
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { name: "arrow-circle-right", scale: "1.5" }
                        })
                      ],
                      1
                    )
                  : _c(
                      "b-btn",
                      {
                        on: {
                          click: function($event) {
                            return _vm.setDataChanger(data.item.USR_ID)
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          attrs: { name: "user-edit", scale: "1.5" }
                        })
                      ],
                      1
                    )
              ]
            }
          },
          {
            key: "cell(USR_HIDDEN)",
            fn: function(data) {
              return [
                _c("b-form-checkbox", {
                  attrs: {
                    value: "1",
                    "unchecked-value": "0",
                    name: "check-button",
                    switch: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.toggleHidden(data.item)
                    }
                  },
                  model: {
                    value: data.item.USR_HIDDEN,
                    callback: function($$v) {
                      _vm.$set(data.item, "USR_HIDDEN", $$v)
                    },
                    expression: "data.item.USR_HIDDEN"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("h3", [_vm._v("Add User")]),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.addUser)
                      }
                    }
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm._v(" Name "),
                            _c("validation-provider", {
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            size: "sm",
                                            id: "USR_NAME",
                                            placeholder: "Name"
                                          },
                                          model: {
                                            value: _vm.newuser.USR_NAME,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newuser,
                                                "USR_NAME",
                                                $$v
                                              )
                                            },
                                            expression: "newuser.USR_NAME"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "formerror" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _vm._v(" Login "),
                            _c("validation-provider", {
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            size: "sm",
                                            id: "USR_LOGIN",
                                            placeholder: "Login"
                                          },
                                          model: {
                                            value: _vm.newuser.USR_LOGIN,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newuser,
                                                "USR_LOGIN",
                                                $$v
                                              )
                                            },
                                            expression: "newuser.USR_LOGIN"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "formerror" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _vm._v(" Email "),
                            _c("validation-provider", {
                              attrs: { rules: "required", mode: "lazy" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "email",
                                            size: "sm",
                                            id: "USR_EMAIL",
                                            placeholder: "Email"
                                          },
                                          model: {
                                            value: _vm.newuser.USR_EMAIL,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newuser,
                                                "USR_EMAIL",
                                                $$v
                                              )
                                            },
                                            expression: "newuser.USR_EMAIL"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "formerror" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _vm._v(" Password "),
                            _c("validation-provider", {
                              attrs: {
                                mode: "lazy",
                                rules: "required|strongpass"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "password",
                                            size: "sm",
                                            id: "USR_PASSWORD",
                                            placeholder: "Password"
                                          },
                                          model: {
                                            value: _vm.newuser.USR_PASSWORD,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newuser,
                                                "USR_PASSWORD",
                                                $$v
                                              )
                                            },
                                            expression: "newuser.USR_PASSWORD"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "formerror" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _vm._v(" Status "),
                            _c("b-form-select", {
                              attrs: {
                                size: "sm",
                                options: _vm.userstatusselecter
                              },
                              model: {
                                value: _vm.newuser.USR_STATUS,
                                callback: function($$v) {
                                  _vm.$set(_vm.newuser, "USR_STATUS", $$v)
                                },
                                expression: "newuser.USR_STATUS"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _vm._v(" Department "),
                            _c("b-form-select", {
                              attrs: { size: "sm", options: _vm.departments },
                              model: {
                                value: _vm.newuser.USR_DEPT,
                                callback: function($$v) {
                                  _vm.$set(_vm.newuser, "USR_DEPT", $$v)
                                },
                                expression: "newuser.USR_DEPT"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          [
                            _c(
                              "span",
                              { staticStyle: { color: "transparent" } },
                              [_vm._v("Pla")]
                            ),
                            _c("br"),
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  size: "sm",
                                  type: "submit",
                                  variant: "primary"
                                }
                              },
                              [
                                _c("v-icon", {
                                  attrs: {
                                    name: "arrow-circle-right",
                                    scale: "1.5"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }