/* eslint-disable no-unused-vars */
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/tickets.scss";
import ticketSource from "@/lib/backend/tickets";
import userSource from "@/lib/backend/users";
import bookingSource from "@/lib/backend/bookings";
import ClientSingle from "@/views/ClientSingle";
import BookingSingle from "@/views/BookingSingle";
import ContractForm from "@/views/ContractForm";
import Messages from "@/components/Messages";
import CCPDF from "@/components/CCPDF";
import tableFields from "@/lib/misc";
import "vue-awesome/icons/exclamation-circle";
import "vue-awesome/icons/backspace";
import "vue-awesome/icons/book-open";
import "vue-awesome/icons/file-signature";
import "vue-awesome/icons/user";
import "vue-awesome/icons/clone";
import "vue-awesome/icons/bell";
import "vue-awesome/icons/sms";
import "vue-awesome/icons/file-pdf";

import { BFormTextarea } from "bootstrap-vue";
import router from "../router";
Vue.component("b-form-textarea", BFormTextarea);

export default {
	components: {
		ClientSingle,
		BookingSingle,
		ContractForm,
		Messages,
		CCPDF
	},
	data() {
		return {
			ticketID: 0,
			ticket: {},
			filters: {},
			users: [],
			statusChanger: 0,
			userChanger: 0,
			languageChanger: 0,
			isBusy: true,
			messages: [],
			currentBookingId: null,
			contractBookingId: null,
			currentPdfUrl: null,
			currentDepId: null,
			currentTicket: null,
			statusOptions: tableFields.statusOptions,
			languages: tableFields.languages
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token
		})
	},

	mounted: function() {
		let that = this;
		if (this.$route.params.page) {
			this.currentPage = this.$route.params.page;
		}
		if (!this.$route.params.id) {
			that.$toastr.Add({
				title: "Error", // Toast Title
				msg: "No Ticket ID passed",
				position: "toast-top-center",
				type: "error"
			});
		} else {
			this.ticketID = parseInt(this.$route.params.id);
		}

		if (localStorage.getItem("users")) {
			this.users = JSON.parse(localStorage.getItem("users"));
		} else {
			userSource
				.getUsersForSelect()
				.then(this.setUsers)
				.catch(error => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		}
		this.getTicketData();
	},
	methods: {
		linkGen(pageNum) {
			return { path: `/tickets/page/${pageNum}` };
		},

		getTicketData() {
			ticketSource
				.getTicket(this.ticketID)
				.then(this.handleTicketResponse)
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		handleTicketResponse(response) {
			// console.log("fresh ticket data")
			this.ticket = response.data;
			console.log("ticket is ", this.ticket);
			this.ticket.MAIL_TEMPLATES.unshift({
				value: null,
				text: "choose a template"
			});
			this.messages = this.ticket.messages.map(function(el) {
				var o = Object.assign({}, el);
				o._showDetails = false;
				return o;
			});
			if (this.ticket.CC_PDF) {
				let recs = [];
				for (let a in this.ticket.CC_PDF) {
					recs.push({ value: a, text: this.ticket.CC_PDF[a] });
				}
				this.ticket.CC_PDF = recs;
				let vouch = this.ticket.PDF_URL;
				this.ticket.CC_VOUCHER = vouch.replace(
					/pdf_vouchers\//,
					"pdf_vouchers/cc_"
				);
				// console.log("Recs: ", this.ticket.CC_PDF);
			}
			this.isBusy = false;
		},
		setUsers(response) {
			this.users = response.data;
			this.users.unshift({ text: "---", value: 0 });
		},
		updateStatus(value) {
			// console.log("The status for ", this.statusChanger, " is now ", value)
			ticketSource
				.setStatus(this.ticket.TI_ID, value)
				.then(res => {
					this.$store.dispatch("TICKETS_CHANGE", {
						TI_ID: this.ticket.TI_ID,
						field: "TI_STATUS",
						value: value
					});

					this.$toastr.Add({
						title: "Status changed", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		updateLanguage(value) {
			ticketSource
				.setLanguage(this.ticket.TI_ID, value)
				.then(res => {
					this.$store.dispatch("TICKETS_CHANGE", {
						TI_ID: this.ticket.TI_ID,
						field: "TI_LANG",
						value: value
					});
					this.$toastr.Add({
						title: "Language changed", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
					this.$router.go();
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		updateAssignedUser(value, idx) {
			// console.log("The status for ", this.statusChanger, " is now ", value, " at idx ", idx)
			ticketSource
				.setAssignedUser(this.ticket.TI_ID, value)
				.then(res => {
					this.$store.dispatch("TICKETS_CHANGE", {
						TI_ID: this.ticket.TI_ID,
						field: "TI_ASSIGNEDTO",
						value: value
					});
					this.$toastr.Add({
						title: "New User assigned", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		updateSubject() {
			// console.log("The status for ", this.statusChanger, " is now ", value)
			ticketSource
				.setSubject(this.ticket.TI_ID, this.ticket.TI_SUBJECT)
				.then(res => {
					this.$store.dispatch("TICKETS_CHANGE", {
						TI_ID: this.ticket.TI_ID,
						field: "TI_SUBJECT",
						value: this.ticket.TI_SUBJECT
					});
					this.$bvModal.hide("subject");

					this.$toastr.Add({
						title: "Subject changed", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		downloadVoucher() {
			let that = this;
			let dlname = this.ticket.TI_BOOKINGID + "_" + this.ticket.TI_ID + ".pdf";
			bookingSource
				.getVoucher(this.ticket.TI_ID)
				.then(response => {
					let blob = new Blob([response.data], { type: "application/pdf" });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = dlname;
					link.click();
				})
				.catch(error => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: "Could not download PDF " + dlname,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		makeClone() {
			let that = this;
			ticketSource
				.clone(this.ticket.TI_ID)
				.then(response => {
					if (!response.data.TI_ID) {
						that.$toastr.Add({
							title: "Error", // Toast Title
							msg: "Could not Clone Ticket ",
							position: "toast-top-center",
							type: "error"
						});
					}
					// window.location("/tickets/details/" + response.data.TI_ID)
					router.push("/tickets/details/" + response.data.TI_ID);
				})
				.catch(error => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: "Could not clone Ticket",
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		showBookingSingle(id, pdfurl, dep_id) {
			// console.log("showing single for ", id, pdfurl, dep_id)
			this.currentBookingId = this.ticket.TI_BOOKINGID;
			this.currentPdfUrl = this.ticket.PDF_URL;
			this.currentDepId = this.ticket.TI_DEPT;
			this.currentTicket = this.ticket;
			this.$bvModal.show("bookingsingle");
		},
		showContractForm(bo_id) {
			console.log("showing contract for ", bo_id);
			this.contractBookingId = bo_id;
			this.$bvModal.show("contractform");
		},

		toggleSendReminder(ev) {
			ticketSource
				.setSendReminder(this.ticket.TI_ID, ev)
				.then(res => {
					let ttext = ev == "0" ? "Disabled " : "Endabled ";
					this.$toastr.Add({
						title: ttext + "Reminder", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
			// console.log(ev)
			// console.log(this.ticket.TI_SENDREMINDER)
		},

		setBoId(id) {
			this.ticket.TI_BOOKINGID = id;
		},

		getTicketsByClient(clid) {
			// if (localStorage.getItem("ticket_filters")) {
			// 	this.filters = JSON.parse(localStorage.getItem("ticket_filters"));
			// 	this.filters["clid"] = clid;
			// 	console.log(this.filters);
			// } else {
			this.filters = { clid: clid };
			// }
			this.$store.dispatch("TICKETS_SETFILTER", this.filters);
			router.push("/tickets");
		},

		setSubject() {
			alert(`${this.ticket.TI_SUBJECT} in ${this.ticket.TI_ID}`);
		},

		zurueck() {
			this.$router.back();
		},

		openSubjectEditor() {
			this.$bvModal.show("subject");
		},

		openPic() {
			this.$bvModal.show("picdetail");
		}
	}
};
