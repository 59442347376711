<template>
	<div class="container-fluid content" id="cars">
		<vue-headful
			:title="`${$target.toUpperCase()} Office | Cars`"
			description="Cars"
		/>
		<h1>Cars</h1>
		<b-row>
			<b-col xs="12">
				Department:
				<b-btn
					squared
					variant="warning"
					:pressed="depid === item.value"
					v-for="(item, index) in cardepartments"
					:key="index"
					:to="'/cars/' + item.value"
					>{{ item.text }}</b-btn
				>
			</b-col>
		</b-row>
		<b-row>
			<b-col xs="12" class="classlist">
				<b-btn
					squared
					v-for="(item, classname) in carclasses"
					:key="classname"
					:pressed="carclass === item"
					@click="toggleClass(item)"
					>{{ item }}</b-btn
				>
			</b-col>
		</b-row>
		<div class="box">
			<div class="carlist container-fluid" v-if="carlist !== null">
				<b-row v-for="(item, index) in carlist" :key="index" align-v="stretch">
					<b-col xs="4" lg="5" style="padding:4px 0">
						<img :src="pic_baseurl + item.CAR_IMAGE" class="carthumb" />
					</b-col>
					<b-col xs="8" lg="7" align-self="center">
						<b-row align-v="center">
							<b-col
								><span style="font-weight:bold;padding-right:15px">{{
									item.CAR_PLATE
								}}</span>
								{{ item.CAR_MAKE }} {{ item.CAR_MODEL }}</b-col
							>
						</b-row>
						<b-row>
							<b-col>
								<b-btn variant="primary" @click="setCar(item)">Edit</b-btn>
								<b-btn variant="danger" @click="hideCar(item.CAR_ID)"
									>Delete</b-btn
								>
								<b-btn @click="toggleLog(item.CAR_ID)">Log</b-btn>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<div class="carsingle container-fluid">
				<b-row>
					<b-col xs="12">
						<div class="text-center mb-3" v-if="dep_class">
							<b-btn type="button" variant="primary" @click="showAddCar()">
								<v-icon name="car" />&nbsp;&nbsp;<v-icon
									name="plus"
								/>&nbsp;&nbsp;Add one
							</b-btn>
						</div>
						<CarForm
							v-if="currentCarId != null"
							:carid="currentCarId"
							:car="currentCar"
							v-on:changecar="editCar"
							v-on:changecarpic="editCarPic"
						/>
					</b-col>
				</b-row>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import misc from "@/lib/misc";
import dayjs from "dayjs";
import carsSource from "@/lib/backend/cars";
import CarForm from "@/components/CarForm";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/car";

import "@/assets/scss/cars.scss";

export default {
	components: {
		CarForm
	},
	props: {},
	data() {
		return {
			isBusy: false,
			depid: null,
			carid: null,
			cardepartments: [],
			carclass: null,
			carclasses: misc.classes,
			carlist: null,
			currentCar: null,
			currentCarId: null,
			pic_baseurl: process.env.VUE_APP_API_URL + "/car_pictures/thumb/"
		};
	},

	computed: {
		dep_class: function() {
			return this.depid != null && this.carclass != null
				? this.depid + "-" + this.carclass
				: null;
		}
	},

	watch: {
		"$route.params.dept": {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(id, oldid) {
				if (id != this.depid) {
					this.depid = id;
					console.log("switch to " + this.depid);
				}
			}
		},
		dep_class: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) {
					this.currentCarId = null;
					this.getCars();
				}
			},
			deep: true
		}
	},

	created: function() {
		carsSource
			.getCarDepartments()
			.then(res => {
				this.cardepartments = res.data;
				// console.log(this.seasons);
			})
			.catch(error => {
				this.$toastr.e(error, "Error");
			});
	},
	mounted: function() {},

	methods: {
		setCar(car) {
			this.currentCarId = parseInt(car.CAR_ID);
			this.currentCar = car;
		},
		hideCar(id) {
			if (confirm("Are you sure?")) {
				carsSource
					.hideCar(id)
					// eslint-disable-next-line no-unused-vars
					.then(res => {
						this.getCars();
						this.$toastr.s("Car removed", "Success");
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			}
			return true;
		},
		toggleClass(classname) {
			if (this.carclass == classname) this.carclass = null;
			else this.carclass = classname;
			// console.log("carclass ",this.carclass)
		},
		getCars() {
			if (this.dep_class === null) {
				this.$toastr.e("Class or Department missing", "Error");
				return false;
			}

			carsSource
				.getCars(this.depid, this.carclass)
				.then(res => {
					this.carlist = res.data;
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		},
		editCar(data) {
			data.CAR_DEPT = this.depid;
			data.CAR_CLASS = this.carclass;
			if (data.CAR_ID == 0) {
				// console.log("send data: " + data)
				carsSource
					.addCar(data)
					.then(res => {
						data["CAR_ID"] = res.data.CAR_ID;
						this.carlist.push(data);
						this.currentCarId = parseInt(res.data.CAR_ID);
						this.currentCar = data;
						this.$toastr.s("Car added!", "Success");
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			} else {
				delete data.CAR_IMAGE;
				carsSource
					.editCar(data)
					// eslint-disable-next-line no-unused-vars
					.then(res => {
						this.$toastr.s("Car changed!", "Success");
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			}
			return true;
		},
		editCarPic(img) {
			console.log("Got ", img, " for ", this.currentCarId);
			for (let i in this.carlist) {
				if (this.carlist[i].CAR_ID == this.currentCarId) {
					this.carlist[i]["CAR_IMAGE"] =
						img + "?updated=" + dayjs().format("YYYYMMDDhhMMSS");
				}
			}
			this.$forceUpdate();
		},

		showAddCar() {
			this.currentCarId = 0;
			this.currentCar = {
				CAR_ID: 0,
				CAR_PLATE: "",
				CAR_MAKE: "",
				CAR_MODEL: ""
			};
		}
	}
};
</script>
