<template>
	<div class="vertnav" v-if="loggedin">
		<div class="logoholder">
			<img
				:src="require('@/assets/images/logo_' + $target + '.svg')"
				style="width:40px"
				@click="toggleNav"
			/>
		</div>
		<div v-if="navstatus">
			<NavElement link="/tickets" icon="receipt" navname="Tickets" vis="2" />

			<NavElement
				link="/clients"
				icon="person-square"
				navname="Clients"
				vis="2"
			/>

			<NavElement link="/settings" icon="tools" navname="Settings" vis="2" />

			<NavElement
				link="/stats"
				icon="pie-chart-fill"
				navname="Stats & Data"
				vis="2"
			/>

			<NavElement link="/cars" icon="key-fill" navname="Cars" vis="2" />

			<NavElement
				link="/planner"
				icon="calendar-check-fill"
				navname="Planner"
				vis="2"
			/>

			<NavElement link="/logs" icon="card-list" navname="Log" vis="0" />

			<div
				class="menuitem h1 mt-4"
				@mouseover="logoutmenu = true"
				@mouseleave="logoutmenu = false"
			>
				<div class="icon exit">
					<v-icon
						name="sign-out-alt"
						scale="3"
						id="icon-exit"
						@click="signout"
					></v-icon>
				</div>
				<div class="mtext" v-if="logoutmenu">
					<a @click="signout">Sign out</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import NavElement from "@/components/NavElement.vue";
import "vue-awesome/icons/sign-out-alt";

export default {
	components: {
		NavElement
	},
	name: "VerticalNavigation",
	props: {
		msg: String
	},
	data() {
		return {
			logoutmenu: false,
			tickets: false
		};
	},

	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			navstatus: state => state.helpers.navstatus,
			username: state => state.auth.userdata.USR_LOGIN,
			userstatus: state => state.auth.status
		})
	},

	created: function() {
		// console.log(this.loggedin)
	},
	methods: {
		signout: function() {
			this.logoutmenu = false;
			this.$store.dispatch("AUTH_LOGOUT");
			this.$router.push("/login");
		},

		toggleNav: function() {
			this.$store.dispatch("HELPERS_NAVTOGGLE");
			document.body.className = this.navstatus == true ? "" : "planner";
			console.log("toogle das ding");
		}
	}
};
</script>
