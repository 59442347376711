// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	todoList() {
		return API.post("/v1/admin/stats/sendtodolist");
	},
	exportData(data) {
		return API.post("/v1/admin/stats/export", data);
	}
};
