import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import mailingSource from "@/lib/backend/mailings";
import dayjs from "dayjs";
import {
	setInteractionMode,
	ValidationProvider,
	ValidationObserver,
	extend
} from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import AvatarUpload from "@/components/AvatarUpload";
import { BFormGroup, BFormCheckbox } from "bootstrap-vue";
import tableFields from "@/lib/misc";
setInteractionMode("lazy");

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-checkbox", BFormCheckbox);
const now = dayjs();
// .subtract(1, "year")
// .format("YYYY-MM-DD");

extend("required", {
	...required,
	message: "This field is required"
});
extend("email", {
	...email,
	message: "Please enter a valid email address"
});
extend("emails", value => {
	if (value == "") return true;
	let alle = value.split(",");
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	for (const val of alle) {
		if (!re.test(String(val.trim()).toLowerCase())) return false;
	}
	return true;
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		AvatarUpload
	},
	props: {
		maid: Number,
		newmailing: Boolean
	},
	watch: {
		maid: {
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != 0) {
					console.log("new data for " + newVal);
					this.getMailingData(newVal);
				} else this.mailing = {};
			},
			deep: true
		}
	},
	data() {
		return {
			mailing: {},
			searchparams: {},
			amount: null,
			senddate: null,
			expired: false,
			languages: tableFields.languages
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token,
			departments: state => state.helpers.departments
		})
	},

	mounted: function() {},
	methods: {
		getMailingData(id) {
			mailingSource
				.getSingleMailing(id)
				.then(this.handleSingleMailingResponse)
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		},
		handleSingleMailingResponse(response) {
			this.mailing = response.data;
			this.senddate = dayjs(this.mailing.MA_SENDDATE);
			this.expired = this.senddate < now;
			let sparms = JSON.parse(this.mailing.MA_SEARCH);
			if (sparms) {
				if (sparms.search_start) {
					sparms.search_start = dayjs(sparms.search_start, "DD-MM-YYYY").format(
						"YYYY-MM-DD"
					);
				}
				if (sparms.search_stop) {
					sparms.search_stop = dayjs(sparms.search_stop, "DD-MM-YYYY").format(
						"YYYY-MM-DD"
					);
				}
				this.searchparams = sparms;
			} else {
				this.searchparams = {};
			}
			console.log(this.mailing);
		},

		transmitForm() {
			if (this.maid && parseInt(this.maid) > 0) {
				this.updateMailingData();
			} else {
				this.addMailing();
			}
		},

		collectMailingData() {
			let search = null;
			if (this.searchparams.search_hasvoucher) {
				search = { search_hasvoucher: 1 };
				if (this.searchparams.search_start) {
					search["search_start"] = dayjs(this.searchparams.search_start).format(
						"DD-MM-YYYY"
					);
				}
				if (this.searchparams.search_stop) {
					search["search_stop"] = dayjs(this.searchparams.search_stop).format(
						"DD-MM-YYYY"
					);
				}
			}
			let senddata = {
				MA_SUBJECT: this.mailing.MA_SUBJECT,
				MA_TEXT: this.mailing.MA_TEXT,
				MA_HTML: this.mailing.MA_HTML,
				MA_FROM: this.mailing.MA_FROM,
				MA_SENDDATE: this.mailing.MA_SENDDATE,
				MA_SEARCH: JSON.stringify(search),
				MA_DEPID: this.mailing.MA_DEPID,
				MA_LANG: this.mailing.MA_LANG,
				MA_TESTRECIPIENTS: this.mailing.MA_TESTRECIPIENTS
			};
			return senddata;
		},

		updateMailingData() {
			this.senddata = this.collectMailingData();
			this.senddata["MA_ID"] = this.maid;
			mailingSource
				.updateMailing(this.senddata)
				.then(this.handleUpdateMailingResponse)
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		},
		handleUpdateMailingResponse(response) {
			console.log(response);
			this.$toastr.s("Mailing changed", "Success");
			this.$bvModal.hide("mailingsingle");
		},

		addMailing() {
			this.senddata = this.collectMailingData();
			mailingSource
				.addMailing(this.senddata)
				.then(this.handleAddMailingResponse)
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		},
		// eslint-disable-next-line no-unused-vars
		handleAddMailingResponse(response) {
			this.$store.dispatch("MAILINGS_REQUEST", true);

			this.$toastr.s("Mailing added", "Success");
			this.$bvModal.hide("mailingsingle");
		},

		sendTestMail() {
			this.senddata = this.collectMailingData();
			this.senddata["MA_ID"] = this.maid;
			mailingSource
				.sendTestMailing(this.senddata)
				.then(() => {
					this.$toastr.s(
						"Mail sent to " + this.senddata.MA_TESTRECIPIENTS,
						"Test sent"
					);
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		},
		getRecipientAmount() {
			let params = {
				start: this.searchparams.search_start,
				stop: this.searchparams.search_stop,
				language: this.mailing.MA_LANG,
				hasvoucher: this.searchparams.search_hasvoucher,
				depid: this.mailing.MA_DEPID
			};
			console.log("grab ", params);
			mailingSource
				.getRecipientAmount(params)
				.then(response => {
					this.amount = parseInt(response.data.amount);
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		}
	}
};
