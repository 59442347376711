// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getMailings(page, per_page) {
		return API.get("/v1/admin/mailings");
	},
	getSingleMailing(clid) {
		return API.get("/v1/admin/mailings/" + clid);
	},
	addMailing(data) {
		return API.post("/v1/admin/mailings/create", data);
	},
	updateMailing(data) {
		return API.post("/v1/admin/mailings/edit", data);
	},
	deleteMailing(data) {
		return API.post("/v1/admin/mailings/delete", data);
	},
	getRecipientAmount(data) {
		return API.post("/v1/admin/mailings/getamount", data);
	},
	sendTestMailing(data) {
		return API.post("/v1/admin/mailings/sendtestmail", data);
	}
};
