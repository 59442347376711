<template>
	<div class="container-fluid content mt-3">
		<ValidationObserver v-slot="{ handleSubmit }">
			<b-form @submit.prevent="handleSubmit(editCar)">
				<b-row class="single-head">
					<b-col class="headline">
						Car Data
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group id="CAR_MAKE" label="Make" label-for="CAR_MAKE">
							<validation-provider rules="required" v-slot="{ errors }">
								<b-form-input
									id="CAR_MAKE"
									v-model="car.CAR_MAKE"
									placeholder="Enter Make of car"
								></b-form-input>
								<span class="formerror">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
						<p />
						<b-form-group id="CAR_MODEL" label="Model" label-for="CAR_MODEL">
							<validation-provider rules="required" v-slot="{ errors }">
								<b-form-input
									id="CAR_MODEL"
									v-model="car.CAR_MODEL"
									placeholder="Enter Model of car"
								></b-form-input>
								<span class="formerror">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
						<p />
						<b-form-group
							id="CAR_PLATE"
							label="Numberplate"
							label-for="CAR_PLATE"
						>
							<validation-provider rules="required" v-slot="{ errors }">
								<b-form-input
									id="CAR_PLATE"
									v-model="car.CAR_PLATE"
									placeholder="Enter Number plate"
								></b-form-input>
								<span class="formerror">{{ errors[0] }}</span>
							</validation-provider>
						</b-form-group>
						<p />
						<b-form-group id="CAR_MODEL" label="Extras" label-for="CAR_EXTRAS">
							<b-form-input
								id="CAR_EXTRAS"
								v-model="car.CAR_EXTRAS"
								placeholder="Extras?"
							></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-center mt-2">
						<b-button type="submit" variant="primary" v-if="carid">
							Change</b-button
						>
						<b-button type="submit" variant="primary" v-else> Add</b-button>
					</b-col>
				</b-row>
			</b-form>
		</ValidationObserver>
		<hr />
		<CarPicUpload
			v-if="carid != 0"
			:CAR_ID="car.CAR_ID"
			:carimage="car.CAR_IMAGE"
			@changepic="updateCarImageInData"
		/>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CarPicUpload from "@/components/CarPicUpload";
import "vue-awesome/icons/check-circle";

export default {
	props: {
		carid: Number,
		car: Object
	},

	components: {
		ValidationProvider,
		ValidationObserver,
		CarPicUpload
	},

	watch: {
		department: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.getPriceSettings(newVal);
			},
			deep: true
		}
	},

	data() {
		return {
			isBusy: false
		};
	},

	created: function() {},

	methods: {
		editCar() {
			this.$emit("changecar", this.car);
			return true;
		},
		updateCarImageInData(img) {
			// this.car.CAR_IMAGE = img
			console.log("Emitting " + img);
			this.$emit("changecarpic", img);
		}
	}
};
</script>
