var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "singlebooking",
      attrs: {
        id: "bookingsingle",
        "hide-footer": "",
        "hide-header": "",
        size: "xl"
      }
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid content" },
        [
          _c(
            "b-row",
            { staticClass: "dark" },
            [
              _vm.booking.BO_ID
                ? _c("b-col", { staticClass: "headline" }, [
                    _vm._v(" Booking "),
                    _c("i", [_vm._v(_vm._s(_vm.booking.BO_NAME))]),
                    _vm._v(" (ID " + _vm._s(_vm.booking.BO_ID) + " ) "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.booking.BO_EMAIL) + " ")
                  ])
                : _c("b-col", { staticClass: "headline" }, [
                    _vm._v(" New Booking ")
                  ])
            ],
            1
          )
        ],
        1
      ),
      !_vm.clEditMode
        ? _c(
            "div",
            { staticClass: "container-fluid topbar mt-3" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("b", [_vm._v("Client: ")]),
                    _vm._v(
                      _vm._s(_vm.booking.CL_FIRSTNAME) +
                        " " +
                        _vm._s(_vm.booking.CL_LASTNAME) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" Tel.: " + _vm._s(_vm.booking.CL_MOBILE) + " "),
                    _c("br"),
                    _vm._v(
                      " License #: " +
                        _vm._s(_vm.booking.CL_DRIVERLICENSE) +
                        " "
                    )
                  ]),
                  _vm.booking.CL_LASTNAME2
                    ? _c("b-col", [
                        _c("b", [_vm._v("Client 2: ")]),
                        _vm._v(
                          _vm._s(_vm.booking.CL_FIRSTNAME2) +
                            " " +
                            _vm._s(_vm.booking.CL_LASTNAME2) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " Tel.: " + _vm._s(_vm.booking.CL_MOBILE2) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          " License #: " +
                            _vm._s(_vm.booking.CL_DRIVERLICENSE2) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "b-col",
                    {
                      staticClass: "headline text-right",
                      staticStyle: { "padding-right": "30px" }
                    },
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: { size: "sm" },
                          on: { click: _vm.toggleEditClient }
                        },
                        [
                          _c("v-icon", { attrs: { name: "user", scale: "1" } }),
                          _vm._v("Edit")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.EditClient)
                      }
                    }
                  },
                  [
                    _vm.clEditMode
                      ? _c(
                          "b-container",
                          { staticClass: "topbar mt-3", attrs: { fluid: "" } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" First Name 1 "),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  staticClass: "inner",
                                                  attrs: {
                                                    size: "sm",
                                                    id: "CL_FIRSTNAME",
                                                    placeholder: "First Name"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.booking.CL_FIRSTNAME,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.booking,
                                                        "CL_FIRSTNAME",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "booking.CL_FIRSTNAME"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" Last Name 1 "),
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  staticClass: "inner",
                                                  attrs: {
                                                    size: "sm",
                                                    id: "CL_LASTNAME",
                                                    placeholder: "Last Name"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.booking.CL_LASTNAME,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.booking,
                                                        "CL_LASTNAME",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "booking.CL_LASTNAME"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" First Name 2 "),
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "CL_FIRSTNAME2",
                                        placeholder: "First Name 2"
                                      },
                                      model: {
                                        value: _vm.booking.CL_FIRSTNAME2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "CL_FIRSTNAME2",
                                            $$v
                                          )
                                        },
                                        expression: "booking.CL_FIRSTNAME2"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" Last Name 2 "),
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "CL_LASTNAME2",
                                        placeholder: "Last Name 2"
                                      },
                                      model: {
                                        value: _vm.booking.CL_LASTNAME2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "CL_LASTNAME2",
                                            $$v
                                          )
                                        },
                                        expression: "booking.CL_LASTNAME2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" Mobile 1 "),
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "CL_MOBILE",
                                        placeholder: "Mobile"
                                      },
                                      model: {
                                        value: _vm.booking.CL_MOBILE,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "CL_MOBILE",
                                            $$v
                                          )
                                        },
                                        expression: "booking.CL_MOBILE"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" License 1 "),
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "CL_DRIVERLICENSE",
                                        placeholder: "Driving License No."
                                      },
                                      model: {
                                        value: _vm.booking.CL_DRIVERLICENSE,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "CL_DRIVERLICENSE",
                                            $$v
                                          )
                                        },
                                        expression: "booking.CL_DRIVERLICENSE"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" Mobile 2 "),
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "CL_MOBILE2",
                                        placeholder: "Mobile"
                                      },
                                      model: {
                                        value: _vm.booking.CL_MOBILE2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "CL_MOBILE2",
                                            $$v
                                          )
                                        },
                                        expression: "booking.CL_MOBILE2"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { xs: "12", lg: "3" } },
                                  [
                                    _vm._v(" License 2 "),
                                    _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "CL_DRIVERLICENSE2",
                                        placeholder: "2nd Driving License No."
                                      },
                                      model: {
                                        value: _vm.booking.CL_DRIVERLICENSE2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "CL_DRIVERLICENSE2",
                                            $$v
                                          )
                                        },
                                        expression: "booking.CL_DRIVERLICENSE2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "headline",
                                    staticStyle: { "padding-left": "30px" }
                                  },
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: { size: "sm" },
                                        on: { click: _vm.toggleEditClient }
                                      },
                                      [
                                        _c("v-icon", {
                                          attrs: {
                                            name: "times-circle",
                                            scale: "1"
                                          }
                                        }),
                                        _vm._v("Close")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "headline text-right",
                                    staticStyle: { "padding-right": "30px" }
                                  },
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        attrs: {
                                          size: "sm",
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [
                                        _c("v-icon", {
                                          attrs: { name: "user", scale: "1" }
                                        }),
                                        _vm._v("Edit")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.transmitForm)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container-fluid content mt-3" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "text-center mt-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    }
                                  },
                                  [
                                    _vm.booking.BO_ID
                                      ? _c("span", [_vm._v("Change")])
                                      : _c("span", [_vm._v("Create")])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "text-center mt-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "secondary" },
                                    on: { click: _vm.makeVoucher }
                                  },
                                  [_vm._v(" Make Voucher ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "6" } },
                              [
                                _vm._v(" Status: "),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-select", {
                                              staticClass: "inner",
                                              attrs: {
                                                size: "sm",
                                                options:
                                                  _vm.bookingStatusOptions
                                              },
                                              model: {
                                                value: _vm.booking.BO_STATUS,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.booking,
                                                    "BO_STATUS",
                                                    $$v
                                                  )
                                                },
                                                expression: "booking.BO_STATUS"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Provider "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.ProviderOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_CARCLASS,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_CARCLASS", $$v)
                                    },
                                    expression: "booking.BO_CARCLASS"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("Placeholder")]
                                ),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_CARCLASS",
                                    placeholder: "Class"
                                  },
                                  model: {
                                    value: _vm.booking.BO_CARCLASS,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_CARCLASS", $$v)
                                    },
                                    expression: "booking.BO_CARCLASS"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "5" } },
                              [
                                _vm._v(" Planned: "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.AvailableCarOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_CARID,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_CARID", $$v)
                                    },
                                    expression: "booking.BO_CARID"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-left",
                                attrs: { xs: "12", lg: "1" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("PP")]
                                ),
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      variant: "outline-primary",
                                      value: "1",
                                      name: "check-button",
                                      size: "sm"
                                    },
                                    on: { click: _vm.getAvailableCars }
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "arrow-counterclockwise",
                                        "aria-hidden": "true"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _vm._v(" On Voucher: "),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-select", {
                                              staticClass: "inner",
                                              attrs: {
                                                size: "sm",
                                                options:
                                                  _vm.AvailableCarTypesOptions
                                              },
                                              model: {
                                                value: _vm.booking.BO_CAR,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.booking,
                                                    "BO_CAR",
                                                    $$v
                                                  )
                                                },
                                                expression: "booking.BO_CAR"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      name: "check-button",
                                      value: "1",
                                      "unchecked-value": "0",
                                      switch: ""
                                    },
                                    model: {
                                      value: _vm.booking.BO_KIDSEAT,
                                      callback: function($$v) {
                                        _vm.$set(_vm.booking, "BO_KIDSEAT", $$v)
                                      },
                                      expression: "booking.BO_KIDSEAT"
                                    }
                                  },
                                  [
                                    _vm.booking.BO_KIDSEAT == "1"
                                      ? _c("b", { staticClass: "blinking" }, [
                                          _vm._v("Child Seat")
                                        ])
                                      : _c("b", [_vm._v("Child Seat")])
                                  ]
                                ),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.ChildSeatOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_CHILDSEAT_TEXT,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.booking,
                                        "BO_CHILDSEAT_TEXT",
                                        $$v
                                      )
                                    },
                                    expression: "booking.BO_CHILDSEAT_TEXT"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("Placeholder")]
                                ),
                                _vm.booking.BO_CHILDSEAT_TEXT != "No"
                                  ? _c("b-form-input", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        id: "BO_CHILDSEAT_TEXT",
                                        placeholder: "Class"
                                      },
                                      model: {
                                        value: _vm.booking.BO_CHILDSEAT_TEXT,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.booking,
                                            "BO_CHILDSEAT_TEXT",
                                            $$v
                                          )
                                        },
                                        expression: "booking.BO_CHILDSEAT_TEXT"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Insurance "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.InsuranceOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_INSURANCE,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_INSURANCE", $$v)
                                    },
                                    expression: "booking.BO_INSURANCE"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Own Risk "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.RiskOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_RISK,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_RISK", $$v)
                                    },
                                    expression: "booking.BO_RISK"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm._v(" Hotel "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_HOTEL",
                                    placeholder: "Hotel"
                                  },
                                  model: {
                                    value: _vm.booking.BO_HOTEL,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_HOTEL", $$v)
                                    },
                                    expression: "booking.BO_HOTEL"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _vm._v(" Location "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_LOCATION",
                                    placeholder: "Location"
                                  },
                                  model: {
                                    value: _vm.booking.BO_LOCATION,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_LOCATION", $$v)
                                    },
                                    expression: "booking.BO_LOCATION"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Pickup "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.PickupOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_PICKUP,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_PICKUP", $$v)
                                    },
                                    expression: "booking.BO_PICKUP"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("Placeholder")]
                                ),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              staticClass: "inner",
                                              attrs: {
                                                size: "sm",
                                                id: "BO_PICKUP",
                                                placeholder: "Pickup Location"
                                              },
                                              model: {
                                                value: _vm.booking.BO_PICKUP,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.booking,
                                                    "BO_PICKUP",
                                                    $$v
                                                  )
                                                },
                                                expression: "booking.BO_PICKUP"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Dropoff "),
                                _c("b-form-select", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    options: _vm.PickupOptions
                                  },
                                  model: {
                                    value: _vm.booking.BO_RETURN,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_RETURN", $$v)
                                    },
                                    expression: "booking.BO_RETURN"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "transparent" } },
                                  [_vm._v("Placeholder")]
                                ),
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              staticClass: "inner",
                                              attrs: {
                                                size: "sm",
                                                id: "BO_PICKUP",
                                                placeholder: "Dropoff Location"
                                              },
                                              model: {
                                                value: _vm.booking.BO_RETURN,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.booking,
                                                    "BO_RETURN",
                                                    $$v
                                                  )
                                                },
                                                expression: "booking.BO_RETURN"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-datepicker", {
                                              attrs: {
                                                dark: "",
                                                size: "sm",
                                                "initial-date":
                                                  _vm.booking.BO_STARTD,
                                                locale: "nl",
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "numeric",
                                                  day: "numeric"
                                                }
                                              },
                                              model: {
                                                value: _vm.booking.BO_STARTD,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.booking,
                                                    "BO_STARTD",
                                                    $$v
                                                  )
                                                },
                                                expression: "booking.BO_STARTD"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_STARTT",
                                    placeholder: "Start Time"
                                  },
                                  model: {
                                    value: _vm.booking.BO_STARTT,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_STARTT", $$v)
                                    },
                                    expression: "booking.BO_STARTT"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c("validation-provider", {
                                  attrs: { rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-datepicker", {
                                              attrs: {
                                                dark: "",
                                                size: "sm",
                                                "initial-date":
                                                  _vm.booking.BO_STOPD,
                                                locale: "nl",
                                                "date-format-options": {
                                                  year: "numeric",
                                                  month: "numeric",
                                                  day: "numeric"
                                                }
                                              },
                                              model: {
                                                value: _vm.booking.BO_STOPD,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.booking,
                                                    "BO_STOPD",
                                                    $$v
                                                  )
                                                },
                                                expression: "booking.BO_STOPD"
                                              }
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "formerror" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_STOPT",
                                    placeholder: "Stop Time"
                                  },
                                  model: {
                                    value: _vm.booking.BO_STOPT,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_STOPT", $$v)
                                    },
                                    expression: "booking.BO_STOPT"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm._v(" Total Days "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_HIREDAYS",
                                    placeholder: "Total days"
                                  },
                                  model: {
                                    value: _vm.booking.BO_HIREDAYS,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_HIREDAYS", $$v)
                                    },
                                    expression: "booking.BO_HIREDAYS"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _vm._v(" Flight No. "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_FLIGHTNO",
                                    placeholder: "Flight No."
                                  },
                                  model: {
                                    value: _vm.booking.BO_FLIGHTNO,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_FLIGHTNO", $$v)
                                    },
                                    expression: "booking.BO_FLIGHTNO"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Price car "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_PRICE",
                                    placeholder: "Price"
                                  },
                                  model: {
                                    value: _vm.booking.BO_PRICE,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_PRICE", $$v)
                                    },
                                    expression: "booking.BO_PRICE"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Total "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_PRICE_SUM",
                                    placeholder: "Price Sum"
                                  },
                                  model: {
                                    value: _vm.booking.BO_PRICE_SUM,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_PRICE_SUM", $$v)
                                    },
                                    expression: "booking.BO_PRICE_SUM"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Extras "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_PRICE_EXTRAS",
                                    placeholder: "Price Extras"
                                  },
                                  model: {
                                    value: _vm.booking.BO_PRICE_EXTRAS,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.booking,
                                        "BO_PRICE_EXTRAS",
                                        $$v
                                      )
                                    },
                                    expression: "booking.BO_PRICE_EXTRAS"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _vm._v(" Deposit "),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_DEPOSIT",
                                    placeholder: "Deposit"
                                  },
                                  model: {
                                    value: _vm.booking.BO_DEPOSIT,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_DEPOSIT", $$v)
                                    },
                                    expression: "booking.BO_DEPOSIT"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12" } },
                              [
                                _vm._v(" Text "),
                                _c("b-form-select", {
                                  attrs: {
                                    size: "sm",
                                    options: _vm.Freetext1Options
                                  },
                                  model: {
                                    value: _vm.booking.BO_FREETEXT1,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_FREETEXT1", $$v)
                                    },
                                    expression: "booking.BO_FREETEXT1"
                                  }
                                }),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_FREETEXT1",
                                    placeholder: ""
                                  },
                                  model: {
                                    value: _vm.booking.BO_FREETEXT1,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_FREETEXT1", $$v)
                                    },
                                    expression: "booking.BO_FREETEXT1"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12" } },
                              [
                                _vm._v(" Text 2 "),
                                _c("b-form-select", {
                                  attrs: {
                                    size: "sm",
                                    options: _vm.Freetext2Options
                                  },
                                  model: {
                                    value: _vm.booking.BO_FREETEXT2,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_FREETEXT2", $$v)
                                    },
                                    expression: "booking.BO_FREETEXT2"
                                  }
                                }),
                                _c("b-form-input", {
                                  staticClass: "inner",
                                  attrs: {
                                    size: "sm",
                                    id: "BO_FREETEX21",
                                    placeholder: ""
                                  },
                                  model: {
                                    value: _vm.booking.BO_FREETEXT2,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_FREETEXT2", $$v)
                                    },
                                    expression: "booking.BO_FREETEXT2"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm._v(" Return Todo "),
                                _c("b-form-textarea", {
                                  attrs: { size: "sm", rows: "5" },
                                  model: {
                                    value: _vm.booking.BO_RETURN_TODO,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.booking,
                                        "BO_RETURN_TODO",
                                        $$v
                                      )
                                    },
                                    expression: "booking.BO_RETURN_TODO"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _vm._v(" Remarks on plan "),
                                _c("b-form-textarea", {
                                  attrs: { size: "sm", rows: "5" },
                                  model: {
                                    value: _vm.booking.BO_EXTRA,
                                    callback: function($$v) {
                                      _vm.$set(_vm.booking, "BO_EXTRA", $$v)
                                    },
                                    expression: "booking.BO_EXTRA"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }