<template>
	<b-tab title="Export Client Email Addresses">
		<b-card-text>
			<b-row align-h="center">
				<b-col sm="6" lg="3">
					<span class="fieldname">From:</span>
					<b-input-group class="mb-3 datebox">
						<b-form-datepicker
							v-model="fromdate"
							button-only
							dropright
							:initial-date="startDate"
							locale="nl"
							aria-controls="example-input"
							@context="onContext"
						></b-form-datepicker>
						<span class="fieldval">
							{{ fromdate || "YYYY-mm-dd" }}
						</span>
					</b-input-group>
				</b-col>
				<b-col sm="6" lg="3">
					<span class="fieldname">To:</span>
					<b-input-group class="mb-3 datebox">
						<b-form-datepicker
							v-model="todate"
							button-only
							dropright
							locale="en-US"
							aria-controls="example-input"
							@context="onContext"
						></b-form-datepicker>
						<span class="fieldval">
							{{ todate || "YYYY-mm-dd" }}
						</span>
					</b-input-group>
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-col class="mb-3" xs="6" lg="3">
					Dept:
					<b-form-select
						class="inner"
						v-model="department"
						:options="departments"
					></b-form-select>
				</b-col>
				<b-col class="mb-3" xs="6" lg="3">
					Voucher:
					<b-form-select
						class="inner"
						v-model="voucher"
						:options="voucherOptions"
					></b-form-select>
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-col class="mb-3" xs="12" lg="6">
					Language:
					<b-form-select
						class="inner"
						v-model="language"
						:options="languageOptions"
					></b-form-select>
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-col class="mb-3 text-center" xs="12" lg="6">
					<b-button-group size="lg">
						<b-button variant="success" @click="exportData('clients', 'XML')"
							>Export XML</b-button
						>
						<b-button variant="info" @click="exportData('clients', 'HTML')"
							>Export HTML</b-button
						>
						<b-button variant="warning" @click="exportData('clients', 'CSV')"
							>Export CSV</b-button
						>
					</b-button-group>
				</b-col>
			</b-row>
		</b-card-text>
	</b-tab>
</template>

<script>
import "@/assets/scss/stats.scss";
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import statsSource from "@/lib/backend/stats";
import miscFields from "@/lib/misc";
import dayjs from "dayjs";
import { BCard, BTabs, BTab, BInputGroup, BButtonGroup } from "bootstrap-vue";
Vue.component("b-card", BCard);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-button-group", BButtonGroup);
Vue.component("b-input-group", BInputGroup);
// const startDate = dayjs()
// 	.subtract(2, "year")
// 	.format("YYYY-MM-DD");
const startDate = dayjs().format("YYYY-MM-DD");
const filetimestamp = dayjs().format("YYYY-MM-DD");

export default {
	data() {
		return {
			fromdate: null,
			todate: null,
			department: null,
			voucher: null,
			language: null,
			hideDatePickerHeader: true,
			startDate: startDate,
			languageOptions: miscFields.languages,
			voucherOptions: miscFields.voucherOptions
		};
	},
	computed: {
		...mapState({
			departments: state => state.helpers.departments
		})
	},
	created: function() {},

	methods: {
		exportData(stats, type) {
			let params = {
				stats: stats,
				language: this.language,
				department: this.department,
				fromdate: this.fromdate,
				todate: this.todate,
				voucher: this.voucher,
				type: type
			};
			let dlname = stats + "_" + filetimestamp + "." + type.toLowerCase();
			statsSource
				.exportData(params)
				.then(response => {
					let ctype = response.headers["content-type"].split(";")[0];
					let blob = new Blob([response.data], { type: ctype });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					if (type !== "HTML") {
						link.download = dlname;
					} else {
						link.target = "_blank";
					}
					link.click();
				})
				// eslint-disable-next-line no-unused-vars
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: "Could not download File " + dlname,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		onContext(ctx) {
			// The date formatted in the locale, or the `label-no-date-selected` string
			this.formatted = ctx.selectedFormatted;
			// The following will be an empty string until a valid date is entered
			this.selected = ctx.selectedYMD;
			console.log(this.formatted + " vs " + this.selected);
		}
	}
};
</script>
