var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "messages" } },
    [
      _c("b-table", {
        attrs: {
          striped: "",
          items: _vm.messages,
          fields: _vm.fields,
          "head-variant": "light",
          "primary-key": "MSG_ID",
          small: "",
          "tbody-tr-class": "messagetable"
        },
        on: { "row-clicked": _vm.expandSingleMessage },
        scopedSlots: _vm._u([
          {
            key: "cell(MSG_ID)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  { staticClass: "toggler", on: { click: row.toggleDetails } },
                  [
                    !row.detailsShowing
                      ? _c("v-icon", {
                          staticClass: "detailbutton",
                          attrs: { name: "angle-up", scale: "1.5" }
                        })
                      : _vm._e(),
                    row.detailsShowing
                      ? _c("v-icon", {
                          staticClass: "detailbutton",
                          attrs: { name: "angle-down", scale: "1.5" }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(MSG_FROM)",
            fn: function(data) {
              return [_c("sendername", { attrs: { row: data.item } })]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-5" },
                      [
                        _c(
                          "b-col",
                          { staticClass: "messagetext", attrs: { cols: "12" } },
                          [_vm._v(_vm._s(row.item.MSG_TEXT))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { name: "check-button", switch: "" },
                                on: { change: _vm.setQuotes },
                                model: {
                                  value: _vm.quotes[row.index],
                                  callback: function($$v) {
                                    _vm.$set(_vm.quotes, row.index, $$v)
                                  },
                                  expression: "quotes[row.index]"
                                }
                              },
                              [_c("b", [_vm._v("Quote")])]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { size: "sm" },
                                on: { click: row.toggleDetails }
                              },
                              [_vm._v("Hide Details")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "b-container",
        { staticClass: "topbar", attrs: { fluid: "", id: "reply" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "1" } }, [
                _c("b", [_vm._v("Reply")])
              ]),
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c("b-form-select", {
                    attrs: { size: "sm", options: _vm.templates },
                    on: { change: _vm.parseTemplate },
                    model: {
                      value: _vm.usetemplate,
                      callback: function($$v) {
                        _vm.usetemplate = $$v
                      },
                      expression: "usetemplate"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "b-btn",
                    { attrs: { size: "sm" }, on: { click: _vm.parseTemplate } },
                    [_vm._v("Insert")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "3" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function($event) {
                          return _vm.sendReply(1)
                        }
                      }
                    },
                    [_vm._v("Send And Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function($event) {
                          return _vm.sendReply(0)
                        }
                      }
                    },
                    [_vm._v("Send Reply")]
                  ),
                  _vm._v(" "),
                  _vm.whatsappPhone != ""
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: {
                            click: function($event) {
                              return _vm.sendWhatsapp()
                            }
                          }
                        },
                        [_vm._v("Send Whatsapp")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "subjectfield",
                        "label-cols-sm": "2",
                        "label-cols-lg": "1",
                        label: "Subject",
                        "label-for": "input-subject"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          size: "sm",
                          id: "input-horizontal",
                          placeholder: "Enter a subject"
                        },
                        model: {
                          value: _vm.lastSubject,
                          callback: function($$v) {
                            _vm.lastSubject = $$v
                          },
                          expression: "lastSubject"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "textarea",
                      placeholder: "Enter something...",
                      rows: "14"
                    },
                    model: {
                      value: _vm.reply,
                      callback: function($$v) {
                        _vm.reply = $$v
                      },
                      expression: "reply"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _vm.PDF_URL
                    ? _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "check-button", switch: "" },
                          model: {
                            value: _vm.attachpdf,
                            callback: function($$v) {
                              _vm.attachpdf = $$v
                            },
                            expression: "attachpdf"
                          }
                        },
                        [_c("b", [_vm._v("Attach PDF")])]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bblock" }, [_c("h1")])
  }
]
render._withStripped = true

export { render, staticRenderFns }