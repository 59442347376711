/* eslint-disable no-undef */
import axios from "axios";
import store from "../../store";

const getToken = () => {
	return localStorage.getItem("userToken");
};

const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3000/api";

const axiosInstance = axios.create({
	baseURL: API_URL
	// headers: {
	// 	"Content-Type": "application/json",
	// 	"Authorization": "Bearer " + token
	// }
});

axiosInstance.interceptors.request.use(config => {
	config.headers.common["Content-Type"] = "application/json";
	config.headers.common["Authorization"] = "Bearer " + getToken();
	return config;
});

axiosInstance.interceptors.response.use(
	function(response) {
		if (response.headers["authtoken"]) {
			// console.log(response.headers);
			localStorage.setItem("userToken", response.headers["authtoken"]);
		}

		return response;
	},
	function(error) {
		console.log("helalo");
		if (error.response.status == 400) {
			window.location.href = "/login";
		}
		if (error.response.status == 401) {
			// eslint-disable-next-line no-undef
			store.dispatch(AUTH_LOGOUT).then(() => {
				router.push({ name: "login" });
				router.go();
			});
		} else if (![400, 422].includes(error.response.status)) {
			Vue.prototype.$toastr.Add({
				title: "Achtung",
				msg:
					'Es ist Fehler in der Anwendung aufgetreten. Bitte wenden Sie sich an den Support: "' +
					error.message +
					'"',
				position: "toast-bottom-center",
				type: "error"
			});
		}
		return Promise.reject(error);
	}
);
export default axiosInstance;
