/* eslint-disable no-unused-vars */
import {
	MAILINGS_REQUEST,
	MAILINGS_ERROR,
	MAILINGS_SUCCESS,
	MAILINGS_CHANGE,
	MAILINGS_SETFILTER,
	MAILINGS_CLEARFILTER,
	MAILINGS_REMOVE_ENTRY
} from "../actions/mailings";
import mailingSource from "@/lib/backend/mailings";

// localStorage.removeItem("mailings");

const state = {
	isBusy: true,
	showold: JSON.parse(localStorage.getItem("showold")) || 2,
	mailings: JSON.parse(localStorage.getItem("mailings")) || [],
	filters: JSON.parse(localStorage.getItem("mailing_filters")) || {}
};

const getters = {
	// showold: state => state.showold,
};

const actions = {
	[MAILINGS_REQUEST]: ({ commit, dispatch }, force) => {
		return new Promise((resolve, reject) => {
			// console.log("REQUEST MAILINGS")
			commit(MAILINGS_REQUEST);
			if (localStorage.getItem("mailings") && typeof force == "undefined") {
				// console.log("got mailings in localstore")
				commit(MAILINGS_SUCCESS);
				resolve(JSON.parse(localStorage.getItem("mailings")));
			} else {
				mailingSource
					.getMailings()
					.then(resp => {
						localStorage.setItem("mailings", JSON.stringify(resp.data));
						commit(MAILINGS_SUCCESS);
						resolve(resp.data);
					})
					.catch(error => {
						reject(error);
					});
			}
		});
	},
	[MAILINGS_CHANGE]: ({ commit, dispatch }, changedata) => {
		commit(MAILINGS_CHANGE, { changedata: changedata });
	},
	[MAILINGS_SETFILTER]: ({ commit, dispatch }, filterdata) => {
		if (filterdata != {}) {
			localStorage.setItem("mailing_filters", JSON.stringify(filterdata));
			commit(MAILINGS_SETFILTER);
		}
	},
	[MAILINGS_CLEARFILTER]: ({ commit, dispatch }) => {
		localStorage.removeItem("mailing_filters");
		commit(MAILINGS_CLEARFILTER);
	},
	[MAILINGS_REMOVE_ENTRY]: ({ commit, dispatch }, id) => {
		commit(MAILINGS_REMOVE_ENTRY, { MA_ID: id });
	}
};

const mutations = {
	[MAILINGS_REQUEST]: state => {
		state.isBusy = true;
	},
	[MAILINGS_SUCCESS]: state => {
		state.mailings = JSON.parse(localStorage.getItem("mailings"));
		// console.log("get mailings from localstore: ", state.mailings.length)
		state.isBusy = false;
		// console.log("success")
	},
	[MAILINGS_ERROR]: state => {
		state.isBusy = true;
	},
	[MAILINGS_CHANGE]: (state, resp) => {
		let changedata = resp.changedata;
		for (var i in state.mailings) {
			if (state.mailings[i].TI_ID == changedata.TI_ID) {
				state.mailings[i][changedata.field] = changedata.value;
				break; //Stop this loop, we found it!
			}
		}
		localStorage.setItem("mailings", JSON.stringify(state.mailings));
	},
	[MAILINGS_SETFILTER]: state => {
		state.filters = JSON.parse(localStorage.getItem("mailing_filters"));
	},
	[MAILINGS_CLEARFILTER]: state => {
		state.filters = {};
	},
	[MAILINGS_REMOVE_ENTRY]: (state, resp) => {
		let dd = JSON.parse(localStorage.getItem("mailings"));
		let ctrl = dd.splice(
			dd.findIndex(item => item.MA_ID == resp.MA_ID),
			1
		);
		// console.log("found ", ctrl)
		state.mailings = dd;
		localStorage.setItem("mailings", JSON.stringify(state.mailings));
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
