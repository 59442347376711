<template>
	<b-tab title="Export Bookings w/Vouchers" active>
		<b-card-text>
			<b-row>
				<b-col class="mb-3" xs="6" lg="3" offset-lg="1">
					Dept:
					<b-form-select
						class="inner"
						v-model="department"
						:options="departments"
					></b-form-select>
				</b-col>

				<b-col class="mb-3" xs="6" lg="3">
					<span style="color:transparent">Text contains</span>
					<b-form-input class="inner" v-model="textcontains"></b-form-input>
				</b-col>
				<b-col class="mb-3" xs="6" lg="3" v-if="hasProviders">
					Provider
					<b-form-select
						class="inner"
						v-model="carprovider"
						:options="ProviderOptions"
					></b-form-select>
				</b-col>
			</b-row>

			<b-row>
				<b-col sm="6" lg="3" offset-lg="1">
					<span class="fieldname">From:</span>
					<b-input-group class="mb-3 datebox">
						<b-form-datepicker
							v-model="fromdate"
							button-only
							dropright
							:initial-date="startDate"
							locale="nl"
							@context="onContext"
						></b-form-datepicker>
						<span class="fieldval">
							{{ fromdate || "YYYY-mm-dd" }}
						</span>
					</b-input-group>
				</b-col>
				<b-col sm="6" lg="3">
					<span class="fieldname">To:</span>
					<b-input-group class="mb-3 datebox">
						<b-form-datepicker
							v-model="todate"
							button-only
							dropright
							locale="nl"
							aria-controls="example-input"
							@context="onContext"
						></b-form-datepicker>
						<span class="fieldval">
							{{ todate || "YYYY-mm-dd" }}
						</span>
					</b-input-group>
				</b-col>
				<b-col class="mb-3" xs="6" lg="3">
					Language:
					<b-form-select
						class="inner"
						v-model="language"
						:options="languageOptions"
					></b-form-select>
				</b-col>
			</b-row>

			<b-row>
				<b-col offset-lg="1" sm="11" class="switches"
					><div>Export Fields:</div></b-col
				>
			</b-row>

			<b-row>
				<b-col sm="4" lg="2" offset-lg="1" class="switches">
					<b-form-checkbox v-model="fields.BO_STARTD" name="BO_STARTD" switch>
						Start Date
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_NAME" name="BO_NAME" switch>
						Name
					</b-form-checkbox>
					<b-form-checkbox
						v-model="fields.BO_TICKETID"
						name="BO_TICKETID"
						switch
					>
						Ticket ID
					</b-form-checkbox>
					<b-form-checkbox
						v-model="fields.BO_HIREDAYS"
						name="BO_HIREDAYS"
						switch
					>
						Total Days
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_PRICE" name="BO_PRICE" switch>
						Price
					</b-form-checkbox>
				</b-col>
				<b-col sm="4" lg="2" class="switches">
					<b-form-checkbox v-model="fields.BO_STOPD" name="BO_STOPD" switch>
						Stop Date
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.CL_EMAIL" name="CL_EMAIL" switch>
						Email
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_HOTEL" name="BO_HOTEL" switch>
						Hotel
					</b-form-checkbox>
					<b-form-checkbox
						v-model="fields.BO_CARCLASS"
						name="BO_CARCLASS"
						switch
					>
						Car Class
					</b-form-checkbox>
					<b-form-checkbox
						v-model="fields.BO_PRICE_SUM"
						name="BO_PRICE_SUM"
						switch
					>
						Price Sum
					</b-form-checkbox>
				</b-col>
				<b-col sm="4" lg="2" class="switches">
					<b-form-checkbox v-model="fields.BO_STARTT" name="BO_STARTT" switch>
						Start Time
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_PICKUP" name="BO_PICKUP" switch>
						Pickup Location
					</b-form-checkbox>
					<b-form-checkbox
						v-model="fields.BO_FLIGHTNO"
						name="BO_FLIGHTNO"
						switch
					>
						Flight No.
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_CAR" name="BO_CAR" switch>
						Booked Car
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.TI_CD" name="TI_CD" switch>
						Request Date
					</b-form-checkbox>
				</b-col>
				<b-col sm="4" lg="2" class="switches">
					<b-form-checkbox v-model="fields.BO_STOPT" name="BO_STOPT" switch>
						Stop Time
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_RETURN" name="BO_RETURN" switch>
						Return Location
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.BO_STATUS" name="BO_STATUS" switch>
						Status
					</b-form-checkbox>
					<b-form-checkbox v-model="fields.CAR_PLATE" name="CAR_PLATE" switch>
						Numberplate
					</b-form-checkbox>
					<b-form-checkbox
						v-model="fields.CL_VOUCHERS"
						name="CL_VOUCHERS"
						switch
					>
						Vouchers
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-col class="mt-3 text-center" xs="12" lg="6">
					<b-button-group size="lg">
						<b-button variant="success" @click="exportData('bookings', 'XML')"
							>Export XML</b-button
						>
						<b-button variant="info" @click="exportData('bookings', 'HTML')"
							>Export HTML</b-button
						>
						<b-button variant="warning" @click="exportData('bookings', 'CSV')"
							>Export CSV</b-button
						>
					</b-button-group>
				</b-col>
			</b-row>
		</b-card-text>
	</b-tab>
</template>

<script>
import "@/assets/scss/stats.scss";
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import departmentSource from "@/lib/backend/departments";
import statsSource from "@/lib/backend/stats";
import miscFields from "@/lib/misc";
import dayjs from "dayjs";
import { BCard, BTabs, BTab, BInputGroup, BButtonGroup } from "bootstrap-vue";
Vue.component("b-card", BCard);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-button-group", BButtonGroup);
Vue.component("b-input-group", BInputGroup);
const startDate = dayjs().format("YYYY-MM-DD");
// const startDate = dayjs()
// 	.subtract(2, "year")
// 	.format("YYYY-MM-DD");
const filetimestamp = dayjs().format("YYYY-MM-DD");

export default {
	data() {
		return {
			hasProviders: false,
			carprovider: null,
			textcontains: null,
			fromdate: null,
			todate: null,
			department: 1,
			voucher: null,
			language: null,
			hideDatePickerHeader: true,
			startDate: startDate,
			languageOptions: miscFields.languages,
			voucherOptions: miscFields.voucherOptions,
			ProviderOptions: [],
			fields: {
				BO_STARTD: true,
				BO_STOPD: true,
				BO_PICKUP: false,
				BO_NAME: true,
				BO_TICKETID: true,
				BO_HIREDAYS: true,
				BO_PRICE: true,
				BO_PRICE_SUM: false,
				BO_HOTEL: true,
				BO_STARTT: false,
				BO_STOPT: false,
				BO_RETURN: false,
				BO_CAR: false,
				BO_CARCLASS: false,
				TI_CD: false,
				CL_EMAIL: true,
				BO_FLIGHTNO: false,
				CAR_PLATE: false,
				BO_STATUS: false,
				CL_VOUCHERS: false
			}
		};
	},
	computed: {
		...mapState({
			departments: state => state.helpers.departments
		})
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		department: function(newVal, oldVal) {
			if (newVal != null) this.getSelectors(newVal);
		}
	},

	created: function() {
		// load Samos Selectors by default
		this.getSelectors(1);
	},

	methods: {
		exportData(stats, type) {
			let sendfields = [];
			for (let i in this.fields) {
				if (this.fields[i] == true) {
					sendfields.push(i);
				}
			}
			let params = {
				stats: stats,
				language: this.language,
				department: this.department,
				fromdate: this.fromdate,
				todate: this.todate,
				textcontains: this.textcontains,
				carprovider: this.carprovider,
				fields: sendfields,
				type: type
			};
			let dlname = stats + "_" + filetimestamp + "." + type.toLowerCase();
			statsSource
				.exportData(params)
				.then(response => {
					let ctype = response.headers["content-type"].split(";")[0];
					let blob = new Blob([response.data], { type: ctype });
					let link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					if (type !== "HTML") {
						link.download = dlname;
					} else {
						link.target = "_blank";
					}
					link.click();
				})
				// eslint-disable-next-line no-unused-vars
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: "Could not download File " + dlname,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		getSelectors(dept) {
			this.hasProviders = false;
			departmentSource.getSelectors(dept, ["DEP_CARPROVIDERS"]).then(result => {
				let sels = JSON.parse(JSON.stringify(result.data));
				if (
					typeof sels.DEP_CARPROVIDERS == "object" &&
					sels.DEP_CARPROVIDERS.length > 1
				) {
					this.hasProviders = true;
					this.ProviderOptions = sels.DEP_CARPROVIDERS;
					this.ProviderOptions.unshift("-");
				}
			});
		},

		onContext(ctx) {
			// The date formatted in the locale, or the `label-no-date-selected` string
			this.formatted = ctx.selectedFormatted;
			// The following will be an empty string until a valid date is entered
			this.selected = ctx.selectedYMD;
		}
	}
};
</script>
