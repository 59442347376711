var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content", attrs: { id: "cars" } },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Cars",
          description: "Cars"
        }
      }),
      _c("h1", [_vm._v("Cars")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12" } },
            [
              _vm._v(" Department: "),
              _vm._l(_vm.cardepartments, function(item, index) {
                return _c(
                  "b-btn",
                  {
                    key: index,
                    attrs: {
                      squared: "",
                      variant: "warning",
                      pressed: _vm.depid === item.value,
                      to: "/cars/" + item.value
                    }
                  },
                  [_vm._v(_vm._s(item.text))]
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "classlist", attrs: { xs: "12" } },
            _vm._l(_vm.carclasses, function(item, classname) {
              return _c(
                "b-btn",
                {
                  key: classname,
                  attrs: { squared: "", pressed: _vm.carclass === item },
                  on: {
                    click: function($event) {
                      return _vm.toggleClass(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "box" }, [
        _vm.carlist !== null
          ? _c(
              "div",
              { staticClass: "carlist container-fluid" },
              _vm._l(_vm.carlist, function(item, index) {
                return _c(
                  "b-row",
                  { key: index, attrs: { "align-v": "stretch" } },
                  [
                    _c(
                      "b-col",
                      {
                        staticStyle: { padding: "4px 0" },
                        attrs: { xs: "4", lg: "5" }
                      },
                      [
                        _c("img", {
                          staticClass: "carthumb",
                          attrs: { src: _vm.pic_baseurl + item.CAR_IMAGE }
                        })
                      ]
                    ),
                    _c(
                      "b-col",
                      { attrs: { xs: "8", lg: "7", "align-self": "center" } },
                      [
                        _c(
                          "b-row",
                          { attrs: { "align-v": "center" } },
                          [
                            _c("b-col", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "padding-right": "15px"
                                  }
                                },
                                [_vm._v(_vm._s(item.CAR_PLATE))]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(item.CAR_MAKE) +
                                  " " +
                                  _vm._s(item.CAR_MODEL)
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setCar(item)
                                      }
                                    }
                                  },
                                  [_vm._v("Edit")]
                                ),
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.hideCar(item.CAR_ID)
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                ),
                                _c(
                                  "b-btn",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleLog(item.CAR_ID)
                                      }
                                    }
                                  },
                                  [_vm._v("Log")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "carsingle container-fluid" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { xs: "12" } },
                  [
                    _vm.dep_class
                      ? _c(
                          "div",
                          { staticClass: "text-center mb-3" },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { type: "button", variant: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.showAddCar()
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { name: "car" } }),
                                _vm._v(" "),
                                _c("v-icon", { attrs: { name: "plus" } }),
                                _vm._v(" Add one ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.currentCarId != null
                      ? _c("CarForm", {
                          attrs: {
                            carid: _vm.currentCarId,
                            car: _vm.currentCar
                          },
                          on: {
                            changecar: _vm.editCar,
                            changecarpic: _vm.editCarPic
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }