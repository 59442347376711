// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getUsersForSelect() {
		return API.get("/v1/admin/users/select");
	},

	getUsers() {
		return API.get("/v1/admin/users");
	},
	editUser(data) {
		return API.post("/v1/admin/users/edit", data);
	},
	addUser(data) {
		return API.post("/v1/admin/users/add", data);
	}
};
