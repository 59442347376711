/* eslint-disable no-unused-vars */
import { CARS_REQUEST, CARS_ERROR, CARS_SUCCESS } from "../actions/planner";
import carSource from "@/lib/backend/cars";

const state = {
	isBusy: true,
	cars: JSON.parse(localStorage.getItem("cars")) || [],
	bookings: JSON.parse(localStorage.getItem("bookings")) || {}
};

const getters = {
	// showold: state => state.showold,
};

const actions = {
	[CARS_REQUEST]: ({ commit, dispatch }, force) => {
		return new Promise((resolve, reject) => {
			commit(CARS_REQUEST);
			if (localStorage.getItem("cars") && typeof force == "undefined") {
				commit(CARS_SUCCESS);
				resolve(JSON.parse(localStorage.getItem("cars")));
			} else {
				carSource
					.getCarList(1)
					.then(resp => {
						localStorage.setItem("cars", JSON.stringify(resp.data));
						commit(CARS_SUCCESS);
						resolve(resp.data);
					})
					.catch(error => {
						reject(error);
					});
			}
		});
	}
};

const mutations = {
	[CARS_REQUEST]: state => {
		state.isBusy = true;
	},
	[CARS_SUCCESS]: state => {
		state.cars = JSON.parse(localStorage.getItem("cars"));
		state.isBusy = false;
	},
	[CARS_ERROR]: state => {
		state.isBusy = true;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
