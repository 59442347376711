<template>
	<div class="container-fluid depform">
		<ValidationObserver v-slot="{ handleSubmit }">
			<b-form @submit.prevent="handleSubmit(transmitForm)">
				<b-row class="head filterbar">
					<b-col
						><h3>
							Department <i>{{ dep_name }}</i>
						</h3></b-col
					>
				</b-row>
				<b-row>
					<b-col xs="12" lg="6">
						<span class="fieldlabel">Incoming Email:</span>
						<validation-provider rules="required" v-slot="{ errors }">
							<b-form-input
								size="sm"
								class="inner"
								style="width:70%"
								id="incoming_email"
								v-model="settings.DEP_EMAIL_INCOMING"
								placeholder=""
							></b-form-input>
							<span class="formerror">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
					<b-col xs="12" lg="6">
						<span class="fieldlabel">Outgoing Email:</span>
						<validation-provider rules="required" v-slot="{ errors }">
							<b-form-input
								size="sm"
								class="inner"
								style="width:70%"
								id="outgoing_email"
								v-model="settings.DEP_EMAIL_OUTGOING"
								placeholder=""
							></b-form-input>
							<span class="formerror">{{ errors[0] }}</span>
						</validation-provider>
					</b-col>
				</b-row>
				<b-row>
					<b-col xs="12" lg="6">
						<span class="fieldlabel">Pickup/Return Locations</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_PICKUPS"
							rows="5"
						></b-form-textarea>
					</b-col>
					<b-col xs="12" lg="3">
						<span class="fieldlabel">Childseats</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_CHILDSEATS"
							rows="5"
						></b-form-textarea>
					</b-col>
					<b-col xs="12" lg="3">
						<span class="fieldlabel">Providers</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_CARPROVIDERS"
							rows="5"
						></b-form-textarea>
					</b-col>
				</b-row>
				<b-row>
					<b-col xs="12" lg="6">
						<span class="fieldlabel">PDF Recipients</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_PDF_RECIPIENTS"
							rows="5"
						></b-form-textarea>
					</b-col>
					<b-col xs="12" lg="3">
						<span class="fieldlabel">Insurance</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_INSURANCE"
							rows="5"
						></b-form-textarea>
					</b-col>
					<b-col xs="12" lg="3">
						<span class="fieldlabel">Insurance Risk</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_INSURANCE_RISK"
							rows="5"
						></b-form-textarea>
					</b-col>
				</b-row>
				<b-row>
					<b-col xs="12">
						<span class="fieldlabel">Cars</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_CARS"
							rows="5"
						></b-form-textarea>
					</b-col>
				</b-row>
				<b-row>
					<b-col xs="12">
						<span class="fieldlabel">Default Extra 1:</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_EXTRA1"
							rows="5"
						></b-form-textarea>
					</b-col>
				</b-row>
				<b-row>
					<b-col xs="12">
						<span class="fieldlabel">Default Extra 2:</span>
						<b-form-textarea
							size="sm"
							v-model="settings.DEP_EXTRA2"
							rows="5"
						></b-form-textarea>
					</b-col>
				</b-row>
				<b-row>
					<b-col xs="12" lg="6">
						<span class="fieldlabel">Autoresponder Subject</span>
						<div
							v-if="settings.DEP_AUTORESPONDER_SUBJECT"
							v-for="lang in languages"
							v-bind:key="lang"
						>
							<p />
							<span class="fieldlabel"
								><img :src="require('@/assets/images/flags/' + lang + '.png')"
							/></span>
							<b-form-input
								size="sm"
								class="inner"
								style="width:100%"
								id="incoming_email"
								v-model="settings.DEP_AUTORESPONDER_SUBJECT[lang]"
								placeholder=""
							></b-form-input>
						</div>
						<hr />
						<div
							v-if="settings.FOOTERS"
							v-for="(lang, index) in languages"
							v-bind:key="index"
						>
							<span class="fieldlabel">
								Footer
								<img :src="require('@/assets/images/flags/' + lang + '.png')" />
							</span>
							<b-form-textarea
								size="sm"
								v-model="settings.FOOTERS[lang]"
								rows="5"
							></b-form-textarea>
							<p />
						</div>
					</b-col>
					<b-col xs="12" lg="6">
						<span class="fieldlabel">Reminder Email</span>
						<div
							v-if="settings.DEP_REMINDER_SUBJECT"
							v-for="(lang, index) in languages"
							v-bind:key="index"
						>
							<span class="fieldlabel">
								Subject
								<img :src="require('@/assets/images/flags/' + lang + '.png')" />
							</span>
							<b-form-input
								size="sm"
								class="inner"
								style="width:80%"
								id="incoming_email"
								v-model="settings.DEP_REMINDER_SUBJECT[lang]"
								placeholder=""
							></b-form-input>
							<br />
							<span class="fieldlabel">
								Text
								<img :src="require('@/assets/images/flags/' + lang + '.png')" />
							</span>
							<b-form-textarea
								size="sm"
								v-model="settings.DEP_REMINDER_TEXT[lang]"
								rows="5"
							></b-form-textarea>
							<hr />
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-center mb-5">
						<b-btn type="submit" size="lg" variant="primary">Edit</b-btn>
					</b-col>
				</b-row>
			</b-form>
		</ValidationObserver>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import { ValidationProvider, ValidationObserver } from "vee-validate";
import settingsSource from "@/lib/backend/settings";
import misc from "@/lib/misc";

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},
	props: {
		department: String
	},
	data() {
		return {
			languages: misc.languages,
			isBusy: false,
			dep_name: null,
			settings: {
				FOOTERS: { nl: "" },
				DEP_AUTORESPONDER_SUBJECT: { nl: "" },
				DEP_REMINDER_SUBJECT: { nl: "" },
				DEP_REMINDER_TEXT: { nl: "" }
			}
		};
	},
	watch: {
		department: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.getGeneralSettings(newVal);
			},
			deep: true
		}
	},

	computed: {
		...mapState({
			departments: state => state.helpers.departments
		})
	},

	mounted: function() {},

	methods: {
		getGeneralSettings(dept) {
			this.isBusy = true;
			const dpentry = this.departments.find(x => x.value === dept);
			this.dep_name = dpentry.text;
			settingsSource
				.getGeneralSettings(dept)
				.then(res => {
					this.settings = res.data;
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.e(error, "Settings Error");
				});
		},

		transmitForm() {
			console.log("trnsmitting. beep");
			settingsSource
				.updateGeneralSettings(this.settings)
				// eslint-disable-next-line no-unused-vars
				.then(res => {
					this.$toastr.s("", "Settings updated");
				})
				.catch(error => {
					this.$toastr.e(error, "Settings update Error");
				});
		}
	}
};
</script>
