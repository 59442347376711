var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tab",
    { attrs: { title: "Export Client Email Addresses" } },
    [
      _c(
        "b-card-text",
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { sm: "6", lg: "3" } },
                [
                  _c("span", { staticClass: "fieldname" }, [_vm._v("From:")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-3 datebox" },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          "button-only": "",
                          dropright: "",
                          "initial-date": _vm.startDate,
                          locale: "nl",
                          "aria-controls": "example-input"
                        },
                        on: { context: _vm.onContext },
                        model: {
                          value: _vm.fromdate,
                          callback: function($$v) {
                            _vm.fromdate = $$v
                          },
                          expression: "fromdate"
                        }
                      }),
                      _c("span", { staticClass: "fieldval" }, [
                        _vm._v(" " + _vm._s(_vm.fromdate || "YYYY-mm-dd") + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6", lg: "3" } },
                [
                  _c("span", { staticClass: "fieldname" }, [_vm._v("To:")]),
                  _c(
                    "b-input-group",
                    { staticClass: "mb-3 datebox" },
                    [
                      _c("b-form-datepicker", {
                        attrs: {
                          "button-only": "",
                          dropright: "",
                          locale: "en-US",
                          "aria-controls": "example-input"
                        },
                        on: { context: _vm.onContext },
                        model: {
                          value: _vm.todate,
                          callback: function($$v) {
                            _vm.todate = $$v
                          },
                          expression: "todate"
                        }
                      }),
                      _c("span", { staticClass: "fieldval" }, [
                        _vm._v(" " + _vm._s(_vm.todate || "YYYY-mm-dd") + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                [
                  _vm._v(" Dept: "),
                  _c("b-form-select", {
                    staticClass: "inner",
                    attrs: { options: _vm.departments },
                    model: {
                      value: _vm.department,
                      callback: function($$v) {
                        _vm.department = $$v
                      },
                      expression: "department"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                [
                  _vm._v(" Voucher: "),
                  _c("b-form-select", {
                    staticClass: "inner",
                    attrs: { options: _vm.voucherOptions },
                    model: {
                      value: _vm.voucher,
                      callback: function($$v) {
                        _vm.voucher = $$v
                      },
                      expression: "voucher"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "12", lg: "6" } },
                [
                  _vm._v(" Language: "),
                  _c("b-form-select", {
                    staticClass: "inner",
                    attrs: { options: _vm.languageOptions },
                    model: {
                      value: _vm.language,
                      callback: function($$v) {
                        _vm.language = $$v
                      },
                      expression: "language"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "mb-3 text-center",
                  attrs: { xs: "12", lg: "6" }
                },
                [
                  _c(
                    "b-button-group",
                    { attrs: { size: "lg" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: {
                            click: function($event) {
                              return _vm.exportData("clients", "XML")
                            }
                          }
                        },
                        [_vm._v("Export XML")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "info" },
                          on: {
                            click: function($event) {
                              return _vm.exportData("clients", "HTML")
                            }
                          }
                        },
                        [_vm._v("Export HTML")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.exportData("clients", "CSV")
                            }
                          }
                        },
                        [_vm._v("Export CSV")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }