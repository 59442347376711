var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "plannerdetail",
      attrs: { id: "plannerdetail", size: "xl" },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function() {
            return [
              _c("div", { staticClass: "headline" }, [
                _vm._v(" Booking Details ")
              ])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "container-fluid mt-3" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "th", attrs: { xs: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.showBooking(
                                    _vm.booking.BO_ID,
                                    _vm.booking.BO_TICKETID
                                  )
                                }
                              }
                            },
                            [_vm._v("Edit Booking")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "th text-right", attrs: { xs: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to:
                                  "/tickets/details/" + _vm.booking.BO_TICKETID,
                                variant: "primary"
                              }
                            },
                            [_vm._v("Edit Ticket")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isBusy
        ? _c(
            "div",
            { staticClass: "busyblock" },
            [
              _c("b-spinner", {
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "secondary" }
              }),
              _c("p"),
              _c("span", { staticClass: "danger" }, [_vm._v("Waiting....")])
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "container-fluid topbar mt-3" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Client")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [
                      _vm._v(
                        _vm._s(_vm.booking.CL_FIRSTNAME) +
                          " " +
                          _vm._s(_vm.booking.CL_LASTNAME)
                      )
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Ticket #")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_TICKETID))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Phone")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.CL_PHONE))]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Flight No.")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_FLIGHTNO))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Hire Days")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_HIREDAYS))]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Price")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_PRICE))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Hotel")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_HOTEL))]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Location")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_LOCATION))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("ChildSeat")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_CHILDSEAT_TEXT))]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Sum")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [_vm._v(_vm._s(_vm.booking.BO_PRICE_SUM))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Car")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "10" } },
                    [_vm._v(_vm._s(_vm.booking.BO_CAR))]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "th", attrs: { xs: "4", lg: "2" } },
                    [_vm._v("Remarks")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "cont", attrs: { xs: "8", lg: "4" } },
                    [
                      _vm._v(
                        _vm._s(_vm.booking.BO_BO_FREETEXT1) +
                          " " +
                          _vm._s(_vm.booking.BO_BO_FREETEXT2)
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }