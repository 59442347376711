// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getBookings(depid, start, stop) {
		return API.get(
			"/v1/admin/planner/bookings/" + depid + "/" + start + "/" + stop
		);
	},
	setCar(BO_ID, CAR_ID) {
		return API.post(
			"/v1/admin/planner/bookings/setcar/" + BO_ID + "/" + CAR_ID
		);
	}
};
