<template>
	<div class="container-fluid depform">
		<b-row class="head filterbar">
			<b-col
				><h3>
					Prices for <i>{{ dep_name }}</i>
				</h3></b-col
			>
		</b-row>
		<b-row>
			<b-col xs="4" lg="4">
				<b-row
					v-for="(item1, carclass) in carclasses"
					v-bind:key="'master-' + carclass"
				>
					<b-col
						xs="12"
						lg="4"
						v-for="(item2, season) in seasons"
						v-bind:key="'child-' + season"
					>
						<b-btn style="width:90%;" @click="togglePriceTable(item1, season)"
							>{{ item1 }} :: {{ item2 }}</b-btn
						>
					</b-col>
				</b-row>
			</b-col>
			<b-col xs="8" lg="6">
				<div class="flowin" v-if="isActive()">
					<b>Class {{ activeClass }}, {{ seasons[activeSeason] }}</b>
					<PriceSetter
						:department="department"
						:carclass="activeClass"
						:season="activeSeason"
					/>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import PriceSetter from "@/components/PriceSetter";

import misc from "@/lib/misc";

export default {
	components: {
		PriceSetter
	},
	props: {
		department: String
	},
	data() {
		return {
			isBusy: false,
			prices: {},
			carclasses: misc.classes,
			seasons: misc.seasons,
			activeClass: null,
			activeSeason: null
		};
	},
	computed: {
		...mapState({
			departments: state => state.helpers.departments
		})
	},
	watch: {
		department: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.getPriceSettings(newVal);
			},
			deep: true
		}
	},
	mounted: function() {
		console.log("prices mounted");
	},
	methods: {
		getPriceSettings(dept) {
			const dpentry = this.departments.find(x => x.value === dept);
			this.dep_name = dpentry.text;
		},
		showError(errormessage) {
			this.$toastr.Add({
				title: "Error", // Toast Title
				msg: errormessage,
				position: "toast-top-center",
				type: "error"
			});
		},
		togglePriceTable(carclass, season) {
			this.activeClass = carclass;
			this.activeSeason = season;
			console.log(this.activeClass + " " + this.activeSeason);
		},
		isActive() {
			return this.activeClass != null && this.activeSeason !== null;
		}
	}
};
</script>
