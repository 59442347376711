<template>
	<div class="container-fluid filterbar">
		<b-row class="head">
			<b-col><h2>Filter Results</h2></b-col>
		</b-row>
		<b-row>
			<b-col xs="12" lg="4" xl="6">
				Contains:
				<b-form-input
					class="inner"
					size="sm"
					v-model="filters.contains"
				></b-form-input>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Has Tickets:
				<b-form-select
					class="inner"
					size="sm"
					v-model="filters.tickets"
					:options="voucherOptions"
				></b-form-select>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Has Voucher:
				<b-form-select
					class="inner"
					size="sm"
					v-model="filters.vouchers"
					:options="voucherOptions"
				></b-form-select>
			</b-col>
			<b-col xs="12" lg="2" xl="1">
				<b-btn size="sm" variant="danger" @click="removeFilters"
					>Reset Filters</b-btn
				>
			</b-col>
			<b-col xs="12" lg="2" xl="1">
				<b-btn size="sm" variant="success" @click="setNewSearch">
					<v-icon name="sync-alt" />
					Reload
				</b-btn>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import tableFields from "@/lib/misc";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "vue-awesome/icons/sync-alt";

const moreFilterText = "More Filters";
const lessFilterText = "Less Filters";

export default {
	props: {
		filters: Object,
		removeFilters: Function
	},
	data() {
		return {
			showExtended: false,
			voucherOptions: tableFields.voucherOptions,
			statusOptions: tableFields.statusOptions,
			toggleFilterText: moreFilterText
		};
	},
	computed: {
		...mapState({
			users: state => state.auth.userdata,
			departments: state => state.helpers.departments
		})
	},
	mounted: function() {
		this.$store.dispatch("HELPERS_DEPARTMENTS");
	},
	methods: {
		toggleFilters() {
			this.showExtended = !this.showExtended;
			this.toggleFilterText = !this.showExtended
				? moreFilterText
				: lessFilterText;
		},
		setNewSearch() {
			this.$store
				.dispatch("CLIENTS_REQUEST", true)
				.then(result => {
					console.log("new search: " + result);
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
			return true;
		}
	}
};
</script>
