// eslint-disable-next-line
import { BIconCardList } from "bootstrap-vue";
import API from "./axios";
export default {
	// eslint-disable-next-line

	get(months) {
		return API.get("/v1/admin/carlog/" + months);
	},
	edit(data) {
		return API.post("/v1/admin/carlog/edit", data);
	},
	add(data) {
		return API.post("/v1/admin/carlog/add", data);
	},
	delete(id) {
		return API.post("/v1/admin/carlog/delete/" + id);
	},
	carlist() {
		return API.get("/v1/admin/carlog/carlist");
	}
};
