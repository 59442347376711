import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/mailings.scss";
import MailingSource from "@/lib/backend/mailings";
import MailingSingle from "@/views/MailingSingle";
import tableFields from "@/lib/misc";
import "vue-awesome/icons/trash-alt";
import { BPaginationNav, BSpinner } from "bootstrap-vue";
Vue.component("b-pagination-nav", BPaginationNav);
Vue.component("b-spinner", BSpinner);

export default {
	components: {
		MailingSingle
	},
	data() {
		return {
			perPage: 20,
			currentPage: 1,
			currentMailingId: 0,
			numrows: 0,
			fields: tableFields.MailingListFields,
			mailingfilters: null
		};
	},
	computed: {
		...mapState({
			users: state => state.helpers.users,
			mailings: state => state.mailings.mailings,
			isBusy: state => state.mailings.isBusy
		}),
		totalPages: function() {
			// `this` points to the vm instance
			return this.numrows == 0 ? 10 : Math.ceil(this.numrows / this.perPage);
		},
		filteredData: function() {
			if (this.mailingfilters == null) {
				this.numrows = this.mailings.length;
				console.log(this.mailings);
				return this.mailings;
			} // no filtering
		}
	},

	created: function() {
		// IF FORCE THEN USE THIS
		// this.$store.dispatch('TICKETS_REQUEST', {force: true})
		this.$store.dispatch("MAILINGS_REQUEST");
	},

	mounted: function() {
		if (this.$route.params.page) {
			this.currentPage = this.$route.params.page;
		}
	},
	methods: {
		linkGen(pageNum) {
			return { path: `/Mailings/page/${pageNum}` };
		},

		showNew() {
			this.currentMailingId = 0;
			this.$bvModal.show("mailingsingle");
		},

		showSingle(id) {
			console.log("clicked id ", id);
			this.currentMailingId = id;
			this.$bvModal.show("mailingsingle");
		},

		preDelete(Mailing) {
			let data = { MA_ID: Mailing.MA_ID };
			let text = "Are you sure?";
			if (confirm(text)) {
				MailingSource.deleteMailing(data).then(res => {
					this.$toastr.s(res.data.Message, "Mailing deleted");
					this.$store.dispatch("MAILINGS_REMOVE_ENTRY", Mailing.MA_ID);
					console.log("Na dann wech!");
				});
			} else {
				console.log("Na dann eben nicht");
			}
		},

		setNewSearch() {
			this.$store.dispatch("MAILINGS_REQUEST", true);

			return true;
		}
	}
};
