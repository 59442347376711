var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "loginpage" } },
    [
      _c("vue-headful", {
        attrs: { title: "Log in", description: "Login Page" }
      }),
      _c(
        "form",
        {
          staticClass: "login",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login($event)
            }
          }
        },
        [
          _c("div", { staticClass: "loginbox" }, [
            _c(
              "div",
              { staticClass: "container", attrs: { id: "loginform" } },
              [
                _c("div", { attrs: { id: "logoblock" } }, [
                  _c("div", { class: _vm.target, attrs: { id: "logoemblem" } })
                ]),
                _vm.error
                  ? _c("div", { staticClass: "msg" }, [
                      _vm._v(_vm._s(_vm.error))
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row field" }, [
                  _c("div", { staticClass: "col col-4 thekey" }, [
                    _vm._v("User")
                  ]),
                  _c("div", { staticClass: "col col-8 theval" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.username,
                          expression: "username"
                        }
                      ],
                      staticClass: "form-control uitests-email-field",
                      attrs: {
                        required: "",
                        type: "text",
                        placeholder: "User"
                      },
                      domProps: { value: _vm.username },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.username = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "row field" }, [
                  _c("div", { staticClass: "col col-4 thekey" }, [
                    _vm._v("Passwort")
                  ]),
                  _c("div", { staticClass: "col col-8 theval" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "form-control uitests-password-field",
                      attrs: {
                        required: "",
                        type: "password",
                        placeholder: "Passwort"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._m(0)
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "button",
        {
          staticClass:
            "thebutton btn btn-primary text-light btn-lg btn-block uitest-login-action",
          attrs: { type: "submit" }
        },
        [_vm._v(" Einloggen ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }