import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import {
	LayoutPlugin,
	IconsPlugin,
	ButtonPlugin,
	ModalPlugin,
	CardPlugin,
	TablePlugin,
	FormPlugin,
	FormSelectPlugin,
	FormInputPlugin,
	FormDatepickerPlugin
} from "bootstrap-vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueHeadful from "vue-headful";
import Toastr from "vue-toastr";
import Icon from "vue-awesome/components/Icon";

Vue.component("vue-headful", vueHeadful);
Vue.component("v-icon", Icon);

Vue.prototype.$target = process.env.VUE_APP_TARGET;

Vue.config.productionTip = false;
[
	LayoutPlugin,
	IconsPlugin,
	ButtonPlugin,
	ModalPlugin,
	CardPlugin,
	TablePlugin,
	FormPlugin,
	FormSelectPlugin,
	FormInputPlugin,
	FormDatepickerPlugin
].forEach(comp => {
	Vue.use(comp);
});
Vue.use(Toastr);
Vue.config.devtools = true;
new Vue({
	router,
	store,
	render: h => h(App),
	mounted() {
		// You are able to access plugin from everywhere via this.$toastr
		this.$toastr.defaultPosition = "toast-top-center";
	}
}).$mount("#app");
