var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content", attrs: { id: "ticket" } },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Ticket",
          description: "Ticket System"
        }
      }),
      _c("h1", [
        _vm._v(
          "Ticket: " +
            _vm._s(_vm.ticket.DEPARTMENT) +
            " #" +
            _vm._s(_vm.ticket.TI_ID)
        )
      ]),
      _c(
        "b-container",
        { staticClass: "topbar", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { attrs: { "align-h": "between" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "buttonrow" },
                            [
                              _c("v-icon", {
                                staticClass: "icon-warning withhover",
                                attrs: {
                                  name: "backspace",
                                  scale: "3",
                                  id: "icon-back"
                                },
                                on: { click: _vm.zurueck }
                              }),
                              _c(
                                "b-btn",
                                {
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showBookingSingle(
                                        _vm.ticket.TI_BOOKINGID,
                                        _vm.ticket.PDF_URL,
                                        _vm.ticket.TI_DEPT
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "book-open", scale: "1" }
                                  }),
                                  _vm._v("Booking ")
                                ],
                                1
                              ),
                              _vm.ticket.TI_BOOKINGID > 0 &&
                              _vm.ticket.TI_DEPT == 1
                                ? _c(
                                    "b-btn",
                                    {
                                      attrs: { size: "sm" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showContractForm(
                                            _vm.ticket.TI_BOOKINGID
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: {
                                          name: "file-signature",
                                          scale: "1"
                                        }
                                      }),
                                      _vm._v("Contract ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-btn",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.clientsingle",
                                      modifiers: { clientsingle: true }
                                    }
                                  ],
                                  attrs: { size: "sm" }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "user", scale: "1" }
                                  }),
                                  _vm._v("Client ")
                                ],
                                1
                              ),
                              _vm.ticket.PDF_URL
                                ? _c(
                                    "b-btn",
                                    {
                                      attrs: { size: "sm" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.downloadVoucher()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: { name: "file-pdf", scale: "1" }
                                      }),
                                      _vm._v("Voucher ")
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-btn",
                                {
                                  attrs: { variant: "success", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.makeClone()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "clone", scale: "1" }
                                  }),
                                  _vm._v("Clone ")
                                ],
                                1
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    size: "sm",
                                    value: "1",
                                    "unchecked-value": "0",
                                    name: "check-button",
                                    button: ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.toggleSendReminder($event)
                                    }
                                  },
                                  model: {
                                    value: _vm.ticket.TI_SENDREMINDER,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ticket,
                                        "TI_SENDREMINDER",
                                        $$v
                                      )
                                    },
                                    expression: "ticket.TI_SENDREMINDER"
                                  }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "bell", scale: "1" }
                                  }),
                                  _vm._v(" 7 day Reminder "),
                                  _vm.ticket.TI_SENDREMINDER == 1
                                    ? _c(
                                        "span",
                                        { staticClass: "sendreminder on" },
                                        [_vm._v("On")]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "sendreminder off" },
                                        [_vm._v("Off")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticStyle: { "margin-top": "7px" } },
                        [
                          _c("b-col", { staticClass: "fett" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.ticket.CL_FIRSTNAME) +
                                " " +
                                _vm._s(_vm.ticket.CL_LASTNAME) +
                                " "
                            )
                          ]),
                          _c("b-col", { staticClass: "fett" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.openSubjectEditor }
                              },
                              [_vm._v(_vm._s(_vm.ticket.TI_SUBJECT))]
                            )
                          ]),
                          _vm.ticket.vouchers > 0
                            ? _c("b-col", { staticClass: "fett" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "pseudolink",
                                    on: {
                                      click: function($event) {
                                        return _vm.getTicketsByClient(
                                          _vm.ticket.TI_CLIENTID
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.ticket.vouchers) +
                                        " Vouchers "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.ticket.CL_EXTRA
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "ticket-extra",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("v-icon", {
                                    staticStyle: { "vertical-align": "middle" },
                                    attrs: {
                                      name: "exclamation-circle",
                                      scale: "1.5"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "padding-left": "1rem",
                                        "vertical-align": "middle"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.ticket.CL_EXTRA))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.ticket.CL_IMAGE
                ? _c("b-col", { attrs: { cols: "1" } }, [
                    _c("img", {
                      staticClass: "clientimground",
                      attrs: { src: _vm.ticket.CL_IMAGE },
                      on: { click: _vm.openPic }
                    })
                  ])
                : _vm._e(),
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-status",
                        label: "Status:",
                        "label-for": "status"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "status",
                          size: "sm",
                          options: _vm.statusOptions
                        },
                        on: { change: _vm.updateStatus },
                        model: {
                          value: _vm.ticket.TI_STATUS,
                          callback: function($$v) {
                            _vm.$set(_vm.ticket, "TI_STATUS", $$v)
                          },
                          expression: "ticket.TI_STATUS"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { cols: "1" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-language",
                        label: "Language:",
                        "label-for": "language"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "language",
                          size: "sm",
                          options: _vm.languages
                        },
                        on: { change: _vm.updateLanguage },
                        model: {
                          value: _vm.ticket.TI_LANG,
                          callback: function($$v) {
                            _vm.$set(_vm.ticket, "TI_LANG", $$v)
                          },
                          expression: "ticket.TI_LANG"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { cols: "2" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-assigned",
                        label: "User:",
                        "label-for": "assigned"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "assigned",
                          size: "sm",
                          options: _vm.users
                        },
                        on: { change: _vm.updateAssignedUser },
                        model: {
                          value: _vm.ticket.TI_ASSIGNEDTO,
                          callback: function($$v) {
                            _vm.$set(_vm.ticket, "TI_ASSIGNEDTO", $$v)
                          },
                          expression: "ticket.TI_ASSIGNEDTO"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Messages", {
        attrs: {
          TI_ID: _vm.ticketID,
          messages: _vm.messages,
          templates: _vm.ticket.MAIL_TEMPLATES,
          PDF_URL: _vm.ticket.PDF_URL,
          updateTicket: _vm.getTicketData
        }
      }),
      _vm.ticket.CC_PDF
        ? _c("CCPDF", {
            attrs: {
              Recipients: _vm.ticket.CC_PDF,
              TI_ID: _vm.ticketID,
              TI_DEPT: _vm.ticket.TI_DEPT,
              TI_CLIENTID: _vm.ticket.TI_CLIENTID,
              CC_VOUCHER: _vm.ticket.CC_VOUCHER
            }
          })
        : _vm._e(),
      _c("BookingSingle", {
        attrs: {
          BO_ID: _vm.currentBookingId,
          TICKET: _vm.currentTicket,
          UpdateBoId: _vm.setBoId
        }
      }),
      _c("ContractForm", { attrs: { BO_ID: _vm.contractBookingId } }),
      _c("hr"),
      _c("ClientSingle", {
        attrs: { clid: parseInt(this.ticket.TI_CLIENTID) }
      }),
      _c(
        "b-modal",
        {
          ref: "picdetail",
          attrs: { id: "picdetail", "hide-footer": "", "hide-header": "" }
        },
        [_c("img", { attrs: { src: _vm.ticket.CL_IMAGE } })]
      ),
      _c(
        "b-modal",
        {
          ref: "subject",
          attrs: { id: "subject", "hide-footer": "", "hide-header": "" }
        },
        [
          _c("b-form-input", {
            attrs: {
              size: "sm",
              id: "input-horizontal",
              placeholder: "Enter a subject"
            },
            model: {
              value: _vm.ticket.TI_SUBJECT,
              callback: function($$v) {
                _vm.$set(_vm.ticket, "TI_SUBJECT", $$v)
              },
              expression: "ticket.TI_SUBJECT"
            }
          }),
          _c(
            "b-button",
            {
              attrs: { size: "sm", type: "button", variant: "success" },
              on: {
                click: function($event) {
                  return _vm.updateSubject()
                }
              }
            },
            [_vm._v("Change")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }