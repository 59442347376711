<template>
	<div
		class="container-fluid content mt-3"
		style="padding-top:0;border:1px solid #ccc"
	>
		<b-form @submit.prevent="sendCCPDF">
			<b-row class="single-head" style="background-color: #ccc">
				<b-col class="headline">
					Send Voucher
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="CC_TEXT_G" label="Your text" label-for="CC_TEXT">
						<b-form-textarea
							size="sm"
							v-model="CC_TEXT"
							rows="2"
						></b-form-textarea>
					</b-form-group>
					<p />
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group id="CC_PDF_G" label="Recipient" label-for="CC_PDF">
						<b-form-select
							class="inner"
							v-model="CC_PDF"
							:options="Recipients"
						></b-form-select>
					</b-form-group>
				</b-col>
				<b-col class="text-center mt-2">
					<span style="color:transparent">Placeholder</span><br />
					<b-button type="submit" variant="primary"> Send</b-button>
				</b-col>
				<b-col class="text-center mt-2">
					<span style="color:transparent">Placeholder</span><br />
					<b-button :href="CC_VOUCHER" target="blank" variant="secondary">
						Download</b-button
					>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>

<script>
import bookingSource from "@/lib/backend/bookings";
export default {
	props: {
		Recipients: Array,
		TI_ID: [String, Number],
		TI_DEPT: [String, Number],
		CC_VOUCHER: String,
		TI_CLIENTID: String
	},

	components: {},

	watch: {},

	data() {
		return {
			CC_TEXT:
				"Please confirm this booking by using your reply button in your email program",
			CC_PDF: ""
		};
	},

	created: function() {},

	methods: {
		sendCCPDF() {
			let err = "";
			if (!this.CC_TEXT || this.CC_TEXT == "") {
				err = "You must enter some text!";
			}
			if (this.CC_PDF == "") {
				err = "You must choose a recipient!";
			}
			if (err != "") {
				this.$toastr.e(err, "Error");
			}
			bookingSource
				.setCCPDF(
					this.TI_ID,
					this.CC_TEXT,
					this.CC_PDF,
					this.TI_DEPT,
					this.TI_CLIENTID
				)
				.then(() => {
					this.$toastr.s({
						title: "PDF sent", // Toast Title
						msg: ""
					});
				});
		}
	}
};
</script>
