import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import _ from "lodash";
import "@/assets/scss/tickets.scss";
import ticketSource from "@/lib/backend/tickets";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import clientSource from "@/lib/backend/clients";
import ClientSingle from "@/views/ClientSingle";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import tableFields from "@/lib/misc";
import "vue-awesome/icons/exclamation-circle";
import "vue-awesome/icons/backspace";
import "vue-awesome/icons/book-open";
import "vue-awesome/icons/user";
import "vue-awesome/icons/bell";
import "vue-awesome/icons/sms";
import "vue-awesome/icons/file-pdf";

import { BFormTextarea } from "bootstrap-vue";
Vue.component("b-form-textarea", BFormTextarea);

extend("required", {
	...required,
	message: "This field is required"
});
extend("email", {
	...email,
	message: "Please enter a valid email address"
});

export default {
	components: {
		ClientSingle,
		VueBootstrapTypeahead,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			ticketID: 0,
			ticket: {
				TI_STATUS: "open",
				TI_LANG: "en",
				TI_DEPT: 1,
				TI_ASSIGNEDTO: 1
			},
			statusChanger: 0,
			userChanger: 0,
			languageChanger: 0,
			isBusy: true,
			messages: [],
			statusOptions: tableFields.statusOptions,
			languages: tableFields.languages,
			clients: [],
			clientSearch: "",
			selectedClient: { value: null },
			firstmessage: null
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			token: state => state.auth.token,
			users: state => state.helpers.users,
			departments: state => state.helpers.departments
		})
	},
	watch: {
		clientSearch: _.debounce(function(addr) {
			this.getClients(addr);
		}, 500)
	},

	created: function() {
		this.$store.dispatch("HELPERS_USERS");
		this.$store.dispatch("HELPERS_DEPARTMENTS");
	},

	mounted: function() {
		if (this.$route.params.page) {
			this.currentPage = this.$route.params.page;
		}
	},

	methods: {
		getClients(query) {
			clientSource.getDropdown(query).then(res => {
				const suggestions = res.data;
				this.clients = suggestions == false ? [] : suggestions;
			});
		},

		setClientDataForTicket(data) {
			this.ticket.TI_CLIENTID = data.CL_ID;
			this.ticket.TI_FROMNAME = data.fromname;
			this.ticket.TI_FROMMAIL = data.CL_EMAIL;
			this.ticket.TI_LANG = data.CL_COUNTRY_SHORT;
			ticketSource
				.getLastMessageFromClient(data.CL_ID)
				.then(res => {
					if (res.data) {
						this.ticket.TI_SUBJECT = res.data.MSG_SUBJECT;
						this.ticket.TI_LANG = res.data.TI_LANG;
						this.ticket.TI_DEPT = res.data.TI_DEPT;
					}
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		},

		transmitForm() {
			if (!this.ticket.TI_CLIENTID || parseInt(this.ticket.TI_CLIENTID) < 1) {
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: "Please choose a client or create one",
					position: "toast-top-center",
					type: "error"
				});
				return false;
			}
			this.senddata = {
				TI_DEPT: this.ticket.TI_DEPT,
				TI_PRIO: 1,
				TI_ASSIGNEDTO: this.ticket.TI_ASSIGNEDTO,
				TI_FROMMAIL: this.ticket.TI_FROMMAIL,
				TI_FROMNAME: this.ticket.TI_FROMNAME,
				TI_CLIENTID: this.ticket.TI_CLIENTID,
				TI_BOOKINGID: 0,
				TI_LANG: this.ticket.TI_LANG,
				TI_SUBJECT: this.ticket.TI_SUBJECT,
				TI_STATUS: this.ticket.TI_STATUS,
				TI_SOURCE: "Web",
				TI_ANSWERED: 0,
				TI_SENDREMINDER: 0,
				firstmessage: this.firstmessage
			};
			console.log(this.senddata);
			ticketSource
				.createTicket(this.senddata)
				.then(this.handleNewTicketResponse)
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		handleNewTicketResponse(response) {
			this.ticket.TI_ID = parseInt(response.data.TI_ID);
			if (isNaN(this.ticket.TI_ID)) {
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: "No Ticket available",
					position: "toast-top-center",
					type: "error"
				});
				return false;
			}
			this.$toastr.Add({
				title: "Ticket created", // Toast Title
				msg: "",
				position: "toast-top-center",
				type: "success"
			});
			this.$store.dispatch("TICKETS_REQUEST", { force: true });
			this.$router.push("/tickets");
		},

		addClient(cldata) {
			console.log(cldata);
			this.clients = [
				{
					CL_ID: cldata.CL_ID,
					fromname: cldata.CL_FIRSTNAME + " " + cldata.CL_LASTNAME,
					CL_EMAIL: cldata.CL_EMAIL
				}
			];
			this.$refs.typeahead.inputValue =
				cldata.CL_FIRSTNAME +
				" " +
				cldata.CL_LASTNAME +
				" (" +
				cldata.CL_EMAIL +
				")";
			this.ticket["TI_CLIENTID"] = cldata.CL_ID;
			this.ticket["TI_FROMNAME"] =
				cldata.CL_FIRSTNAME + " " + cldata.CL_FIRSTNAME;
			this.ticket["TI_FROMMAIL"] = cldata.CL_EMAIL;
			this.$bvModal.hide("clientsingle");
			this.$forceUpdate();
		},

		zurueck() {
			this.$router.back();
		}
	}
};
