import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import clientSource from "@/lib/backend/clients";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import AvatarUpload from "@/components/AvatarUpload";
import { BFormGroup, BFormCheckbox } from "bootstrap-vue";
import "vue-awesome/icons/brands/whatsapp-square";

Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-checkbox", BFormCheckbox);

extend("required", {
	...required,
	message: "This field is required"
});
extend("email", {
	...email,
	message: "Please enter a valid email address"
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		AvatarUpload
	},
	props: {
		clid: Number,
		finalize: Function
	},
	watch: {
		clid: {
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				this.getClientData(newVal);
			},
			deep: true
		}
	},
	data() {
		return {
			client: {},
			hasnewsletter: false,
			clientimage: false
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token
		})
	},

	mounted: function() {},
	methods: {
		getClientData(id) {
			let that = this;
			clientSource
				.getSingleClient(id)
				.then(this.handleSingleClientResponse)
				.catch(error => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		handleSingleClientResponse(response) {
			this.client = response.data;
			this.hasnewsletter = this.client.CL_NEWSLETTER == 1;
			this.clientimage = this.client.CL_IMAGE
				? process.env.VUE_APP_API_URL +
				  "/client_pics/thumb/" +
				  this.client.CL_IMAGE
				: false;
			// console.log(this.clientimage)
		},

		transmitForm() {
			if (this.clid && parseInt(this.clid) > 0) {
				this.updateClientData();
			} else {
				this.addClient();
			}
		},

		updateClientData() {
			this.senddata = {
				CL_ID: this.client.CL_ID,
				CL_FIRSTNAME: this.client.CL_FIRSTNAME,
				CL_LASTNAME: this.client.CL_LASTNAME,
				CL_FIRSTNAME2: this.client.CL_FIRSTNAME2,
				CL_LASTNAME2: this.client.CL_LASTNAME2,
				CL_EMAIL: this.client.CL_EMAIL,
				CL_NEWSLETTER: this.hasnewsletter == false ? 0 : 1,
				CL_MOBILE: this.client.CL_MOBILE,
				CL_MOBILE2: this.client.CL_MOBILE2,
				CL_DRIVERLICENSE: this.client.CL_DRIVERLICENSE,
				CL_DRIVERLICENSE2: this.client.CL_DRIVERLICENSE2,
				CL_EXTRA: this.client.CL_EXTRA
			};
			clientSource
				.updateClient(this.senddata)
				.then(this.handleUpdateClientResponse)
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		// eslint-disable-next-line no-unused-vars
		handleUpdateClientResponse(response) {
			// console.log(response);
			this.$store.dispatch("TICKETS_REQUEST", true);

			this.$toastr.Add({
				title: "Success", // Toast Title
				msg: "Client changed",
				position: "toast-top-center",
				type: "success"
			});
			this.$emit("onClientChanged", this.senddata);
		},

		addClient() {
			this.senddata = {
				CL_FIRSTNAME: this.client.CL_FIRSTNAME,
				CL_LASTNAME: this.client.CL_LASTNAME,
				CL_FIRSTNAME2: this.client.CL_FIRSTNAME2,
				CL_LASTNAME2: this.client.CL_LASTNAME2,
				CL_EMAIL: this.client.CL_EMAIL,
				CL_NEWSLETTER: this.hasnewsletter == false ? 0 : 1,
				CL_MOBILE: this.client.CL_MOBILE,
				CL_MOBILE2: this.client.CL_MOBILE2,
				CL_DRIVERLICENSE: this.client.CL_DRIVERLICENSE,
				CL_DRIVERLICENSE2: this.client.CL_DRIVERLICENSE2
			};
			clientSource
				.addClient(this.senddata)
				.then(this.handleAddClientResponse)
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		handleAddClientResponse(response) {
			// console.log(response);
			if (this.finalize) {
				let data = {
					CL_ID: parseInt(response.data),
					CL_FIRSTNAME: this.senddata.CL_FIRSTNAME,
					CL_LASTNAME: this.senddata.CL_LASTNAME,
					CL_EMAIL: this.senddata.CL_EMAIL
				};
				this.finalize(data);
			}
			this.$toastr.Add({
				title: "Success", // Toast Title
				msg: "Client added",
				position: "toast-top-center",
				type: "success"
			});
			this.$bvModal.hide();
		},

		updateClientImageInData(img) {
			this.client.CL_IMAGE = img;
			this.clientimage =
				process.env.VUE_APP_API_URL + "/client_pics/thumb/" + img;
		}
	}
};
