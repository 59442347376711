import Vue from "vue"; //eslint-disable-line no-unused-vars
import bookingSource from "@/lib/backend/bookings";
import clientSource from "@/lib/backend/clients";
import "@/assets/scss/bookings.scss";
import departmentSource from "@/lib/backend/departments";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import tableFields from "@/lib/misc";
import "vue-awesome/icons/times-circle";

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},
	props: {
		BO_ID: [String, Number],
		TICKET: [Object],
		UpdateBoId: Function
	},
	watch: {
		BO_ID: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) {
					console.log("get Booking data for " + newVal);
					this.getBookingData(newVal);
				}
			},
			deep: true
		}
	},
	data() {
		return {
			clEditMode: false,
			booking: { BO_STATUS: null },
			bookingStatusOptions: this.makeBookingStatusOptions(),
			ProviderOptions: [],
			AvailableCarTypesOptions: [],
			AvailableCarOptions: [],
			Freetext1Options: [],
			Freetext2Options: [],
			RiskOptions: [
				"&euro; 0,--",
				"&euro; 900,--",
				"&euro; 500,--",
				"&euro; 300,--"
			],
			InsuranceOptions: ["FDW", "CDW", "CDW+FDW"],
			ChildSeatOptions: [],
			PickupOptions: []
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token
		})
	},
	methods: {
		// :BO_ID="currentBookingId"
		// :TICKET.TI_DEPT="currentDepId"
		// :PDF_URL="currentPdfUrl"

		getBookingData(id) {
			console.log("getting booking data");
			console.log(this.TICKET);
			departmentSource
				.getSelectors(this.TICKET.TI_DEPT, [
					"DEP_CARPROVIDERS",
					"DEP_EXTRA1",
					"DEP_EXTRA2",
					"DEP_PICKUPS",
					"DEP_CHILDSEATS",
					"DEP_INSURANCE",
					"DEP_CARS",
					"DEP_INSURANCE_RISK"
				])
				.then(result => {
					let sels = JSON.parse(JSON.stringify(result.data));
					if (typeof sels.DEP_CARS == "object") {
						this.AvailableCarTypesOptions = sels.DEP_CARS;
					}
					if (typeof sels.DEP_CARPROVIDERS == "object") {
						this.ProviderOptions = sels.DEP_CARPROVIDERS;
						this.ProviderOptions.unshift("-");
					}
					if (typeof sels.DEP_EXTRA1 == "object") {
						this.Freetext1Options = sels.DEP_EXTRA1;
						this.Freetext1Options.unshift("-");
					}
					if (typeof sels.DEP_EXTRA2 == "object") {
						this.Freetext2Options = sels.DEP_EXTRA2;
						this.Freetext2Options.unshift("-");
					}
					if (typeof sels.DEP_PICKUPS == "object") {
						this.PickupOptions = sels.DEP_PICKUPS;
						this.PickupOptions.unshift("-");
					}
					if (typeof sels.DEP_CHILDSEATS == "object") {
						this.ChildSeatOptions = sels.DEP_CHILDSEATS;
						this.ChildSeatOptions.unshift("No");
					}
					if (typeof sels.DEP_INSURANCE == "object") {
						this.InsuranceOptions = sels.DEP_INSURANCE;
						this.InsuranceOptions.unshift("-");
					}
					if (typeof sels.DEP_INSURANCE_RISK == "object") {
						this.RiskOptions = sels.DEP_INSURANCE_RISK;
						this.RiskOptions.unshift("-");
					}
				});
			if (id > 0) {
				bookingSource
					.getSingleBooking(id)
					.then(this.handleSingleBookingResponse)
					.catch(error => {
						this.$toastr.Add({
							title: "Error", // Toast Title
							msg: error,
							position: "toast-top-center",
							type: "error"
						});
					});
			} else {
				this.setValsForNewBooking();
			}
		},

		handleSingleBookingResponse(response) {
			this.booking = response.data;
			this.getAvailableCars();

			console.log("got booking for ", this.booking.BO_ID);
		},

		getAvailableCars() {
			if (this.TICKET.TI_DEPT == "1") {
				bookingSource
					.getFreeCars(
						this.booking.BO_ID,
						this.TICKET.TI_DEPT,
						this.booking.BO_STARTD,
						this.booking.BO_STOPD
					)
					.then(res => {
						// eslint-disable-next-line valid-typeof
						if (typeof res.data == "array" || typeof res.data == "object") {
							let freecars = res.data;
							this.AvailableCarOptions = [{ text: "----", value: null }];
							for (let a in freecars) {
								let group = a;
								let groupoptions = [];
								for (let b in freecars[a]) {
									groupoptions.push({ text: freecars[a][b], value: b });
								}
								this.AvailableCarOptions.push({
									label: "Class " + group,
									options: groupoptions
								});
							}
						} else {
							console.warn(res.data + " is no array");
						}
					})
					.catch(() => {
						console.error("could not retrieve Free cars selector");
					});
			} else {
				this.AvailableCarOptions = [];
			}
		},

		setValsForNewBooking() {
			// console.log("Get booking client for id " + this.TICKET.TI_CLIENTID)

			this.AvailableCarOptions = [];
			this.booking = {
				BO_KIDSEAT: 0,
				BO_INSURANCE: "FDW",
				BO_RISK: "€ 0,-",
				BO_PRICE_EXTRAS: "0",
				BO_DEPOSIT: "0"
			};
			if (this.TICKET.TI_CLIENTID) {
				clientSource.getSingleClient(this.TICKET.TI_CLIENTID).then(res => {
					this.booking["CL_FIRSTNAME"] = res.data.CL_FIRSTNAME;
					this.booking["CL_LASTNAME"] = res.data.CL_LASTNAME;
					this.booking["CL_MOBILE"] = res.data.CL_MOBILE;
					this.booking["CL_EMAIL"] = res.data.CL_EMAIL;
					this.booking["CL_FIRSTNAME2"] = res.data.CL_FIRSTNAME2;
					this.booking["CL_LASTNAME2"] = res.data.CL_LASTNAME2;
					this.booking["CL_MOBILE2"] = res.data.CL_MOBILE2;
					this.booking["CL_EMAIL2"] = res.data.CL_EMAIL2;
					this.booking["CL_DRIVERLICENSE"] = res.data.CL_DRIVERLICENSE;
					this.booking["CL_DRIVERLICENSE2"] = res.data.CL_DRIVERLICENSE2;
					this.booking["BO_KIDSEAT"] = 0;
					this.$forceUpdate();
				});
			}
		},

		makeBookingStatusOptions() {
			let pp = [{ value: null, text: "Please select an option" }];
			for (let i = 0; i < tableFields.bookingStatusOptions.length; i++) {
				pp.push({
					text: tableFields.bookingStatusOptions[i],
					value: i
				});
			}
			return pp;
		},

		transmitForm() {
			this.senddata = {
				BO_CLIENTID: this.TICKET.TI_CLIENTID,
				BO_CAR: this.booking.BO_CAR,
				BO_CARCLASS: this.booking.BO_CARCLASS,
				BO_CARID: this.booking.BO_CARID,
				BO_CHILDSEAT_TEXT: this.booking.BO_CHILDSEAT_TEXT,
				BO_DEPOSIT: this.booking.BO_DEPOSIT,
				BO_EXTRA: this.booking.BO_EXTRA,
				BO_FLIGHTNO: this.booking.BO_FLIGHTNO,
				BO_FREETEXT1: this.booking.BO_FREETEXT1,
				BO_FREETEXT2: this.booking.BO_FREETEXT2,
				BO_HIREDAYS: this.booking.BO_HIREDAYS,
				BO_NAME: this.booking.CL_FIRSTNAME + " " + this.booking.CL_LASTNAME,
				BO_EMAIL: this.booking.CL_EMAIL,
				BO_HOTEL: this.booking.BO_HOTEL,
				BO_INSURANCE: this.booking.BO_INSURANCE,
				BO_KIDSEAT: this.booking.BO_KIDSEAT,
				BO_LOCATION: this.booking.BO_LOCATION,
				BO_PICKUP: this.booking.BO_PICKUP,
				BO_PRICE: this.booking.BO_PRICE,
				BO_PRICE_EXTRAS: this.booking.BO_PRICE_EXTRAS,
				BO_PRICE_SUM: this.booking.BO_PRICE_SUM,
				BO_RETURN: this.booking.BO_RETURN,
				BO_RETURN_TODO: this.booking.BO_RETURN_TODO,
				BO_RISK: this.booking.BO_RISK,
				BO_STARTD: this.booking.BO_STARTD,
				BO_STARTT: this.booking.BO_STARTT,
				BO_STATUS: this.booking.BO_STATUS,
				BO_STOPD: this.booking.BO_STOPD,
				BO_STOPT: this.booking.BO_STOPT,
				BO_TICKETID: this.TICKET.TI_ID
			};
			if (parseInt(this.booking.BO_ID) > 0) {
				this.senddata["BO_ID"] = this.booking.BO_ID;
				bookingSource
					.updateBooking(this.senddata)
					.then(res => {
						if (!res) console.log(this.senddata);
						this.$toastr.Add({
							title: "Booking updated", // Toast Title
							msg: "",
							position: "toast-top-center",
							type: "success"
						});
						// this.$refs["singlebooking"].hide();
						// this.$bvModal.hide()
					})
					.catch(error => {
						// alert("huch");
						this.$toastr.Add({
							title: "Booking update Error", // Toast Title
							msg: error,
							position: "toast-top-center",
							type: "error"
						});
					});
			} else {
				bookingSource
					.createBooking(this.senddata)
					.then(res => {
						this.UpdateBoId(res.data.BO_ID);
						// this.BO_ID = res.data.BO_ID;
						console.log(this.senddata);
						this.$toastr.Add({
							title: "Booking added", // Toast Title
							msg: "",
							position: "toast-top-center",
							type: "success"
						});
						// this.$refs["singlebooking"].hide();
						// this.$bvModal.hide()
					})
					.catch(error => {
						// alert("huch");
						this.$toastr.Add({
							title: "Booking update Error", // Toast Title
							msg: error,
							position: "toast-top-center",
							type: "error"
						});
					});
			}
		},
		toggleEditClient() {
			this.clEditMode = !this.clEditMode;
		},
		EditClient() {
			this.cldata = {
				CL_ID: this.booking.BO_CLIENTID,
				CL_FIRSTNAME: this.booking.CL_FIRSTNAME,
				CL_LASTNAME: this.booking.CL_LASTNAME,
				CL_FIRSTNAME2: this.booking.CL_FIRSTNAME2,
				CL_LASTNAME2: this.booking.CL_LASTNAME2,
				CL_MOBILE: this.booking.CL_MOBILE,
				CL_MOBILE2: this.booking.CL_MOBILE2,
				CL_DRIVERLICENSE: this.booking.CL_DRIVERLICENSE,
				CL_DRIVERLICENSE2: this.booking.CL_DRIVERLICENSE2
			};
			clientSource
				.updateClient(this.cldata)
				// eslint-disable-next-line no-unused-vars
				.then(res => {
					res = "";
					this.$toastr.Add({
						title: "Client updated", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
					this.toggleEditClient();
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Client update Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		onClientChanged(data) {
			this.booking.CL_FIRSTNAME = data.CL_FIRSTNAME;
			this.booking.CL_LASTNAME = data.CL_LASTNAME;
			this.booking.CL_FIRSTNAME2 = data.CL_FIRSTNAME2;
			this.booking.CL_LASTNAME2 = data.CL_LASTNAME2;
			this.booking.CL_EMAIL = data.CL_EMAIL;
			this.booking.CL_MOBILE = data.CL_MOBILE;
			this.booking.CL_DRIVERLICENSE = data.CL_DRIVERLICENSE;
			this.booking.CL_DRIVERLICENSE2 = data.CL_DRIVERLICENSE2;
			this.booking.CL_MOBILE = data.CL_MOBILE;
			console.log(data); // someValue
		},
		makeVoucher() {
			bookingSource
				.makeVoucher(this.BO_ID)
				.then(res => {
					if (res.Error) {
						this.$toastr.e(res.Error, "Error");
					} else {
						this.$toastr.s("Voucher created");
						this.UpdateBoId(this.BO_ID);
						this.$router.go();
					}
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		}
	}
};
