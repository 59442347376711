/* eslint-disable vue/no-unused-vars */
<template>
	<b-modal id="logsingle" hide-footer hide-header size="xl">
		<ValidationObserver v-slot="{ handleSubmit }">
			<b-form @submit.prevent="handleSubmit(transmitForm)">
				<div class="container-fluid content">
					<b-row class="dark">
						<b-col class="headline" v-if="logentry.CL_ID">
							Edit Log Entry
						</b-col>
						<b-col class="headline" v-else>
							New Entry
						</b-col>
					</b-row>
				</div>
				<div class="container-fluid content mt-3">
					<b-row>
						<b-col>
							<b-form-group
								id="CL_PLATE"
								label="Numberplate"
								label-for="CL_PLATE"
							>
								<b-form-select
									class="inner"
									size="sm"
									v-model="logentry.CL_PLATE"
									:options="cars"
								></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group id="CL_STATUS" label="Status" label-for="CL_STATUS">
								<b-form-select
									class="inner"
									size="sm"
									v-model="logentry.CL_STATUS"
									:options="carlog_statusarray"
								></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group
								id="CL_CATEGORY"
								label="Category"
								label-for="CL_CATEGORY"
							>
								<b-form-select
									class="inner"
									size="sm"
									v-model="logentry.CL_CATEGORY"
									:options="carlog_categories"
								></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group id="CL_DUE" label="Due" label-for="CL_DUE">
								<validation-provider rules="required" v-slot="{ errors }">
									<b-form-datepicker
										dark
										size="sm"
										:initial-date="logentry.CL_DUE"
										v-model="logentry.CL_DUE"
										locale="en"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric'
										}"
										@context="onContext"
									></b-form-datepicker>
									<span class="formerror">{{ errors[0] }}</span>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group id="CL_TEXT" label="Text" label-for="CL_TEXT">
								<validation-provider rules="required" v-slot="{ errors }">
									<b-form-textarea
										id="textarea"
										v-model="logentry.CL_TEXT"
										placeholder="Enter something..."
										rows="14"
									>
									</b-form-textarea>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col class="text-center mt-2">
							<b-button v-if="logentry.CL_ID" type="submit" variant="primary"
								>Change</b-button
							>
							<b-button v-else type="submit" variant="primary">Add</b-button>
						</b-col>
					</b-row>
				</div>
			</b-form>
		</ValidationObserver>
		<hr />
	</b-modal>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import dayjs from "dayjs";
import { required } from "vee-validate/dist/rules";
import { BFormGroup, BFormCheckbox } from "bootstrap-vue";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
Vue.component("b-form-group", BFormGroup);
Vue.component("b-form-checkbox", BFormCheckbox);

extend("required", {
	...required,
	message: "This field is required"
});

export default {
	components: {
		ValidationProvider,
		ValidationObserver
	},
	props: {
		logentry: Object,
		logidx: Number,
		carlog_statusarray: Array,
		carlog_categories: Array,
		cars: Array,
		logEdit: Function
	},
	watch: {},
	data() {
		return {
			thislog: {}
		};
	},

	mounted: function() {},
	methods: {
		transmitForm() {
			this.logEdit(this.logentry, this.logidx);
			this.$bvModal.hide();
		},
		onContext(ctx) {
			let selected = ctx.selectedYMD;
			this.logentry.DUEDATE = dayjs(selected, "YYYY-MM-DD").format(
				"DD.MM.YYYY"
			);
			// selectedYMD: "2023-10-26"
		}
	}
};
</script>
