var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "logsingle",
        "hide-footer": "",
        "hide-header": "",
        size: "xl"
      }
    },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.transmitForm)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container-fluid content" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "dark" },
                          [
                            _vm.logentry.CL_ID
                              ? _c("b-col", { staticClass: "headline" }, [
                                  _vm._v(" Edit Log Entry ")
                                ])
                              : _c("b-col", { staticClass: "headline" }, [
                                  _vm._v(" New Entry ")
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "container-fluid content mt-3" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_PLATE",
                                      label: "Numberplate",
                                      "label-for": "CL_PLATE"
                                    }
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "inner",
                                      attrs: { size: "sm", options: _vm.cars },
                                      model: {
                                        value: _vm.logentry.CL_PLATE,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.logentry,
                                            "CL_PLATE",
                                            $$v
                                          )
                                        },
                                        expression: "logentry.CL_PLATE"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_STATUS",
                                      label: "Status",
                                      "label-for": "CL_STATUS"
                                    }
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        options: _vm.carlog_statusarray
                                      },
                                      model: {
                                        value: _vm.logentry.CL_STATUS,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.logentry,
                                            "CL_STATUS",
                                            $$v
                                          )
                                        },
                                        expression: "logentry.CL_STATUS"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_CATEGORY",
                                      label: "Category",
                                      "label-for": "CL_CATEGORY"
                                    }
                                  },
                                  [
                                    _c("b-form-select", {
                                      staticClass: "inner",
                                      attrs: {
                                        size: "sm",
                                        options: _vm.carlog_categories
                                      },
                                      model: {
                                        value: _vm.logentry.CL_CATEGORY,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.logentry,
                                            "CL_CATEGORY",
                                            $$v
                                          )
                                        },
                                        expression: "logentry.CL_CATEGORY"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_DUE",
                                      label: "Due",
                                      "label-for": "CL_DUE"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-datepicker", {
                                                  attrs: {
                                                    dark: "",
                                                    size: "sm",
                                                    "initial-date":
                                                      _vm.logentry.CL_DUE,
                                                    locale: "en",
                                                    "date-format-options": {
                                                      year: "numeric",
                                                      month: "numeric",
                                                      day: "numeric"
                                                    }
                                                  },
                                                  on: {
                                                    context: _vm.onContext
                                                  },
                                                  model: {
                                                    value: _vm.logentry.CL_DUE,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.logentry,
                                                        "CL_DUE",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "logentry.CL_DUE"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_TEXT",
                                      label: "Text",
                                      "label-for": "CL_TEXT"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    id: "textarea",
                                                    placeholder:
                                                      "Enter something...",
                                                    rows: "14"
                                                  },
                                                  model: {
                                                    value: _vm.logentry.CL_TEXT,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.logentry,
                                                        "CL_TEXT",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "logentry.CL_TEXT"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "text-center mt-2" },
                              [
                                _vm.logentry.CL_ID
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [_vm._v("Change")]
                                    )
                                  : _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [_vm._v("Add")]
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("hr")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }