var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "clientsingle",
        "hide-footer": "",
        "hide-header": "",
        size: "xl"
      }
    },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.transmitForm)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "container-fluid content" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "dark" },
                          [
                            _vm.clid
                              ? _c("b-col", { staticClass: "headline" }, [
                                  _vm._v(" Client "),
                                  _c("i", [
                                    _vm._v(
                                      _vm._s(_vm.client.CL_FIRSTNAME) +
                                        " " +
                                        _vm._s(_vm.client.CL_LASTNAME)
                                    )
                                  ]),
                                  _vm._v(
                                    " (ID " + _vm._s(_vm.client.CL_ID) + " ) "
                                  )
                                ])
                              : _c("b-col", { staticClass: "headline" }, [
                                  _vm._v(" New Client ")
                                ]),
                            _vm.clientimage
                              ? _c("b-col", { staticClass: "text-center" }, [
                                  _c("img", {
                                    staticClass: "clientimground",
                                    attrs: { src: _vm.clientimage }
                                  })
                                ])
                              : _vm._e(),
                            _vm.clid
                              ? _c(
                                  "b-col",
                                  { staticClass: "headline text-right" },
                                  [
                                    _vm._v(
                                      " Registered: " +
                                        _vm._s(_vm.client.CL_REGDATE) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "container-fluid content mt-3" },
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", { staticClass: "fett" }, [
                              _vm._v("Client Data")
                            ]),
                            _c("b-col", { staticClass: "fett" }, [
                              _vm._v("Second Client Data")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_FIRSTNAME",
                                      label: "First Name",
                                      "label-for": "CL_FIRSTNAME"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "CL_FIRSTNAME",
                                                    placeholder:
                                                      "Enter first name"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.client.CL_FIRSTNAME,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.client,
                                                        "CL_FIRSTNAME",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "client.CL_FIRSTNAME"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_FIRSTNAME2",
                                      label: "First Name 2",
                                      "label-for": "CL_FIRSTNAME2"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "CL_FIRSTNAME2",
                                        placeholder: "Enter second first name"
                                      },
                                      model: {
                                        value: _vm.client.CL_FIRSTNAME2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.client,
                                            "CL_FIRSTNAME2",
                                            $$v
                                          )
                                        },
                                        expression: "client.CL_FIRSTNAME2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_LASTNAME",
                                      label: "last Name",
                                      "label-for": "CL_LASTNAME"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { rules: "required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "CL_LASTNAME",
                                                    placeholder:
                                                      "Enter last name"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.client.CL_LASTNAME,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.client,
                                                        "CL_LASTNAME",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "client.CL_LASTNAME"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_LASTNAME2",
                                      label: "Last Name 2",
                                      "label-for": "CL_LASTNAME2"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "CL_LASTNAME2",
                                        placeholder: "Enter second last name"
                                      },
                                      model: {
                                        value: _vm.client.CL_LASTNAME2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.client,
                                            "CL_LASTNAME2",
                                            $$v
                                          )
                                        },
                                        expression: "client.CL_LASTNAME2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "5" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_MOBILE",
                                      label: "Mobile No.",
                                      "label-for": "CL_MOBILE"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "CL_MOBILE",
                                        placeholder: "Enter Mobile No."
                                      },
                                      model: {
                                        value: _vm.client.CL_MOBILE,
                                        callback: function($$v) {
                                          _vm.$set(_vm.client, "CL_MOBILE", $$v)
                                        },
                                        expression: "client.CL_MOBILE"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("b-col", { attrs: { cols: "1" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "transparent",
                                    "margin-bottom": "6px"
                                  }
                                },
                                [_vm._v("Placeholder")]
                              ),
                              _vm.client.CL_MOBILE
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://api.whatsapp.com/send?phone=" +
                                          _vm.client.CL_MOBILE,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: {
                                          name: "brands/whatsapp-square",
                                          scale: "2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "b-col",
                              { attrs: { cols: "5" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_MOBILE2",
                                      label: "Mobile No. 2",
                                      "label-for": "CL_MOBILE2"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "CL_MOBILE2",
                                        placeholder: "Enter second Mobile"
                                      },
                                      model: {
                                        value: _vm.client.CL_MOBILE2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.client,
                                            "CL_MOBILE2",
                                            $$v
                                          )
                                        },
                                        expression: "client.CL_MOBILE2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("b-col", { attrs: { cols: "1" } }, [
                              _c(
                                "div",
                                { staticStyle: { color: "transparent" } },
                                [_vm._v("Placeholder")]
                              ),
                              _vm.client.CL_MOBILE2
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://api.whatsapp.com/send?phone=" +
                                          _vm.client.CL_MOBILE2,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        attrs: {
                                          name: "brands/whatsapp-square",
                                          scale: "2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_DRIVERLICENSE",
                                      label: "Driving License",
                                      "label-for": "CL_DRIVERLICENSE"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "CL_DRIVERLICENSE",
                                        placeholder: "Enter Driving License"
                                      },
                                      model: {
                                        value: _vm.client.CL_DRIVERLICENSE,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.client,
                                            "CL_DRIVERLICENSE",
                                            $$v
                                          )
                                        },
                                        expression: "client.CL_DRIVERLICENSE"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_DRIVERLICENSE2",
                                      label: "Driving License 2",
                                      "label-for": "CL_DRIVERLICENSE2"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "CL_DRIVERLICENSE2",
                                        placeholder:
                                          "Enter second Driving License"
                                      },
                                      model: {
                                        value: _vm.client.CL_DRIVERLICENSE2,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.client,
                                            "CL_DRIVERLICENSE2",
                                            $$v
                                          )
                                        },
                                        expression: "client.CL_DRIVERLICENSE2"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_EMAIL",
                                      label: "Email",
                                      "label-for": "CL_EMAIL"
                                    }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: { rules: "email|required" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "CL_EMAIL",
                                                    placeholder:
                                                      "Enter Email address"
                                                  },
                                                  model: {
                                                    value: _vm.client.CL_EMAIL,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.client,
                                                        "CL_EMAIL",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "client.CL_EMAIL"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "formerror" },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "CL_NEWSLETTER",
                                      label: ". ",
                                      "label-for": "CL_NEWSLETTER"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          name: "check-button",
                                          switch: "",
                                          size: "lg"
                                        },
                                        model: {
                                          value: _vm.hasnewsletter,
                                          callback: function($$v) {
                                            _vm.hasnewsletter = $$v
                                          },
                                          expression: "hasnewsletter"
                                        }
                                      },
                                      [_vm._v(" Newsletter ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "CL_EXTRA",
                                    placeholder: "Enter something...",
                                    rows: "2"
                                  },
                                  model: {
                                    value: _vm.client.CL_EXTRA,
                                    callback: function($$v) {
                                      _vm.$set(_vm.client, "CL_EXTRA", $$v)
                                    },
                                    expression: "client.CL_EXTRA"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "text-center mt-2" },
                              [
                                _vm.clid
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [_vm._v("Change")]
                                    )
                                  : _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        }
                                      },
                                      [_vm._v("Add")]
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("hr"),
      _vm.client.CL_ID
        ? _c("AvatarUpload", {
            attrs: { CL_ID: _vm.client.CL_ID, clientimage: _vm.clientimage },
            on: { changepic: _vm.updateClientImageInData }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }