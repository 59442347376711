// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	get() {
		return API.get("/v1/admin/settings");
	},
	getTodoListSettings() {
		return API.get("/v1/admin/settings/todolist_settings");
	},
	getGeneralSettings(dept) {
		return API.get("/v1/admin/settings/general_settings/" + dept);
	},
	getSeasonSettings(dept) {
		return API.get("/v1/admin/settings/seasons_settings/" + dept);
	},
	getPriceSettings(dept, carclass, season) {
		return API.get(
			"/v1/admin/settings/prices_settings/" +
				dept +
				"/" +
				carclass +
				"/" +
				season
		);
	},
	update(data) {
		return API.post("/v1/admin/settings/edit", data);
	},
	updateGeneralSettings(data) {
		return API.post("/v1/admin/settings/edit_general_settings", data);
	},
	updateSeasonSettings(data) {
		return API.post("/v1/admin/settings/edit_season_settings", data);
	},
	addSeasonSettings(data) {
		return API.post("/v1/admin/settings/add_season_settings", data);
	},
	deleteSeasonSettings(seid) {
		return API.post("/v1/admin/settings/delete_season_settings", {
			SE_ID: seid
		});
	},
	setPrice(data) {
		return API.post("/v1/admin/settings/edit_price", data);
	}
};
