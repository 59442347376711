/* eslint-disable no-unused-vars */
import {
	CLIENTS_REQUEST,
	CLIENTS_ERROR,
	CLIENTS_SUCCESS,
	CLIENTS_CHANGE,
	CLIENTS_SETFILTER,
	CLIENTS_CLEARFILTER,
	CLIENTS_REMOVE_ENTRY
} from "../actions/clients";
import clientSource from "@/lib/backend/clients";

// localStorage.removeItem("clients");

const state = {
	isBusy: true,
	showold: JSON.parse(localStorage.getItem("showold")) || 2,
	clients: JSON.parse(localStorage.getItem("clients")) || [],
	filters: JSON.parse(localStorage.getItem("client_filters")) || {}
};

const getters = {
	// showold: state => state.showold,
};

const actions = {
	[CLIENTS_REQUEST]: ({ commit, dispatch }, force) => {
		return new Promise((resolve, reject) => {
			// console.log("REQUEST CLIENTS")
			commit(CLIENTS_REQUEST);
			if (localStorage.getItem("clients") && typeof force == "undefined") {
				// console.log("got clients in localstore")
				commit(CLIENTS_SUCCESS);
				resolve(JSON.parse(localStorage.getItem("clients")));
			} else {
				clientSource
					.getClients()
					.then(resp => {
						localStorage.setItem("clients", JSON.stringify(resp.data));
						commit(CLIENTS_SUCCESS);
						resolve(resp.data);
					})
					.catch(error => {
						reject(error);
					});
			}
		});
	},
	[CLIENTS_CHANGE]: ({ commit, dispatch }, changedata) => {
		commit(CLIENTS_CHANGE, { changedata: changedata });
	},
	[CLIENTS_SETFILTER]: ({ commit, dispatch }, filterdata) => {
		if (filterdata != {}) {
			localStorage.setItem("client_filters", JSON.stringify(filterdata));
			commit(CLIENTS_SETFILTER);
		}
	},
	[CLIENTS_CLEARFILTER]: ({ commit, dispatch }) => {
		localStorage.removeItem("client_filters");
		commit(CLIENTS_CLEARFILTER);
	},
	[CLIENTS_REMOVE_ENTRY]: ({ commit, dispatch }, id) => {
		commit(CLIENTS_REMOVE_ENTRY, { CL_ID: id });
	}
};

const mutations = {
	[CLIENTS_REQUEST]: state => {
		state.isBusy = true;
	},
	[CLIENTS_SUCCESS]: state => {
		state.clients = JSON.parse(localStorage.getItem("clients"));
		// console.log("get clients from localstore: ", state.clients.length)
		state.isBusy = false;
		// console.log("success")
	},
	[CLIENTS_ERROR]: state => {
		state.isBusy = true;
	},
	[CLIENTS_CHANGE]: (state, resp) => {
		let changedata = resp.changedata;
		for (var i in state.clients) {
			if (state.clients[i].TI_ID == changedata.TI_ID) {
				state.clients[i][changedata.field] = changedata.value;
				break; //Stop this loop, we found it!
			}
		}
		localStorage.setItem("clients", JSON.stringify(state.clients));
	},
	[CLIENTS_SETFILTER]: state => {
		state.filters = JSON.parse(localStorage.getItem("client_filters"));
	},
	[CLIENTS_CLEARFILTER]: state => {
		state.filters = {};
	},
	[CLIENTS_REMOVE_ENTRY]: (state, resp) => {
		let dd = JSON.parse(localStorage.getItem("clients"));
		let ctrl = dd.splice(
			dd.findIndex(item => item.CL_ID == resp.CL_ID),
			1
		);
		// console.log("found ", ctrl)
		state.clients = dd;
		localStorage.setItem("clients", JSON.stringify(state.clients));
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
