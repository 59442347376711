<template>
	<b-table
		striped
		bordered
		:items="prices"
		:fields="fields"
		head-variant="light"
		:busy="isBusy"
		primary-key="days"
		small
		tbody-tr-class="tickettable"
	>
		<template v-slot:cell(PR_PRICE)="data">
			<b-form-input
				class="inner"
				size="sm"
				style="width:50%"
				v-model="data.item.PR_PRICE"
				placeholder=""
			></b-form-input>
			<b-btn
				size="sm"
				class="ml-2"
				variant="outline-dark"
				@click="editPrice(data.item, data.index)"
				>Set</b-btn
			>
			<span style="padding-left:10px;vertical-align:bottom;color:green">
				<transition name="fade">
					<v-icon
						v-if="showIcon == data.index"
						name="check-circle"
						scale="1.5"
					/>
				</transition>
			</span>
		</template>
	</b-table>
</template>

<script>
import settingsSource from "@/lib/backend/settings";
import "vue-awesome/icons/check-circle";

export default {
	props: {
		department: String,
		carclass: String,
		season: String
	},
	computed: {
		cid: function() {
			return this.carclass + "-" + this.season;
		}
	},

	watch: {
		cid: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.getPrices(newVal);
			},
			deep: true
		}
	},

	data() {
		return {
			isBusy: false,
			prices: null,
			showIcon: null,
			fields: [
				{
					key: "PR_DAYS",
					label: "Days",
					sortable: false,
					class: "dayfield"
				},
				{
					key: "PR_PRICE",
					label: "Price",
					sortable: false
				}
			]
		};
	},
	created: function() {},

	methods: {
		getPrices() {
			settingsSource
				.getPriceSettings(
					this.department,
					this.carclass.replace(/\+/, "plus"),
					this.season
				)
				.then(res => {
					console.log(
						"grabbed prices for ",
						this.carclass,
						": ",
						res.data.prices
					);
					this.prices = res.data.prices;
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.e(error, "Error");
				});
		},

		editPrice(data, idx) {
			settingsSource
				.setPrice({
					PR_DEPT: this.department,
					PR_GROUP: this.carclass,
					PR_SEASON: this.season,
					PR_DAYS: data.PR_DAYS,
					PR_PRICE: data.PR_PRICE
				})
				// eslint-disable-next-line no-unused-vars
				.then(res => {
					this.showIcon = idx;
					setTimeout(() => (this.showIcon = null), 2000);
					// this.setOk() = res.data.prices;
				})
				.catch(error => {
					this.$toastr.e(error, "Error");
				});
		}
	}
};
</script>
