import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Tickets from "../views/Tickets.vue";
import Clients from "../views/Clients.vue";
import Planner from "../views/Planner.vue";
import Settings from "../views/Settings.vue";
import Stats from "../views/Stats.vue";
import Logs from "../views/Logs.vue";
import Cars from "../views/Cars.vue";
import Mailings from "../views/Mailings.vue";
import TicketSingle from "../views/TicketSingle.vue";
import TicketNew from "../views/TicketNew.vue";
import Login from "../views/Login.vue";
import VueJwtDecode from "vue-jwt-decode";

Vue.use(VueRouter);

if ("scrollRestoration" in window.history) {
	window.history.scrollRestoration = "manual";
}

const checkToken = () => {
	if (
		!localStorage.getItem("userToken") ||
		localStorage.getItem("userToken") == ""
	) {
		return false;
	}
	let decodedToken = localStorage.getItem("userToken")
		? VueJwtDecode.decode(localStorage.getItem("userToken"))
		: "";
	if (!decodedToken || !decodedToken.exp || !decodedToken.context) {
		return false;
	}
	let jetzt = Math.floor(Date.now() / 1000);
	if (jetzt > parseInt(decodedToken.exp)) {
		return false;
	}
	return true;
};

const getAdmin = (to, from, next) => {
	if (checkToken() == false) {
		next("/login");
		return;
	}
	let decodedToken = localStorage.getItem("userToken")
		? VueJwtDecode.decode(localStorage.getItem("userToken"))
		: "";
	let status = decodedToken.context.user.USR_STATUS
		? parseInt(decodedToken.context.user.USR_STATUS)
		: "Not defined";
	if (status != 2) {
		next("/login");
		return;
	}
	localStorage.setItem("userData", JSON.stringify(decodedToken.context.user));
	// console.log(localStorage.getItem("userToken")," = ", decodedToken);
	next();
	return;
};

const getLogger = (to, from, next) => {
	if (checkToken() == false) {
		next("/login");
		return;
	}
	let decodedToken = localStorage.getItem("userToken")
		? VueJwtDecode.decode(localStorage.getItem("userToken"))
		: "";
	let status = decodedToken.context.user.USR_STATUS
		? parseInt(decodedToken.context.user.USR_STATUS)
		: "Not defined";
	if (![2, 3].includes(status)) {
		next("/login");
		return;
	}
	localStorage.setItem("userData", JSON.stringify(decodedToken.context.user));
	next();
	return;
};

const routes = [
	{
		path: "/",
		name: "Home",
		beforeEnter: getAdmin,
		component: Tickets
	},
	{
		path: "/cars",
		name: "Cars",
		beforeEnter: getAdmin,
		component: Cars,
		children: [
			{
				path: ":dept",
				component: Cars
			}
		]
	},

	{
		path: "/planner",
		name: "Planner",
		beforeEnter: getAdmin,
		component: Planner
	},

	{
		path: "/tickets",
		name: "Tickets",
		beforeEnter: getAdmin,
		component: Tickets,
		children: [
			{
				path: "page/:page",
				component: Tickets
			}
		]
	},
	{
		path: "/tickets/details/:id",
		component: TicketSingle
	},
	{
		path: "/tickets/new",
		component: TicketNew
	},
	{
		path: "/clients",
		name: "Clients",
		beforeEnter: getAdmin,
		component: Clients,
		children: [
			{
				path: "page/:page",
				component: Clients
			}
		]
	},
	{
		path: "/mailings",
		name: "Mailings",
		beforeEnter: getAdmin,
		component: Mailings,
		children: [
			{
				path: "page/:page",
				component: Mailings
			}
		]
	},
	{
		path: "/settings",
		name: "Settings",
		beforeEnter: getAdmin,
		component: Settings
	},

	{
		path: "/stats",
		name: "Stats",
		beforeEnter: getAdmin,
		component: Stats
	},

	{
		path: "/logs",
		name: "Logs",
		beforeEnter: getLogger,

		// beforeEnter: (to, from, next) => {
		// 	// ...
		// },
		component: Logs
	},

	{
		path: "/login",
		name: "Login",
		component: Login
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
