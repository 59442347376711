var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid filterbar" },
    [
      _c(
        "b-row",
        { staticClass: "head" },
        [_c("b-col", [_c("h2", [_vm._v("Filter Results")])])],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "4", xl: "2" } },
            [
              _vm._v(" Status: "),
              _c("b-form-select", {
                staticClass: "inner",
                attrs: { size: "sm", options: _vm.statusOptions },
                model: {
                  value: _vm.filters.status,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "status", $$v)
                  },
                  expression: "filters.status"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "4", xl: "2" } },
            [
              _vm._v(" Dept: "),
              _c("b-form-select", {
                staticClass: "inner",
                attrs: { size: "sm", options: _vm.departments },
                on: {
                  change: function($event) {
                    return _vm.$emit("update:filters", _vm.filters)
                  }
                },
                model: {
                  value: _vm.filters.department,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "department", $$v)
                  },
                  expression: "filters.department"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "4", xl: "2" } },
            [
              _vm._v(" Voucher: "),
              _c("b-form-select", {
                staticClass: "inner",
                attrs: { size: "sm", options: _vm.voucherOptions },
                model: {
                  value: _vm.filters.voucher,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "voucher", $$v)
                  },
                  expression: "filters.voucher"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "4", xl: "2" } },
            [
              _vm._v(" Contains: "),
              _c("b-form-input", {
                staticClass: "inner",
                attrs: { size: "sm" },
                model: {
                  value: _vm.filters.contains,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "contains", $$v)
                  },
                  expression: "filters.contains"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "4", xl: "2" } },
            [
              _c(
                "b-btn",
                { attrs: { size: "sm" }, on: { click: _vm.toggleFilters } },
                [_vm._v(_vm._s(_vm.toggleFilterText))]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "2", xl: "1" } },
            [
              _c(
                "b-btn",
                {
                  attrs: { size: "sm", variant: "danger" },
                  on: { click: _vm.removeFilters }
                },
                [_vm._v("Reset Filters")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "2", xl: "1" } },
            [
              _c(
                "b-btn",
                {
                  attrs: { size: "sm", variant: "success" },
                  on: { click: _vm.setNewSearch }
                },
                [
                  _c("v-icon", { attrs: { name: "sync-alt" } }),
                  _vm._v(" Reload ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showExtended
        ? _c(
            "b-row",
            { attrs: { "align-v": "end" } },
            [
              _c(
                "b-col",
                { attrs: { xs: "12", lg: "4", xl: "2" } },
                [
                  _vm._v(" Last msg in "),
                  _c("b-form-select", {
                    attrs: { size: "sm", options: _vm.monthyears },
                    on: {
                      change: function($event) {
                        return _vm.$emit("update:filters", _vm.filters)
                      }
                    },
                    model: {
                      value: _vm.filters.messagedate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "messagedate", $$v)
                      },
                      expression: "filters.messagedate"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", lg: "4", xl: "2" } },
                [
                  _vm._v(" Booking after "),
                  _c("b-form-datepicker", {
                    attrs: {
                      dark: "",
                      size: "sm",
                      "initial-date": _vm.startDate,
                      min: _vm.minDate,
                      max: _vm.maxDate,
                      locale: "en",
                      "date-format-options": {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric"
                      }
                    },
                    model: {
                      value: _vm.filters.fromdate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "fromdate", $$v)
                      },
                      expression: "filters.fromdate"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", lg: "4", xl: "2" } },
                [
                  _vm._v(" Booking before "),
                  _c("b-form-datepicker", {
                    attrs: {
                      dark: "",
                      size: "sm",
                      "initial-date": _vm.endDate,
                      min: _vm.minDate,
                      max: _vm.maxDate,
                      locale: "en",
                      "date-format-options": {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric"
                      }
                    },
                    model: {
                      value: _vm.filters.todate,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "todate", $$v)
                      },
                      expression: "filters.todate"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", lg: "4", xl: "2" } },
                [
                  _vm._v(" Subject: "),
                  _c("b-form-input", {
                    attrs: { size: "sm" },
                    model: {
                      value: _vm.filters.subject,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "subject", $$v)
                      },
                      expression: "filters.subject"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", lg: "4", xl: "2" } },
                [
                  _vm._v(" Message: "),
                  _c("b-form-input", {
                    attrs: { size: "sm" },
                    model: {
                      value: _vm.filters.freetext,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "freetext", $$v)
                      },
                      expression: "filters.freetext"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", lg: "4", xl: "2" } },
                [
                  _c("b", [_vm._v("Show old data")]),
                  _c("b-form-select", {
                    attrs: { size: "sm", options: _vm.showOldOptions },
                    on: { change: _vm.setNewSearch },
                    model: {
                      value: _vm.showolddata,
                      callback: function($$v) {
                        _vm.showolddata = $$v
                      },
                      expression: "showolddata"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }