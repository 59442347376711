<template>
	<b-tab title="Make Todo List">
		<b-card-text>
			<b-row align-h="center">
				<b-col class="mb-3" xs="6" lg="6">
					<div>
						<b-form inline>
							<span style="padding-right:1rem;min-width:80px">Email:</span>
							<b-form-input
								style="width:70%"
								id="Todo_List_Email"
								v-model="fields.Todo_List_Email"
								placeholder=""
							></b-form-input>
							<b-btn
								class="setbutton"
								@click="editSetting('Todo List Email', fields.Todo_List_Email)"
							>
								Set
							</b-btn>
						</b-form>
					</div>
					<div style="margin-top:.75rem">
						<b-form inline>
							<span style="padding-right:1rem;min-width:80px">Days:</span>
							<b-form-input
								style="width:70%"
								id="Todo_List_Days"
								v-model="fields.Todo_List_Days"
								placeholder=""
							></b-form-input>
							<b-btn
								class="setbutton"
								@click="editSetting('Todo List Days', fields.Todo_List_Days)"
							>
								Set
							</b-btn>
						</b-form>
					</div>
				</b-col>
				<b-col class="mb-3" xs="6" lg="3">
					<b-button variant="success" @click="sendTodoList()">Send</b-button>
				</b-col>
			</b-row>
		</b-card-text>
	</b-tab>
</template>

<script>
import "@/assets/scss/stats.scss";
import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import statsSource from "@/lib/backend/stats";
import settingsSource from "@/lib/backend/settings";
import { BCard, BTabs, BTab, BInputGroup, BButtonGroup } from "bootstrap-vue";
Vue.component("b-card", BCard);
Vue.component("b-tabs", BTabs);
Vue.component("b-tab", BTab);
Vue.component("b-button-group", BButtonGroup);
Vue.component("b-input-group", BInputGroup);

export default {
	data() {
		return {
			isBusy: false,
			fields: {
				Todo_List_Email: "",
				Todo_List_Days: null
			}
		};
	},
	created: function() {
		this.isBusy = true;
		settingsSource
			.getTodoListSettings()
			.then(res => {
				this.isBusy = false;
				this.fields = res.data;
			})
			.catch(error => {
				this.isBusy = false;
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: error,
					position: "toast-top-center",
					type: "error"
				});
			});
	},

	methods: {
		editSetting(key, value) {
			let params = {
				S_KEY: key,
				S_VALUE: value
			};
			settingsSource
				.update(params)
				.then(res => {
					if (res.data["Error"]) {
						this.showError(res.data["Error"]);
					} else {
						this.$toastr.Add({
							title: "Settings changed", // Toast Title
							msg: "",
							position: "toast-top-center",
							type: "success"
						});
					}
				})
				.catch(error => {
					this.showError(error);
				});
		},

		sendTodoList() {
			statsSource
				.todoList()
				.then(res => {
					if (res.data["Error"]) {
						this.showError(res.data["Error"]);
					} else if (res.data == "Nothing to do") {
						this.$toastr.Add({
							title: "Nothing to do", // Toast Title
							msg: "",
							position: "toast-top-center",
							type: "warning"
						});
					} else {
						this.$toastr.Add({
							title: "Sent Todo List", // Toast Title
							msg: "",
							position: "toast-top-center",
							type: "success"
						});
					}
				})
				.catch(error => {
					this.showError(error);
				});
		},

		showError(errormessage) {
			this.$toastr.Add({
				title: "Error", // Toast Title
				msg: errormessage,
				position: "toast-top-center",
				type: "error"
			});
		}
	}
};
</script>
