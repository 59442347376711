// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line

	getCarDepartments() {
		return API.get("/v1/admin/cars/departments");
	},
	getCars(DEPID, CARCLASS) {
		return API.post("/v1/admin/cars/" + DEPID, { class: CARCLASS });
	},
	getCarList(DEPID) {
		return API.get("/v1/admin/cars/" + DEPID);
	},
	editCar(data) {
		return API.post("/v1/admin/cars/edit", data);
	},
	addCar(data) {
		return API.post("/v1/admin/cars/add", data);
	},
	hideCar(id) {
		return API.post("/v1/admin/cars/hide/" + id);
	},
	sendCarPic(carid, data) {
		return API.post("/v1/admin/cars/carimage/set/" + carid, data);
	}
};
