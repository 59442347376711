import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/clients.scss";
import clientSource from "@/lib/backend/clients";
import ClientSingle from "@/views/ClientSingle";
import ClientFilters from "@/components/ClientFilters";
import tableFields from "@/lib/misc";
import "vue-awesome/icons/trash-alt";
import { BPaginationNav, BSpinner } from "bootstrap-vue";
Vue.component("b-pagination-nav", BPaginationNav);
Vue.component("b-spinner", BSpinner);
import router from "../router";

export default {
	components: {
		ClientSingle,
		ClientFilters
	},
	data() {
		return {
			filters: {},
			perPage: 20,
			currentPage: 1,
			currentClientId: 0,
			numrows: 0,
			fields: tableFields.clientListFields
		};
	},
	computed: {
		...mapState({
			users: state => state.helpers.users,
			clients: state => state.clients.clients,
			isBusy: state => state.clients.isBusy
		}),
		totalPages: function() {
			// `this` points to the vm instance
			return this.numrows == 0 ? 10 : Math.ceil(this.numrows / this.perPage);
		},
		filteredData: function() {
			if (this.filters == {}) {
				this.numrows = this.clients.length;
				return this.clients;
			} // no filtering
			if (
				Object.keys(this.filters).length === 0 &&
				this.filters.constructor === Object
			) {
				this.numrows = this.clients.length;
				return this.clients;
			}
			var items = this.clients;
			let that = this;
			if (this.filters.tickets) {
				items = items.filter(function(el) {
					if (that.filters.tickets == "y") return parseInt(el.CL_TICKETS) > 0;
					else if (that.filters.tickets == "n")
						return parseInt(el.CL_TICKETS) < 1;
					else return true;
				});
			}
			if (this.filters.vouchers) {
				items = items.filter(function(el) {
					if (that.filters.vouchers == "y") return parseInt(el.CL_VOUCHERS) > 0;
					else if (that.filters.vouchers == "n")
						return parseInt(el.CL_VOUCHERS) < 1;
					else return true;
				});
			}
			if (this.filters.contains) {
				let regex = new RegExp(this.filters.contains, "gi");
				items = items.filter(function(el) {
					return (
						el.CL_EMAIL.match(regex) ||
						el.CL_FIRSTNAME.match(regex) ||
						el.CL_LASTNAME.match(regex)
					);
				});
			}

			this.$store.dispatch("CLIENTS_SETFILTER", this.filters);

			this.numrows = items.length;
			return items;
		}
	},

	watch: {
		filters: {
			handler() {
				// eslint-disable-next-line no-unused-vars
				let pr = "";
				for (let k in this.filters) {
					if (
						this.filters[k] == "undefined" ||
						typeof this.filters[k] == "undefined"
					) {
						delete this.filters[k];
					}
					pr += k + " = " + this.filters[k] + "\n";
				}
			},
			deep: true
		}
	},
	filters: {
		shortenDept: function(value) {
			return value.replace(/IDrive /, "");
		}
	},

	created: function() {
		// IF FORCE THEN USE THIS
		// this.$store.dispatch('TICKETS_REQUEST', {force: true})
		this.$store.dispatch("CLIENTS_REQUEST");
	},

	mounted: function() {
		if (localStorage.getItem("client_filters")) {
			// console.log("we still got filters: ", JSON.parse(localStorage.getItem("client_filters")))
			this.filters = JSON.parse(localStorage.getItem("client_filters"));
		}
		if (this.$route.params.page) {
			this.currentPage = this.$route.params.page;
		}
	},
	methods: {
		linkGen(pageNum) {
			return { path: `/clients/page/${pageNum}` };
		},

		showSingle(id) {
			console.log("clicked id ", id);
			this.currentClientId = id;
			this.$bvModal.show("clientsingle");
		},
		resetFilters() {
			this.filters = {};
			this.$store.dispatch("CLIENTS_CLEARFILTER");
		},
		preDelete(client) {
			let data = { CL_ID: client.CL_ID };
			let text = "Are you sure?";
			if (parseInt(client.CL_TICKETS) > 0) {
				text +=
					"\n- this will delete all tickets connected with this client (" +
					client.CL_TICKETS +
					")";
				data["hastickets"] = true;
			}
			if (parseInt(client.CL_VOUCHERS) > 0) {
				text +=
					"\n- this will delete all vouchers connected with this client (" +
					client.CL_VOUCHERS +
					")";
				data["hasvouchers"] = true;
			}
			if (confirm(text)) {
				clientSource.deleteClient(data).then(res => {
					this.$toastr.Add({
						title: "Client deleted", // Toast Title
						msg: res.data.Message,
						position: "toast-top-center",
						type: "success"
					});

					this.$store.dispatch("CLIENTS_REMOVE_ENTRY", client.CL_ID);
					console.log("Na dann wech!");
				});
			} else {
				console.log("Na dann eben nicht");
			}
		},

		showTickets(clid) {
			console.log("TIKKITS ", clid);
			if (localStorage.getItem("ticket_filters")) {
				this.filters = JSON.parse(localStorage.getItem("ticket_filters"));
				this.filters["clid"] = clid;
				console.log(this.filters);
			} else {
				this.filters = { clid: clid };
			}
			this.$store.dispatch("TICKETS_SETFILTER", this.filters);
			router.push("/tickets");
		}
	}
};
