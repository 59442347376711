var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "contractform",
      attrs: {
        id: "contractform",
        "hide-footer": "",
        "hide-header": "",
        size: "xl"
      }
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.sendForm($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "container-fluid content" },
            [
              _c(
                "b-row",
                { staticClass: "dark" },
                [
                  _vm.booking.BO_ID
                    ? _c("b-col", { staticClass: "headline" }, [
                        _vm._v(
                          " Contract for " +
                            _vm._s(_vm.booking.CL_FIRSTNAME) +
                            " " +
                            _vm._s(_vm.booking.CL_LASTNAME) +
                            " (Voucher #" +
                            _vm._s(_vm.booking.BO_TICKETID) +
                            " ) "
                        ),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.booking.BO_EMAIL) + " ")
                      ])
                    : _c("b-col", { staticClass: "headline" }, [
                        _vm._v(" No Booking available ")
                      ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container-fluid topbar mt-3" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("b", [_vm._v("Client: ")]),
                    _vm._v(
                      _vm._s(_vm.booking.CL_FIRSTNAME) +
                        " " +
                        _vm._s(_vm.booking.CL_LASTNAME) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" Tel.: " + _vm._s(_vm.booking.CL_MOBILE) + " "),
                    _c("br"),
                    _vm._v(
                      " License #: " +
                        _vm._s(_vm.booking.CL_DRIVERLICENSE) +
                        " "
                    )
                  ]),
                  _vm.booking.CL_LASTNAME2
                    ? _c("b-col", [
                        _c("b", [_vm._v("Client 2: ")]),
                        _vm._v(
                          _vm._s(_vm.booking.CL_FIRSTNAME2) +
                            " " +
                            _vm._s(_vm.booking.CL_LASTNAME2) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " Tel.: " + _vm._s(_vm.booking.CL_MOBILE2) + " "
                        ),
                        _c("br"),
                        _vm._v(
                          " License #: " +
                            _vm._s(_vm.booking.CL_DRIVERLICENSE2) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: _vm.CONTRACTID } }, [
                        _vm._v("Contract #")
                      ]),
                      _c("b-form-input", {
                        attrs: { id: "CONTRACTID", placeholder: "ID?" },
                        model: {
                          value: _vm.CONTRACTID,
                          callback: function($$v) {
                            _vm.CONTRACTID = $$v
                          },
                          expression: "CONTRACTID"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("h3", { staticClass: "mt-5" }, [_vm._v("Optional:")]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_NAME,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_NAME", $$v)
                            },
                            expression: "optionalFields.BO_NAME"
                          }
                        },
                        [
                          _vm._v(" Name "),
                          _c("i", [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.booking.CL_FIRSTNAME) +
                                " " +
                                _vm._s(_vm.booking.CL_LASTNAME) +
                                ")"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_LICENSE,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_LICENSE", $$v)
                            },
                            expression: "optionalFields.BO_LICENSE"
                          }
                        },
                        [
                          _vm._v(" Driver License # "),
                          _c("i", [
                            _vm._v(
                              "(" + _vm._s(_vm.booking.CL_DRIVERLICENSE) + ")"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_NAME2,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_NAME2", $$v)
                            },
                            expression: "optionalFields.BO_NAME2"
                          }
                        },
                        [
                          _vm._v(" 2nd Name "),
                          _c("i", [
                            _vm._v(
                              " (" +
                                _vm._s(_vm.booking.CL_FIRSTNAME2) +
                                " " +
                                _vm._s(_vm.booking.CL_LASTNAME2) +
                                ")"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_LICENSE2,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_LICENSE2", $$v)
                            },
                            expression: "optionalFields.BO_LICENSE2"
                          }
                        },
                        [
                          _vm._v(" 2nd Driver License # "),
                          _c("i", [
                            _vm._v(
                              "(" + _vm._s(_vm.booking.CL_DRIVERLICENSE2) + ")"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_CAR,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_CAR", $$v)
                            },
                            expression: "optionalFields.BO_CAR"
                          }
                        },
                        [
                          _vm._v(" Car "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_CAR) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.CAR_PLATE,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "CAR_PLATE", $$v)
                            },
                            expression: "optionalFields.CAR_PLATE"
                          }
                        },
                        [
                          _vm._v(" Number plate "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.CAR_PLATE) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_CHILDSEAT_TEXT,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.optionalFields,
                                "BO_CHILDSEAT_TEXT",
                                $$v
                              )
                            },
                            expression: "optionalFields.BO_CHILDSEAT_TEXT"
                          }
                        },
                        [
                          _vm._v(" Child Seat Text "),
                          _c("i", [
                            _vm._v(
                              "(" + _vm._s(_vm.booking.BO_CHILDSEAT_TEXT) + ")"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_FLIGHTNO,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_FLIGHTNO", $$v)
                            },
                            expression: "optionalFields.BO_FLIGHTNO"
                          }
                        },
                        [
                          _vm._v(" Flight Number "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_FLIGHTNO) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_PRICE,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_PRICE", $$v)
                            },
                            expression: "optionalFields.BO_PRICE"
                          }
                        },
                        [
                          _vm._v(" Price "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_PRICE) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_HOTEL,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_HOTEL", $$v)
                            },
                            expression: "optionalFields.BO_HOTEL"
                          }
                        },
                        [
                          _vm._v(" Hotel "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_HOTEL) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_LOCATION,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_LOCATION", $$v)
                            },
                            expression: "optionalFields.BO_LOCATION"
                          }
                        },
                        [
                          _vm._v(" Location "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_LOCATION) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_INSURANCE,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_INSURANCE", $$v)
                            },
                            expression: "optionalFields.BO_INSURANCE"
                          }
                        },
                        [
                          _vm._v(" Insurance "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_INSURANCE) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_PICKUP,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_PICKUP", $$v)
                            },
                            expression: "optionalFields.BO_PICKUP"
                          }
                        },
                        [
                          _vm._v(" Pickup "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_LOCATION) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_RETURN,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_RETURN", $$v)
                            },
                            expression: "optionalFields.BO_RETURN"
                          }
                        },
                        [
                          _vm._v(" Return "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_RETURN) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_HIREDAYS,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_HIREDAYS", $$v)
                            },
                            expression: "optionalFields.BO_HIREDAYS"
                          }
                        },
                        [
                          _vm._v(" Days "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_HIREDAYS) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_PRICE_SUM,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_PRICE_SUM", $$v)
                            },
                            expression: "optionalFields.BO_PRICE_SUM"
                          }
                        },
                        [
                          _vm._v(" Price(Sum) "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_PRICE_SUM) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_STARTD,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_STARTD", $$v)
                            },
                            expression: "optionalFields.BO_STARTD"
                          }
                        },
                        [
                          _vm._v(" Start Day "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_STARTD) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_STARTT,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_STARTT", $$v)
                            },
                            expression: "optionalFields.BO_STARTT"
                          }
                        },
                        [
                          _vm._v(" Start Time "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_STARTT) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_STOPD,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_STOPD", $$v)
                            },
                            expression: "optionalFields.BO_STOPD"
                          }
                        },
                        [
                          _vm._v(" Stop Day "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_STOPD) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_STOPT,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_STOPT", $$v)
                            },
                            expression: "optionalFields.BO_STOPT"
                          }
                        },
                        [
                          _vm._v(" Stop Time "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.BO_STOPT) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_PHONE,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_PHONE", $$v)
                            },
                            expression: "optionalFields.BO_PHONE"
                          }
                        },
                        [
                          _vm._v(" Phone "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.CL_MOBILE) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.optionalFields.BO_VOUCHERS,
                            callback: function($$v) {
                              _vm.$set(_vm.optionalFields, "BO_VOUCHERS", $$v)
                            },
                            expression: "optionalFields.BO_VOUCHERS"
                          }
                        },
                        [
                          _vm._v(" Vouchers "),
                          _c("i", [
                            _vm._v("(" + _vm._s(_vm.booking.CL_VOUCHERS) + ")")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { switch: "" },
                          model: {
                            value: _vm.showBlank,
                            callback: function($$v) {
                              _vm.showBlank = $$v
                            },
                            expression: "showBlank"
                          }
                        },
                        [_vm._v(" Blank Template ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.CONTRACTID == "",
                            type: "submit",
                            variant: "primary"
                          }
                        },
                        [
                          _vm.CONTRACTID == ""
                            ? _c("span", [_vm._v("No Contract #!")])
                            : _c("span", [_vm._v("Download Contract")])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }