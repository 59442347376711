// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getDepartmentsForSelect() {
		return API.get("/v1/admin/departments/shortlist");
	},
	getDepartmentSelector(depid, type) {
		return API.get(
			"/v1/admin/departments/selectors/?DEP_ID=" + depid + "&type=" + type
		);
	},
	getSelectors(depid, types) {
		return API.get(
			`/v1/admin/departments/multiselectors/?DEP_ID=${depid}&${types
				.map((n, index) => `types[${index}]=${n}`)
				.join("&")}`
		);

		// return API.get("/v1/admin/departments/multiselectors/",{DEP_ID: depid,  types: types });
	}
};
