<template>
	<div class="container-fluid content" id="settings">
		<vue-headful
			:title="`${$target.toUpperCase()} Office | Tickets`"
			description="Ticket System"
		/>
		<h1>Settings</h1>
		<b-tabs card>
			<b-tab title="General Settings" active>
				<b-card-text>
					<b-table
						striped
						bordered
						foot-clone
						hover
						:items="settings"
						:fields="fields"
						head-variant="light"
						:busy="isBusy"
						primary-key="CL_ID"
						small
						tbody-tr-class="settingstable"
					>
						<template v-slot:cell(S_VALUE)="data">
							<b-form inline>
								<b-form-input
									class="inner"
									size="sm"
									:id="data.item.S_KEY"
									v-model="data.item.S_VALUE"
									placeholder=""
								></b-form-input>
								<b-btn
									size="sm"
									class="setbutton"
									@click="editSetting(data.item.S_KEY, data.item.S_VALUE)"
								>
									Set
								</b-btn>
							</b-form>
						</template>

						<template v-slot:table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>&nbsp;&nbsp;&nbsp;Loading...</strong>
							</div>
						</template>
					</b-table>
				</b-card-text>
			</b-tab>
			<b-tab title="Department Settings">
				<b-card-text>
					<b-row>
						<b-col sm="12" lg="3">
							<span style="padding-right:8px">Department:</span
							><b-form-select
								class="inner"
								size="sm"
								v-model="dpt"
								:options="departments"
								@change="show('General')"
							></b-form-select>
						</b-col>
						<b-col sm="4" lg="1">
							<span style="color:transparent">x<br /></span>
							<b-btn
								size="sm"
								@click="show('General')"
								:disabled="dpt.length < 1"
								>General</b-btn
							>
						</b-col>
						<b-col sm="4" lg="1">
							<span style="color:transparent">x<br /></span>
							<b-btn
								size="sm"
								@click="show('Seasons')"
								:disabled="dpt.length < 1"
								>Seasons</b-btn
							>
						</b-col>
						<b-col sm="4" lg="1">
							<span style="color:transparent">x<br /></span>
							<b-btn
								size="sm"
								@click="show('Prices')"
								:disabled="dpt.length < 1"
								>Prices</b-btn
							>
						</b-col>
					</b-row>
					<SettingsGeneral
						v-if="showPart == 'General'"
						:department="department"
					/>
					<SettingsPrices
						v-if="showPart == 'Prices'"
						:department="department"
					/>
					<SettingsSeasons
						v-if="showPart == 'Seasons'"
						:department="department"
					/>
				</b-card-text>
			</b-tab>
			<b-tab title="Users">
				<SettingsUsers />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import settingsSource from "@/lib/backend/settings";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/settings.scss";
import SettingsGeneral from "@/components/SettingsGeneral";
import SettingsPrices from "@/components/SettingsPrices";
import SettingsSeasons from "@/components/SettingsSeasons";
import SettingsUsers from "@/components/SettingsUsers";

export default {
	components: {
		SettingsGeneral,
		SettingsPrices,
		SettingsSeasons,
		SettingsUsers
	},

	computed: {
		...mapState({
			departments: state => state.helpers.departments
		})
	},
	data() {
		return {
			isBusy: false,
			showPart: null,
			settings: [],
			fields: [
				{
					key: "S_KEY",
					label: "Field",
					sortable: true
				},
				{
					key: "S_VALUE",
					label: "Value",
					sortable: false
				}
			],
			dpt: "",
			department: "0"
		};
	},
	created: function() {
		this.$store.dispatch("HELPERS_DEPARTMENTS");
		this.isBusy = true;
		settingsSource
			.get()
			.then(res => {
				this.isBusy = false;
				this.settings = res.data;
			})
			.catch(error => {
				this.isBusy = false;
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: error,
					position: "toast-top-center",
					type: "error"
				});
			});
	},

	methods: {
		editSetting(key, value) {
			let params = {
				S_KEY: key,
				S_VALUE: value
			};
			settingsSource
				.update(params)
				.then(res => {
					if (res.data["Error"]) {
						this.showError(res.data["Error"]);
					} else {
						this.$toastr.Add({
							title: "Settings changed", // Toast Title
							msg: "",
							position: "toast-top-center",
							type: "success"
						});
					}
				})
				.catch(error => {
					this.showError(error);
				});
		},

		show(what) {
			this.$nextTick(() => {
				this.department = this.dpt;
				this.showPart = this.department == "0" ? null : what;
			});
		},

		showError(errormessage) {
			this.$toastr.Add({
				title: "Error", // Toast Title
				msg: errormessage,
				position: "toast-top-center",
				type: "error"
			});
		}
	}
};
</script>
