<template>
	<div class="container-fluid content" id="logs">
		<vue-headful
			:title="`${$target.toUpperCase()} Office | Logs`"
			description="Ticket System"
		/>
		<h1>Logs</h1>
		<b-row>
			<b-col xs="12" lg="2">
				<b-btn type="button" variant="primary" @click="showAddLogEntry()">
					<v-icon name="book-open" />&nbsp;&nbsp;<v-icon
						name="plus"
					/>&nbsp;&nbsp;New
				</b-btn>
				<hr />
				<div class="filterlist">
					Show Newer than
					<b-form-select
						size="sm"
						v-model="pastmonths"
						:options="neweropts"
						@change="getLog"
					></b-form-select>
					<p />
					Car:
					<b-form-select
						size="sm"
						v-model="car_selected"
						:options="cars"
					></b-form-select>
					<p />
					<b-form-group label="Status">
						<b-form-checkbox-group
							v-model="status_selected"
							:options="carlog_statusarray"
							switches
							stacked
						></b-form-checkbox-group>
					</b-form-group>
					<p />
					<b-form-group label="Status">
						<b-form-checkbox-group
							v-model="categories_selected"
							:options="carlog_categories"
							switches
							stacked
						></b-form-checkbox-group>
					</b-form-group>
					<p />
					<hr />
					Sort by
					<b-form-select
						size="sm"
						v-model="sortby"
						:options="sort_opts"
						@change="sortresults"
					></b-form-select>
				</div>
			</b-col>
			<b-col xs="12" lg="10">
				<div>
					<b-row
						class="logentry"
						v-for="(entry, index) in filteredData"
						:key="index"
					>
						<b-col xs="4" lg="3" class="leftcol">
							<span class="numberplate">
								{{ entry.CL_PLATE }}
							</span>
							<p />
							<span class="title">Model</span
							><span class="ttext">{{ entry.CL_MODEL }}</span>
							<br />
							<span class="title">Status</span
							><span :class="'ttext-' + entry.CL_STATUS">{{
								carlog_statusses_bykey[entry.CL_STATUS]
							}}</span>
							<br />
							<span class="title">Kat.</span
							><span class="ttext">{{
								carlog_categories_bykey[entry.CL_CATEGORY]
							}}</span>
						</b-col>
						<b-col xs="8" lg="9" class="rightcol">
							<b-row>
								<b-col xs="12" lg="9">
									<b-row style="margin-bottom:8px">
										<b-col
											><span class="title">Entered:</span>
											{{ entry.STARTDATE }}</b-col
										>
										<b-col class="text-right"
											><span class="title">Due:</span> {{ entry.CL_DUE }}
											{{ entry.DUEDATE }}</b-col
										>
									</b-row>
									{{ entry.CL_TEXT }}
								</b-col>
								<b-col xs="12" lg="3">
									<b-btn block variant="primary" @click="showSingleLog(entry)"
										>Edit</b-btn
									>
									<p />
									<b-btn block variant="danger" @click="showDelete(entry.CL_ID)"
										>Delete</b-btn
									>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</div>
				X
			</b-col>
		</b-row>
		<LogSingle
			:logidx="this.currentLogIdx"
			:logentry="this.currentLogEntry"
			:carlog_statusarray="carlog_statusarray"
			:carlog_categories="carlog_categories"
			:cars="cars"
			:logEdit="edit"
		/>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import LogSingle from "@/components/LogSingle";
import logsSource from "@/lib/backend/logs";
import dayjs from "dayjs";
import misc from "@/lib/misc";
import { BFormCheckboxGroup } from "bootstrap-vue";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "vue-awesome/icons/plus";
import "vue-awesome/icons/book-open";
import "@/assets/scss/logs.scss";
Vue.component("b-form-checkbox-group", BFormCheckboxGroup);

export default {
	components: {
		LogSingle
	},

	computed: {
		...mapState({
			departments: state => state.helpers.departments
		}),
		filteredData: function() {
			var items = this.logs;
			let that = this;
			if (this.status_selected.length > 0) {
				items = items.filter(function(el) {
					return that.status_selected.includes(el.CL_STATUS.toString());
				});
			}
			if (this.categories_selected.length > 0) {
				items = items.filter(function(el) {
					return that.categories_selected.includes(el.CL_CATEGORY.toString());
				});
			}
			if (this.car_selected != null && this.car_selected != "ALL") {
				items = items.filter(function(el) {
					return el.CL_PLATE == that.car_selected;
				});
			}
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.numrows = items.length;
			console.log(items);
			return items;
		}
	},
	watch: {
		filters: {
			handler() {
				for (let k in this.filters) {
					if (
						this.filters[k] == "undefined" ||
						typeof this.filters[k] == "undefined"
					) {
						delete this.filters[k];
					}
				}
			},
			deep: true
		}
	},
	data() {
		return {
			status_selected: [],
			isBusy: false,
			showPart: null,
			logs: [],
			dpt: "",
			cars: [],
			currentLogIdx: null,
			currentLogEntry: {},
			dummyLog: {
				CL_STATUS: 1,
				CL_DUE: dayjs().format("YYYY-MM-DD"),
				CL_DEPT: 1,
				CL_CARID: 0,
				CL_PLATE: "",
				CL_MODEL: "",
				CL_TEXT: "",
				CL_CATEGORY: 0
			},
			sort_opts: [
				{ value: "CL_CD desc", text: "Entered (new first)" },
				{ value: "CL_CD asc", text: "Entered (old first)" },
				{ value: "CL_DUE desc", text: "Due (new first)" },
				{ value: "CL_DUE asc", text: "Due (old first)" }
			],
			sortby: "CL_CD desc",
			car_selected: null,
			categories_selected: [],
			carlog_statusarray: misc.carlog_statusarray,
			carlog_categories: misc.carlog_categories,
			carlog_statusses_bykey: {},
			carlog_categories_bykey: {},
			department: "1",
			pastmonths: 6,
			neweropts: [
				{ value: "6", text: "6 months" },
				{ value: "12", text: "1 year" },
				{ value: "24", text: "2 years" },
				{ value: "36", text: "3 years" },
				{ value: "0", text: "All (SLOW!)" }
			]
		};
	},
	created: function() {
		this.isBusy = true;
		this.getCarList();
		this.getLog();
		for (let ee in this.carlog_statusarray) {
			this.carlog_statusses_bykey[
				this.carlog_statusarray[ee].value
			] = this.carlog_statusarray[ee].text;
		}
		for (let ci in this.carlog_categories) {
			this.carlog_categories_bykey[
				this.carlog_categories[ci].value
			] = this.carlog_categories[ci].text;
		}
	},

	methods: {
		getLog() {
			logsSource
				.get(this.pastmonths)
				.then(res => {
					this.isBusy = false;
					this.logs = res.data;
					this.getSelected();
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		showSingleLog(entry) {
			this.currentLogEntry = entry;
			this.$bvModal.show("logsingle");
		},

		showAddLogEntry() {
			this.currentLogIdx = null;
			this.currentLogEntry = JSON.parse(JSON.stringify(this.dummyLog));
			this.$bvModal.show("logsingle");
		},

		edit(entry, idx) {
			this.$bvModal.hide("logsingle");
			console.log("edit ID " + idx);
			console.log("or ID " + entry.CL_ID);
			// return true;
			console.log(entry);
			if (parseInt(entry.CL_ID) > 0) {
				logsSource
					.edit(entry)
					.then(res => {
						if (res.data["Error"]) {
							this.$toastr.e(res.data["Error"], "Error");
						} else {
							this.logs[idx] = entry;
							this.$toastr.s("", "Log changed");
						}
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			} else {
				console.log("HUi, ", entry);
				logsSource
					.add(entry)
					.then(res => {
						if (res.data["Error"]) {
							this.$toastr.e(res.data["Error"], "Error");
						} else {
							console.log("DATA", res.data[0]);
							this.getLog();
							this.$toastr.s("", "Log entry added");
						}
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			}
		},

		getCarList() {
			logsSource
				.carlist()
				.then(res => {
					this.isBusy = false;
					let plates = res.data;
					// console.log(plates)
					let cc = { ALL: "ALL" };
					for (let i in plates) {
						cc[plates[i]] = plates[i];
					}
					this.cars = Object.keys(cc).sort();
				})
				.catch(error => {
					this.isBusy = false;
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		getSelected() {
			let catt = {};
			let statc = {};
			for (let i in this.logs) {
				catt[this.logs[i].CL_CATEGORY] = this.logs[i].CL_CATEGORY;
				statc[this.logs[i].CL_STATUS] = this.logs[i].CL_STATUS;
			}
			this.status_selected = Object.keys(statc).sort();
			this.categories_selected = Object.keys(catt).sort();
		},
		sortresults(by) {
			if (by == "CL_CD desc") {
				this.filteredData.sort((a, b) => (a.CL_CD < b.CL_CD ? 1 : -1));
			} else if (by == "CL_CD asc") {
				this.filteredData.sort((a, b) => (a.CL_CD > b.CL_CD ? 1 : -1));
			}
			if (by == "CL_DUE desc") {
				this.filteredData.sort((a, b) => (a.CL_DUE < b.CL_DUE ? 1 : -1));
			} else if (by == "CL_DUE asc") {
				this.filteredData.sort((a, b) => (a.CL_DUE > b.CL_DUE ? 1 : -1));
			}
			this.$forceUpdate();
		},

		showDelete(clid) {
			let text = "Are you sure?";
			if (confirm(text)) {
				logsSource
					.delete(clid)
					.then(res => {
						this.$toastr.s(res.data.Message, "Log entry deleted");
						let index = this.logs.findIndex(x => x.CL_ID == clid);
						this.logs.splice(index, 1);
					})
					.catch(error => {
						this.$toastr.e(error, "Could not delete Log entry");
					});
			}
		}
	}
};
</script>
