var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        {
          staticClass: "menuitem h1 mb-2",
          on: {
            mouseover: function($event) {
              _vm.elementname = false
            },
            mouseleave: function($event) {
              _vm.elementname = false
            }
          }
        },
        [
          _c(
            "router-link",
            { staticClass: "icon", attrs: { to: _vm.link, tag: "div" } },
            [_c("b-icon", { attrs: { icon: _vm.icon, id: "icon-tickets" } })],
            1
          ),
          _vm.elementname
            ? _c(
                "div",
                { staticClass: "mtext" },
                [
                  _c("router-link", { attrs: { to: _vm.link } }, [
                    _vm._v(_vm._s(_vm.navname))
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }