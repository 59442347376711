var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid depform" },
    [
      _c("ValidationObserver", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.transmitForm)
                      }
                    }
                  },
                  [
                    _c(
                      "b-row",
                      { staticClass: "head filterbar" },
                      [
                        _c("b-col", [
                          _c("h3", [
                            _vm._v(" Department "),
                            _c("i", [_vm._v(_vm._s(_vm.dep_name))])
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "6" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Incoming Email:")
                            ]),
                            _c("validation-provider", {
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          staticClass: "inner",
                                          staticStyle: { width: "70%" },
                                          attrs: {
                                            size: "sm",
                                            id: "incoming_email",
                                            placeholder: ""
                                          },
                                          model: {
                                            value:
                                              _vm.settings.DEP_EMAIL_INCOMING,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.settings,
                                                "DEP_EMAIL_INCOMING",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "settings.DEP_EMAIL_INCOMING"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "formerror" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "6" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Outgoing Email:")
                            ]),
                            _c("validation-provider", {
                              attrs: { rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-input", {
                                          staticClass: "inner",
                                          staticStyle: { width: "70%" },
                                          attrs: {
                                            size: "sm",
                                            id: "outgoing_email",
                                            placeholder: ""
                                          },
                                          model: {
                                            value:
                                              _vm.settings.DEP_EMAIL_OUTGOING,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.settings,
                                                "DEP_EMAIL_OUTGOING",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "settings.DEP_EMAIL_OUTGOING"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "formerror" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "6" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Pickup/Return Locations")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_PICKUPS,
                                callback: function($$v) {
                                  _vm.$set(_vm.settings, "DEP_PICKUPS", $$v)
                                },
                                expression: "settings.DEP_PICKUPS"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "3" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Childseats")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_CHILDSEATS,
                                callback: function($$v) {
                                  _vm.$set(_vm.settings, "DEP_CHILDSEATS", $$v)
                                },
                                expression: "settings.DEP_CHILDSEATS"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "3" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Providers")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_CARPROVIDERS,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.settings,
                                    "DEP_CARPROVIDERS",
                                    $$v
                                  )
                                },
                                expression: "settings.DEP_CARPROVIDERS"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "6" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("PDF Recipients")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_PDF_RECIPIENTS,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.settings,
                                    "DEP_PDF_RECIPIENTS",
                                    $$v
                                  )
                                },
                                expression: "settings.DEP_PDF_RECIPIENTS"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "3" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Insurance")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_INSURANCE,
                                callback: function($$v) {
                                  _vm.$set(_vm.settings, "DEP_INSURANCE", $$v)
                                },
                                expression: "settings.DEP_INSURANCE"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "3" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Insurance Risk")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_INSURANCE_RISK,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.settings,
                                    "DEP_INSURANCE_RISK",
                                    $$v
                                  )
                                },
                                expression: "settings.DEP_INSURANCE_RISK"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Cars")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_CARS,
                                callback: function($$v) {
                                  _vm.$set(_vm.settings, "DEP_CARS", $$v)
                                },
                                expression: "settings.DEP_CARS"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Default Extra 1:")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_EXTRA1,
                                callback: function($$v) {
                                  _vm.$set(_vm.settings, "DEP_EXTRA1", $$v)
                                },
                                expression: "settings.DEP_EXTRA1"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Default Extra 2:")
                            ]),
                            _c("b-form-textarea", {
                              attrs: { size: "sm", rows: "5" },
                              model: {
                                value: _vm.settings.DEP_EXTRA2,
                                callback: function($$v) {
                                  _vm.$set(_vm.settings, "DEP_EXTRA2", $$v)
                                },
                                expression: "settings.DEP_EXTRA2"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "6" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Autoresponder Subject")
                            ]),
                            _vm._l(_vm.languages, function(lang) {
                              return _vm.settings.DEP_AUTORESPONDER_SUBJECT
                                ? _c(
                                    "div",
                                    { key: lang },
                                    [
                                      _c("p"),
                                      _c(
                                        "span",
                                        { staticClass: "fieldlabel" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/flags/" +
                                                lang +
                                                ".png")
                                            }
                                          })
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "inner",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "sm",
                                          id: "incoming_email",
                                          placeholder: ""
                                        },
                                        model: {
                                          value:
                                            _vm.settings
                                              .DEP_AUTORESPONDER_SUBJECT[lang],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings
                                                .DEP_AUTORESPONDER_SUBJECT,
                                              lang,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "settings.DEP_AUTORESPONDER_SUBJECT[lang]"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }),
                            _c("hr"),
                            _vm._l(_vm.languages, function(lang, index) {
                              return _vm.settings.FOOTERS
                                ? _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "fieldlabel" },
                                        [
                                          _vm._v(" Footer "),
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/flags/" +
                                                lang +
                                                ".png")
                                            }
                                          })
                                        ]
                                      ),
                                      _c("b-form-textarea", {
                                        attrs: { size: "sm", rows: "5" },
                                        model: {
                                          value: _vm.settings.FOOTERS[lang],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings.FOOTERS,
                                              lang,
                                              $$v
                                            )
                                          },
                                          expression: "settings.FOOTERS[lang]"
                                        }
                                      }),
                                      _c("p")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ],
                          2
                        ),
                        _c(
                          "b-col",
                          { attrs: { xs: "12", lg: "6" } },
                          [
                            _c("span", { staticClass: "fieldlabel" }, [
                              _vm._v("Reminder Email")
                            ]),
                            _vm._l(_vm.languages, function(lang, index) {
                              return _vm.settings.DEP_REMINDER_SUBJECT
                                ? _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "fieldlabel" },
                                        [
                                          _vm._v(" Subject "),
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/flags/" +
                                                lang +
                                                ".png")
                                            }
                                          })
                                        ]
                                      ),
                                      _c("b-form-input", {
                                        staticClass: "inner",
                                        staticStyle: { width: "80%" },
                                        attrs: {
                                          size: "sm",
                                          id: "incoming_email",
                                          placeholder: ""
                                        },
                                        model: {
                                          value:
                                            _vm.settings.DEP_REMINDER_SUBJECT[
                                              lang
                                            ],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings.DEP_REMINDER_SUBJECT,
                                              lang,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "settings.DEP_REMINDER_SUBJECT[lang]"
                                        }
                                      }),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "fieldlabel" },
                                        [
                                          _vm._v(" Text "),
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/flags/" +
                                                lang +
                                                ".png")
                                            }
                                          })
                                        ]
                                      ),
                                      _c("b-form-textarea", {
                                        attrs: { size: "sm", rows: "5" },
                                        model: {
                                          value:
                                            _vm.settings.DEP_REMINDER_TEXT[
                                              lang
                                            ],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings.DEP_REMINDER_TEXT,
                                              lang,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "settings.DEP_REMINDER_TEXT[lang]"
                                        }
                                      }),
                                      _c("hr")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-center mb-5" },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  type: "submit",
                                  size: "lg",
                                  variant: "primary"
                                }
                              },
                              [_vm._v("Edit")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }