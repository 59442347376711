export const TICKETS_REQUEST = "TICKETS_REQUEST";
export const TICKETS_SUCCESS = "TICKETS_SUCCESS";
export const TICKETS_ERROR = "TICKETS_ERROR";
export const TICKETS_SHOWOLD = "TICKETS_SHOWOLD";
export const TICKETS_CHANGE = "TICKETS_CHANGE";
export const TICKETS_CHANGE_FIELD = "TICKETS_CHANGE_FIELD";
export const TICKETS_SETFILTER = "TICKETS_SETFILTER";
export const TICKETS_CLEARFILTER = "TICKETS_CLEARFILTER";
export const TICKETS_REMOVE_ENTRY = "TICKETS_REMOVE_ENTRY";
export const TICKETS_SET_USER = "TICKETS_SET_USER";
