import Vue from "vue"; //eslint-disable-line no-unused-vars
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/login.scss";

export default {
	data() {
		return {
			username: "",
			password: "",
			error: "",
			target: process.env.VUE_APP_TARGET
		};
	},

	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata
		})
	},

	created: function() {
		// console.log(this.loggedin, "?")
		// console.log(process.env.VUE_APP_API_URL)
	},

	methods: {
		login: function() {
			let usererror = "";
			const { username, password } = this;
			var that = this;
			let userdata = {};
			this.$store
				.dispatch("AUTH_REQUEST", { username, password })
				.then(result => {
					if (result.loginError) {
						usererror = result.loginError;
						// console.error(result.loginError);
					} else {
						if (localStorage.getItem("userData") == "") {
							usererror = "No matching user data available";
						} else {
							userdata = JSON.parse(localStorage.getItem("userData"));
							if (!userdata.USR_STATUS) {
								usererror = "No matching role found";
							} else if (parseInt(userdata.USR_STATUS) < 1) {
								usererror = "No access for this user";
							}
						}
					}
					if (usererror != "") {
						that.$toastr.Add({
							title: "Error", // Toast Title
							msg: usererror,
							position: "toast-top-center",
							type: "error"
						});
					} else {
						let startpage = localStorage.getItem("lastUrl");
						if (userdata.USR_STATUS == "2") {
							startpage = "/";
						} else if (parseInt(userdata.USR_STATUS) == 3) {
							startpage = "/logs";
						} else {
							startpage = "/login";
						}
						that.$toastr.Add({
							title: "Hooray.", // Toast Title
							msg: "Logged in",
							position: "toast-top-center",
							type: "success"
						});
						that.error = "";
						this.$forceUpdate();
						this.$router.push(startpage);
					}
				})
				.catch(function(error) {
					console.error(error);
					console.error("hier auch nicht");
					let failtxt = "";
					if (error.status) {
						let perr = parseInt(error.status);
						if (perr == 400) {
							failtxt = "No access with this data";
						} else {
							if (error.data.error != "") {
								failtxt = error.data.error;
							} else {
								failtxt = "Server error";
							}
						}
					} else {
						failtxt = "An error occured.";
					}
					that.$toastr.Add({
						title: "Error",
						msg: failtxt,
						position: "toast-top-center",
						type: "error"
					});
					that.email = "";
					that.password = "";
				});
		}
	}
};
