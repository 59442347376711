var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content" },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Tickets",
          description: "Ticket System"
        }
      }),
      _c(
        "b-row",
        [
          _c("b-col", [_c("h1", [_vm._v("Tickets")])]),
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                { attrs: { to: "/tickets/new", variant: "primary" } },
                [_vm._v("New Ticket")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("TicketFilters", {
        attrs: {
          filters: _vm.filters,
          initialDate: _vm.initialDate,
          removeFilters: _vm.resetFilters
        },
        on: {
          "update:filters": function($event) {
            _vm.filters = $event
          }
        }
      }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-pagination-nav", {
                attrs: {
                  size: "sm",
                  "link-gen": _vm.linkGen,
                  "number-of-pages": _vm.totalPages,
                  "aria-controls": "my-table",
                  align: "left",
                  "use-router": ""
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c("b-col", { staticClass: "text-right" }, [
            _vm._v(
              " " +
                _vm._s(_vm.numrows) +
                " Results, Page " +
                _vm._s(_vm.currentPage) +
                " / " +
                _vm._s(_vm.totalPages) +
                " "
            )
          ])
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          striped: "",
          bordered: "",
          "foot-clone": "",
          items: _vm.filteredData,
          fields: _vm.fields,
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
          "head-variant": "light",
          busy: _vm.isBusy,
          "primary-key": "TI_ID",
          small: "",
          "tbody-tr-class": "tickettable"
        },
        scopedSlots: _vm._u([
          {
            key: "cell(TI_ID)",
            fn: function(data) {
              return [
                _c("div", {
                  staticClass: "indicator",
                  class: _vm.IdBackgroundSelect(
                    data.item.TI_STATUS,
                    data.item.TI_ANSWERED
                  )
                }),
                _c(
                  "b-btn",
                  {
                    attrs: {
                      size: "sm",
                      to: "/tickets/details/" + data.item.TI_ID
                    }
                  },
                  [_vm._v(" " + _vm._s(data.item.TI_ID) + " ")]
                )
              ]
            }
          },
          {
            key: "cell(TI_STATUS)",
            fn: function(data) {
              return [
                _vm.statusChanger == data.item.TI_ID
                  ? _c(
                      "div",
                      [
                        _c("b-form-select", {
                          attrs: { size: "sm", options: _vm.statusOptions },
                          on: { change: _vm.updateStatus },
                          model: {
                            value: _vm.currentStatus,
                            callback: function($$v) {
                              _vm.currentStatus = $$v
                            },
                            expression: "currentStatus"
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.setStatusChanger(data.item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(data.item.TI_STATUS) + " ")]
                    )
              ]
            }
          },
          {
            key: "cell(TI_CD)",
            fn: function(data) {
              return [_vm._v(" " + _vm._s(data.value) + " ")]
            }
          },
          {
            key: "cell(DEP_NAME)",
            fn: function(data) {
              return [
                _vm._v(" " + _vm._s(_vm._f("shortenDept")(data.value)) + " ")
              ]
            }
          },
          {
            key: "cell(TI_SUBJECT)",
            fn: function(data) {
              return [
                _c(
                  "router-link",
                  { attrs: { to: "/tickets/details/" + data.item.TI_ID } },
                  [_vm._v(" " + _vm._s(data.item.TI_SUBJECT) + " ")]
                )
              ]
            }
          },
          {
            key: "cell(CL_LASTNAME)",
            fn: function(data) {
              return [
                _c("b-icon", {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.clientsingle",
                      modifiers: { clientsingle: true }
                    }
                  ],
                  staticClass: "rounded-circle bg-primary withhover",
                  staticStyle: { "vertical-align": "middle" },
                  attrs: {
                    icon: "person-fill",
                    variant: "light",
                    "font-scale": "2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setClientId(data.item.TI_CLIENTID)
                    }
                  }
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "vertical-align": "middle",
                      "padding-left": "10px"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(data.item.CL_FIRSTNAME) +
                        " " +
                        _vm._s(data.item.CL_LASTNAME) +
                        " "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "cell(TI_LANG)",
            fn: function(data) {
              return [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/flags/" + data.value + ".png")
                  }
                })
              ]
            }
          },
          {
            key: "cell(BO_STARTD)",
            fn: function(data) {
              return [
                data.item.BO_STARTD && data.item.BO_STOPD
                  ? _c(
                      "div",
                      {
                        staticClass: "withhover",
                        on: {
                          click: function($event) {
                            return _vm.showBookingSingle(
                              data.item.BO_ID,
                              data.item
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(data.item.BO_STARTD) +
                            " - " +
                            _vm._s(data.item.BO_STOPD) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(TI_ASSIGNEDTO)",
            fn: function(data) {
              return [
                _vm.userChanger == data.item.TI_ID
                  ? _c(
                      "div",
                      [
                        _c("b-form-select", {
                          attrs: { size: "sm", options: _vm.users },
                          on: {
                            change: function($event) {
                              return _vm.updateAssignedUser($event, data.index)
                            }
                          },
                          model: {
                            value: _vm.currentAssigned,
                            callback: function($$v) {
                              _vm.currentAssigned = $$v
                            },
                            expression: "currentAssigned"
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.setUserChanger(data.item)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(data.item.USR_LOGIN) + " ")]
                    )
              ]
            }
          },
          {
            key: "cell(MUELL)",
            fn: function(data) {
              return [
                _c("b-icon", {
                  staticClass: "rounded-circle bg-secondary withhover",
                  staticStyle: { "vertical-align": "middle", padding: "4px" },
                  attrs: { icon: "trash", variant: "light", "font-scale": "2" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.preDelete(data.item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "table-busy",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-primary my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _c("strong", [_vm._v(" Loading...")])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-pagination-nav", {
                attrs: {
                  size: "sm",
                  "link-gen": _vm.linkGen,
                  "number-of-pages": _vm.totalPages,
                  "aria-controls": "my-table",
                  align: "center",
                  "use-router": ""
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c("b-col", [
            _vm._v(
              " " +
                _vm._s(_vm.numrows) +
                " Results, Page " +
                _vm._s(_vm.currentPage) +
                " / " +
                _vm._s(_vm.totalPages) +
                " "
            )
          ])
        ],
        1
      ),
      _c("ClientSingle", { attrs: { clid: parseInt(this.currentClientId) } }),
      _c("BookingSingle", {
        attrs: {
          BO_ID: _vm.currentBookingId,
          ",": "",
          TICKET: _vm.currentTicket
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }