import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import clients from "./modules/clients";
import mailings from "./modules/mailings";
import planner from "./modules/planner";
import tickets from "./modules/tickets";
import user from "./modules/user";
import helpers from "./modules/helpers";
import axios from "../lib/backend/axios";

Vue.use(Vuex, axios);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
	modules: {
		user,
		auth,
		helpers,
		mailings,
		planner,
		tickets,
		clients
	},
	strict: debug
});
