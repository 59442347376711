var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loggedin
    ? _c("div", { staticClass: "vertnav" }, [
        _c("div", { staticClass: "logoholder" }, [
          _c("img", {
            staticStyle: { width: "40px" },
            attrs: {
              src: require("@/assets/images/logo_" + _vm.$target + ".svg")
            },
            on: { click: _vm.toggleNav }
          })
        ]),
        _vm.navstatus
          ? _c(
              "div",
              [
                _c("NavElement", {
                  attrs: {
                    link: "/tickets",
                    icon: "receipt",
                    navname: "Tickets",
                    vis: "2"
                  }
                }),
                _c("NavElement", {
                  attrs: {
                    link: "/clients",
                    icon: "person-square",
                    navname: "Clients",
                    vis: "2"
                  }
                }),
                _c("NavElement", {
                  attrs: {
                    link: "/settings",
                    icon: "tools",
                    navname: "Settings",
                    vis: "2"
                  }
                }),
                _c("NavElement", {
                  attrs: {
                    link: "/stats",
                    icon: "pie-chart-fill",
                    navname: "Stats & Data",
                    vis: "2"
                  }
                }),
                _c("NavElement", {
                  attrs: {
                    link: "/cars",
                    icon: "key-fill",
                    navname: "Cars",
                    vis: "2"
                  }
                }),
                _c("NavElement", {
                  attrs: {
                    link: "/planner",
                    icon: "calendar-check-fill",
                    navname: "Planner",
                    vis: "2"
                  }
                }),
                _c("NavElement", {
                  attrs: {
                    link: "/logs",
                    icon: "card-list",
                    navname: "Log",
                    vis: "0"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "menuitem h1 mt-4",
                    on: {
                      mouseover: function($event) {
                        _vm.logoutmenu = true
                      },
                      mouseleave: function($event) {
                        _vm.logoutmenu = false
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon exit" },
                      [
                        _c("v-icon", {
                          attrs: {
                            name: "sign-out-alt",
                            scale: "3",
                            id: "icon-exit"
                          },
                          on: { click: _vm.signout }
                        })
                      ],
                      1
                    ),
                    _vm.logoutmenu
                      ? _c("div", { staticClass: "mtext" }, [
                          _c("a", { on: { click: _vm.signout } }, [
                            _vm._v("Sign out")
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }