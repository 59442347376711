<template>
	<b-modal id="plannerdetail" ref="plannerdetail" size="xl">
		<template #modal-title>
			<div class="headline">
				Booking Details
			</div>
		</template>
		<template #modal-footer>
			<div class="container-fluid mt-3">
				<b-row>
					<b-col xs="6" class="th">
						<b-button
							@click="showBooking(booking.BO_ID, booking.BO_TICKETID)"
							variant="primary"
							>Edit Booking</b-button
						>
					</b-col>
					<b-col xs="6" class="th text-right">
						<b-button
							:to="'/tickets/details/' + booking.BO_TICKETID"
							variant="primary"
							>Edit Ticket</b-button
						>
					</b-col>
				</b-row>
			</div>
		</template>
		<div v-if="isBusy" class="busyblock">
			<b-spinner
				style="width: 5rem; height: 5rem;"
				variant="secondary"
			></b-spinner>
			<p />
			<span class="danger">Waiting....</span>
		</div>
		<div v-else class="container-fluid topbar mt-3">
			<b-row>
				<b-col xs="4" lg="2" class="th">Client</b-col>
				<b-col xs="8" lg="4" class="cont"
					>{{ booking.CL_FIRSTNAME }} {{ booking.CL_LASTNAME }}</b-col
				>
				<b-col xs="4" lg="2" class="th">Ticket #</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_TICKETID }}</b-col>
			</b-row>
			<b-row>
				<b-col xs="4" lg="2" class="th">Phone</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.CL_PHONE }}</b-col>
				<b-col xs="4" lg="2" class="th">Flight No.</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_FLIGHTNO }}</b-col>
			</b-row>
			<b-row>
				<b-col xs="4" lg="2" class="th">Hire Days</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_HIREDAYS }}</b-col>
				<b-col xs="4" lg="2" class="th">Price</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_PRICE }}</b-col>
			</b-row>
			<b-row>
				<b-col xs="4" lg="2" class="th">Hotel</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_HOTEL }}</b-col>
				<b-col xs="4" lg="2" class="th">Location</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_LOCATION }}</b-col>
			</b-row>
			<b-row>
				<b-col xs="4" lg="2" class="th">ChildSeat</b-col>
				<b-col xs="8" lg="4" class="cont">{{
					booking.BO_CHILDSEAT_TEXT
				}}</b-col>
				<b-col xs="4" lg="2" class="th">Sum</b-col>
				<b-col xs="8" lg="4" class="cont">{{ booking.BO_PRICE_SUM }}</b-col>
			</b-row>
			<b-row>
				<b-col xs="4" lg="2" class="th">Car</b-col>
				<b-col xs="8" lg="10" class="cont">{{ booking.BO_CAR }}</b-col>
			</b-row>
			<b-row>
				<b-col xs="4" lg="2" class="th">Remarks</b-col>
				<b-col xs="8" lg="4" class="cont"
					>{{ booking.BO_BO_FREETEXT1 }} {{ booking.BO_BO_FREETEXT2 }}</b-col
				>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import bookingSource from "@/lib/backend/bookings";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/planner.scss";

export default {
	props: {
		boid: [String, Number],
		showBooking: Function
	},
	watch: {
		boid: {
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.getBookingData(newVal);
			},
			deep: true
		}
	},
	data() {
		return {
			isBusy: true,
			booking: { BO_STATUS: null }
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token
		})
	},
	methods: {
		getBookingData(id) {
			if (id > 0) {
				bookingSource
					.getSingleBooking(id)
					.then(result => {
						this.isBusy = false;
						this.booking = result.data;
					})
					.catch(error => {
						this.$toastr.e(error, "Error");
					});
			}
		}
	}
};
</script>
