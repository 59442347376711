var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tab",
    { attrs: { title: "Make Todo List" } },
    [
      _c(
        "b-card-text",
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "6", lg: "6" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "b-form",
                        { attrs: { inline: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "padding-right": "1rem",
                                "min-width": "80px"
                              }
                            },
                            [_vm._v("Email:")]
                          ),
                          _c("b-form-input", {
                            staticStyle: { width: "70%" },
                            attrs: { id: "Todo_List_Email", placeholder: "" },
                            model: {
                              value: _vm.fields.Todo_List_Email,
                              callback: function($$v) {
                                _vm.$set(_vm.fields, "Todo_List_Email", $$v)
                              },
                              expression: "fields.Todo_List_Email"
                            }
                          }),
                          _c(
                            "b-btn",
                            {
                              staticClass: "setbutton",
                              on: {
                                click: function($event) {
                                  return _vm.editSetting(
                                    "Todo List Email",
                                    _vm.fields.Todo_List_Email
                                  )
                                }
                              }
                            },
                            [_vm._v(" Set ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": ".75rem" } },
                    [
                      _c(
                        "b-form",
                        { attrs: { inline: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "padding-right": "1rem",
                                "min-width": "80px"
                              }
                            },
                            [_vm._v("Days:")]
                          ),
                          _c("b-form-input", {
                            staticStyle: { width: "70%" },
                            attrs: { id: "Todo_List_Days", placeholder: "" },
                            model: {
                              value: _vm.fields.Todo_List_Days,
                              callback: function($$v) {
                                _vm.$set(_vm.fields, "Todo_List_Days", $$v)
                              },
                              expression: "fields.Todo_List_Days"
                            }
                          }),
                          _c(
                            "b-btn",
                            {
                              staticClass: "setbutton",
                              on: {
                                click: function($event) {
                                  return _vm.editSetting(
                                    "Todo List Days",
                                    _vm.fields.Todo_List_Days
                                  )
                                }
                              }
                            },
                            [_vm._v(" Set ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "6", lg: "3" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "success" },
                      on: {
                        click: function($event) {
                          return _vm.sendTodoList()
                        }
                      }
                    },
                    [_vm._v("Send")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }