var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content" },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Tickets",
          description: "Ticket System"
        }
      }),
      _c("h1", [_vm._v("Mailings")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-pagination-nav", {
                attrs: {
                  size: "sm",
                  "link-gen": _vm.linkGen,
                  "number-of-pages": _vm.totalPages,
                  "aria-controls": "my-table",
                  align: "left",
                  "use-router": ""
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center" },
            [
              _c(
                "b-button",
                {
                  attrs: { type: "button", variant: "primary" },
                  on: { click: _vm.showNew }
                },
                [_vm._v("New Mailing")]
              )
            ],
            1
          ),
          _c("b-col", { staticClass: "text-right" }, [
            _vm._v(
              " " +
                _vm._s(_vm.numrows) +
                " Results, Page " +
                _vm._s(_vm.currentPage) +
                " / " +
                _vm._s(_vm.totalPages) +
                " "
            )
          ])
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          striped: "",
          bordered: "",
          "foot-clone": "",
          hover: "",
          items: _vm.filteredData,
          fields: _vm.fields,
          "per-page": _vm.perPage,
          "current-page": _vm.currentPage,
          "head-variant": "light",
          busy: _vm.isBusy,
          "primary-key": "MA_ID",
          small: "",
          "tbody-tr-class": "mailingtable"
        },
        on: {
          "row-clicked": function(item, index, event) {
            return _vm.showSingle(item.MA_ID)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "cell(MA_ID)",
            fn: function(data) {
              return [
                _c(
                  "b-btn",
                  {
                    attrs: { size: "sm" },
                    on: {
                      click: function($event) {
                        return _vm.showSingle(data.item.MA_ID)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(data.item.MA_ID) + " ")]
                )
              ]
            }
          },
          {
            key: "cell(MUELL)",
            fn: function(data) {
              return [
                _c("b-icon", {
                  staticClass: "rounded-circle bg-secondary withhover",
                  staticStyle: { "vertical-align": "middle", padding: "4px" },
                  attrs: { icon: "trash", variant: "light", "font-scale": "2" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.preDelete(data.item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "table-busy",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-primary my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _c("strong", [_vm._v(" Loading...")])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-pagination-nav", {
                attrs: {
                  size: "sm",
                  "link-gen": _vm.linkGen,
                  "number-of-pages": _vm.totalPages,
                  "aria-controls": "my-table",
                  align: "center",
                  "use-router": ""
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c("b-col", [
            _vm._v(
              " " +
                _vm._s(_vm.numrows) +
                " Results, Page " +
                _vm._s(_vm.currentPage) +
                " / " +
                _vm._s(_vm.totalPages) +
                " "
            )
          ])
        ],
        1
      ),
      _c("b-btn", { on: { click: _vm.setNewSearch } }, [_vm._v("Reload")]),
      _c("MailingSingle", { attrs: { maid: parseInt(this.currentMailingId) } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }