/* eslint-disable no-unused-vars */
import {
	HELPERS_NAVTOGGLE,
	HELPERS_DEPARTMENTS,
	HELPERS_SETDEPARTMENTS,
	HELPERS_USERS
} from "../actions/helpers";
import departmentSource from "@/lib/backend/departments";
import userSource from "@/lib/backend/users";

const state = {
	departments: JSON.parse(localStorage.getItem("departments")) || {},
	users: JSON.parse(localStorage.getItem("users")) || {},
	navstatus: localStorage.getItem("navstatus") || true
};

const getters = {
	departments: state => state.departments,
	users: state => state.users
};

const actions = {
	[HELPERS_DEPARTMENTS]: ({ commit, dispatch }, user) => {
		if (localStorage.getItem("departments")) {
			commit(HELPERS_SETDEPARTMENTS);
			return JSON.parse(localStorage.getItem("departments"));
		} else {
			departmentSource
				.getDepartmentsForSelect()
				.then(resp => {
					let in_departments = JSON.parse(JSON.stringify(resp.data));
					in_departments.unshift({ text: "---", value: 0 });
					localStorage.setItem("departments", JSON.stringify(in_departments));
					commit(HELPERS_SETDEPARTMENTS);
					return in_departments;
				})
				.catch(error => {
					localStorage.removeItem("departments"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[HELPERS_USERS]: ({ commit, dispatch }, user) => {
		if (localStorage.getItem("users")) {
			commit(HELPERS_USERS, {
				users: JSON.parse(localStorage.getItem("users"))
			});
			return JSON.parse(localStorage.getItem("users"));
		} else {
			// console.log("Fetch users");

			userSource
				.getUsersForSelect()
				.then(resp => {
					localStorage.setItem("users", JSON.stringify(resp.data));
					commit(HELPERS_USERS);
					return resp.data;
				})
				.catch(error => {
					localStorage.removeItem("users"); // if the request fails, remove any possible user token if possible
					return error;
				});
		}
	},
	[HELPERS_NAVTOGGLE]: ({ commit, dispatch }, force) => {
		commit(HELPERS_NAVTOGGLE, force);
	}
};

const mutations = {
	[HELPERS_DEPARTMENTS]: state => {
		state.status = "loading";
	},
	[HELPERS_SETDEPARTMENTS]: state => {
		state.departments = JSON.parse(localStorage.getItem("departments"));
	},
	[HELPERS_USERS]: state => {
		state.users = JSON.parse(localStorage.getItem("users"));
	},
	[HELPERS_NAVTOGGLE]: (state, force) => {
		if (typeof force != "undefined") {
			state.navstatus = force == "1";
		} else {
			state.navstatus = !state.navstatus;
		}
		localStorage.setItem("navstatus", state.navstatus);
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
