<template>
	<div class="container-fluid content">
		<b-row>
			<b-col>
				<!--UPLOAD-->
				<form
					enctype="multipart/form-data"
					novalidate
					v-if="isInitial || isSaving"
				>
					<h3>Upload Avatar</h3>
					<div class="dropbox">
						<input
							type="file"
							multiple
							:name="uploadFieldName"
							:disabled="isSaving"
							@change="
								filesChange($event.target.name, $event.target.files);
								fileCount = $event.target.files.length;
							"
							accept="image/*"
							class="input-file"
						/>
						<p v-if="isInitial">
							Drag your file(s) here to begin<br />
							or click to browse
						</p>
						<p v-if="isSaving">
							Uploading {{ fileCount }} files...<br />&nbsp;.
						</p>
					</div>
				</form>
			</b-col>
			<b-col>
				<!--SUCCESS-->
				<div v-if="isSuccess">
					<h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
					<p>
						<a href="javascript:void(0)" @click="reset()">Upload again</a>
					</p>
					<ul class="list-unstyled">
						<li v-for="item in uploadedFiles" v-bind:key="item.url">
							<img
								:src="item.url"
								class="img-responsive img-thumbnail"
								:alt="item.originalName"
							/>
						</li>
					</ul>
				</div>
				<!--FAILED-->
				<div v-if="isFailed">
					<h2>Uploaded failed.</h2>
					<p>
						<a href="javascript:void(0)" @click="reset()">Try again</a>
					</p>
					<pre>{{ uploadError }}</pre>
				</div>

				<div v-if="uploadedPic" class="text-center">
					<img :src="uploadedPic" style="max-height:120px;" />
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import clientSource from "@/lib/backend/clients";

const STATUS_INITIAL = 0,
	STATUS_SAVING = 1,
	STATUS_SUCCESS = 2,
	STATUS_FAILED = 3;

export default {
	name: "app",
	data() {
		return {
			uploadedFiles: [],
			uploadError: null,
			currentStatus: null,
			uploadFieldName: "clientpic",
			newpic: null
		};
	},
	props: {
		clientimage: [String, Boolean],
		CL_ID: [String, Number]
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		},
		uploadedPic() {
			// Check if we need to reverse the list
			if (this.newpic != null) {
				return this.newpic;
			} else {
				// If not, return the plain list passed in
				return this.clientimage;
			}
		}
	},
	methods: {
		reset() {
			// reset form to initial state
			this.currentStatus = STATUS_INITIAL;
			this.uploadedFiles = [];
			this.uploadError = null;
		},
		save(formData) {
			let that = this;
			// upload data to the server
			this.currentStatus = STATUS_SAVING;
			clientSource
				.sendAvatar(this.CL_ID, formData)
				.then(this.sendAvatarResponse)
				.catch(error => {
					this.currentStatus = STATUS_FAILED;
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		sendAvatarResponse(response) {
			let in_img = response.data.Image;
			this.$emit("changepic", in_img);

			if (!in_img) {
				let error = response.data.Error ? response.data.Error : "unknown Error";
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: error,
					position: "toast-top-center",
					type: "error"
				});
				return false;
			}

			this.currentStatus = STATUS_SUCCESS;
			this.newpic = in_img
				? process.env.VUE_APP_API_URL + "/client_pics/thumb/" + in_img
				: false;
			console.log(response);
		},

		filesChange(fieldName, fileList) {
			// handle file changes
			const formData = new FormData();

			if (!fileList.length) return;

			// append the files to FormData
			Array.from(Array(fileList.length).keys()).map(x => {
				formData.append(fieldName, fileList[x], fileList[x].name);
			});

			// save it
			this.save(formData);
		}
	},
	mounted() {
		this.reset();
	}
};
</script>
<style lang="scss">
.dropbox {
	outline: 2px dashed grey; /* the dash box */
	outline-offset: -10px;
	background: #e1f5d8;
	color: dimgray;
	padding: 0px 10px;
	min-height: 80px; /* minimum height */
	position: relative;
	cursor: pointer;
}

.input-file {
	opacity: 0; /* invisible but it's there! */
	width: 100%;
	height: 80px;
	position: absolute;
	cursor: pointer;
}

.dropbox:hover {
	background: #f3fcef; /* when mouse over to the drop zone, change color */
}

.dropbox p {
	font-size: 1em;
	text-align: center;
	padding: 20px 0;
}
</style>
