<template>
	<div class="container-fluid content" id="stats">
		<vue-headful
			:title="`${$target.toUpperCase()} Office | Tickets`"
			description="Ticket System"
		/>
		<h1>Stats & Data</h1>
		<b-card no-body>
			<b-tabs card justified>
				<ClientExportForm />
				<BookingExportForm />
				<TodoListForm />
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import "@/assets/scss/stats.scss";
import ClientExportForm from "@/components/ClientExportForm";
import BookingExportForm from "@/components/BookingExportForm";
import TodoListForm from "@/components/TodoListForm";

export default {
	components: {
		ClientExportForm,
		BookingExportForm,
		TodoListForm
	}
};
</script>
