<template>
	<div class="container-fluid content">
		<b-row>
			<b-col>
				<!--UPLOAD-->
				<form
					enctype="multipart/form-data"
					novalidate
					v-if="isInitial || isSaving"
				>
					<h3>Upload Car Pic</h3>
					<div class="dropbox">
						<input
							type="file"
							multiple
							:name="uploadFieldName"
							:disabled="isSaving"
							@change="
								filesChange($event.target.name, $event.target.files);
								fileCount = $event.target.files.length;
							"
							accept="image/*"
							class="input-file"
						/>
						<p v-if="isInitial">
							Drag your file(s) here to begin<br />
							or click to browse
						</p>
						<p v-if="isSaving">
							Uploading {{ fileCount }} files...<br />&nbsp;.
						</p>
					</div>
				</form>
			</b-col>
			<b-col>
				<!--SUCCESS-->
				<div v-if="isSuccess">
					<h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
					<p>
						<a href="javascript:void(0)" @click="reset()">Upload again</a>
					</p>
					<ul class="list-unstyled">
						<li v-for="item in uploadedFiles" v-bind:key="item.url">
							<img
								:src="item.url"
								class="img-responsive img-thumbnail"
								:alt="item.originalName"
							/>
						</li>
					</ul>
				</div>
				<!--FAILED-->
				<div v-if="isFailed">
					<h2>Uploaded failed.</h2>
					<p>
						<a href="javascript:void(0)" @click="reset()">Try again</a>
					</p>
					<pre>{{ uploadError }}</pre>
				</div>

				<div v-if="uploadedPic" class="text-center">
					<img :src="pic_baseurl + uploadedPic" style="max-height:240px;" />
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import carSource from "@/lib/backend/cars";
import dayjs from "dayjs";

const STATUS_INITIAL = 0,
	STATUS_SAVING = 1,
	STATUS_SUCCESS = 2,
	STATUS_FAILED = 3;

export default {
	name: "app",
	data() {
		return {
			uploadedFiles: [],
			uploadError: null,
			currentStatus: null,
			uploadFieldName: "carpic",
			newpic: null,
			pic_baseurl: process.env.VUE_APP_API_URL + "/car_pictures/medium/"
		};
	},
	props: {
		carimage: [String, Boolean],
		CAR_ID: [String, Number]
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		},
		uploadedPic() {
			if (this.newpic != null) {
				console.log("NEW PIC HERE " + this.newpic);
				return this.newpic + "?updated=" + dayjs().format("YYYYMMDDhhMMSS");
			} else {
				if (!this.carimage || this.carimage == null) {
					return false;
				}
				console.log("PIC HERE " + this.carimage);
				// If not, return the plain list passed in
				let mediumimage = this.carimage.replace(
					/car_pictures\/thumb/,
					"car_pictures/medium"
				);
				return mediumimage;
			}
		}
	},

	watch: {
		CAR_ID: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) this.reset();
			},
			deep: true
		}
	},

	methods: {
		reset() {
			// reset form to initial state
			// console.log("reset!")
			this.currentStatus = STATUS_INITIAL;
			this.uploadedFiles = [];
			this.uploadError = null;
			this.newpic = null;
		},
		save(formData) {
			let that = this;
			// upload data to the server
			this.currentStatus = STATUS_SAVING;
			carSource
				.sendCarPic(this.CAR_ID, formData)
				.then(this.sendCarPicResponse)
				.catch(error => {
					this.currentStatus = STATUS_FAILED;
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},
		sendCarPicResponse(response) {
			let in_img = response.data.Image;
			this.newpic = in_img ? in_img : false;
			this.$forceUpdate();

			if (!in_img) {
				let error = response.data.Error ? response.data.Error : "unknown Error";
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: error,
					position: "toast-top-center",
					type: "error"
				});
				return false;
			}

			this.currentStatus = STATUS_SUCCESS;
			// console.log(response)
			this.$emit("changepic", in_img);
		},

		filesChange(fieldName, fileList) {
			// handle file changes
			const formData = new FormData();

			if (!fileList.length) return;

			// append the files to FormData
			Array.from(Array(fileList.length).keys()).map(x => {
				formData.append(fieldName, fileList[x], fileList[x].name);
			});

			// save it
			this.save(formData);
		}
	},
	mounted() {
		this.reset();
	}
};
</script>
<style lang="scss">
.dropbox {
	outline: 2px dashed grey; /* the dash box */
	outline-offset: -10px;
	background: #e1f5d8;
	color: dimgray;
	padding: 0px 10px;
	min-height: 80px; /* minimum height */
	position: relative;
	cursor: pointer;
}

.input-file {
	opacity: 0; /* invisible but it's there! */
	width: 100%;
	height: 160px;
	position: absolute;
	cursor: pointer;
}

.dropbox:hover {
	background: #f3fcef; /* when mouse over to the drop zone, change color */
}

.dropbox p {
	font-size: 1em;
	text-align: center;
	padding: 20px 0;
}
</style>
