<template>
	<div class="container-fluid filterbar">
		<b-row class="head">
			<b-col><h2>Filter Results</h2></b-col>
		</b-row>
		<b-row>
			<b-col xs="12" lg="4" xl="2">
				Status:
				<b-form-select
					class="inner"
					size="sm"
					v-model="filters.status"
					:options="statusOptions"
				></b-form-select>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Dept:
				<b-form-select
					class="inner"
					size="sm"
					v-model="filters.department"
					:options="departments"
					@change="$emit('update:filters', filters)"
				></b-form-select>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Voucher:
				<b-form-select
					class="inner"
					size="sm"
					v-model="filters.voucher"
					:options="voucherOptions"
				></b-form-select>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Contains:
				<b-form-input
					class="inner"
					size="sm"
					v-model="filters.contains"
				></b-form-input>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				<b-btn size="sm" @click="toggleFilters">{{ toggleFilterText }}</b-btn>
			</b-col>
			<b-col xs="12" lg="2" xl="1">
				<b-btn size="sm" variant="danger" @click="removeFilters"
					>Reset Filters</b-btn
				>
			</b-col>
			<b-col xs="12" lg="2" xl="1">
				<b-btn size="sm" variant="success" @click="setNewSearch">
					<v-icon name="sync-alt" />
					Reload
				</b-btn>
			</b-col>
		</b-row>
		<b-row v-if="showExtended" align-v="end">
			<b-col xs="12" lg="4" xl="2">
				Last msg in
				<b-form-select
					size="sm"
					v-model="filters.messagedate"
					:options="monthyears"
					@change="$emit('update:filters', filters)"
				></b-form-select>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Booking after
				<b-form-datepicker
					dark
					size="sm"
					:initial-date="startDate"
					v-model="filters.fromdate"
					:min="minDate"
					:max="maxDate"
					locale="en"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric'
					}"
				></b-form-datepicker>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Booking before
				<b-form-datepicker
					dark
					size="sm"
					:initial-date="endDate"
					v-model="filters.todate"
					:min="minDate"
					:max="maxDate"
					locale="en"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric'
					}"
				></b-form-datepicker>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Subject:
				<b-form-input size="sm" v-model="filters.subject"></b-form-input>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				Message:
				<b-form-input size="sm" v-model="filters.freetext"></b-form-input>
			</b-col>
			<b-col xs="12" lg="4" xl="2">
				<b>Show old data</b>
				<b-form-select
					size="sm"
					v-model="showolddata"
					:options="showOldOptions"
					@change="setNewSearch"
				></b-form-select>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import tableFields from "@/lib/misc";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "vue-awesome/icons/sync-alt";

dayjs.extend(relativeTime);
const moreFilterText = "More Filters";
const lessFilterText = "Less Filters";
const maxDate = new Date();
const minDate = dayjs()
	.subtract(3, "year")
	.format("YYYY-MM-DD");
const startDate = dayjs()
	.subtract(6, "month")
	.format("YYYY-MM-DD");

let dropstart = dayjs().subtract(5, "year");
let dropend = dayjs();
let dropper = [];
let interm = null;
while (dropend >= dropstart) {
	interm = dropend.format("MM/YYYY");
	dropper.push({ text: interm, value: dropend.startOf("month") });
	dropend = dropend.subtract(1, "month");
}

export default {
	props: {
		filters: Object,
		initialDate: String,
		removeFilters: Function
	},
	data() {
		return {
			monthyears: dropper,
			showExtended: false,
			voucherOptions: tableFields.voucherOptions,
			statusOptions: tableFields.statusOptions,
			minDate: minDate,
			maxDate: maxDate,
			startDate: startDate,
			endDate: maxDate,
			toggleFilterText: moreFilterText,
			showOldOptions: [
				{ text: "2 years", value: 2 },
				{ text: "3 years", value: 3 },
				{ text: "4 years", value: 4 },
				{ text: "5 years", value: 5 },
				{ text: "all", value: 100 }
			]
		};
	},
	computed: {
		...mapState({
			users: state => state.auth.userdata,
			departments: state => state.helpers.departments,
			showolddata: state => state.tickets.showold
		}),
		showolddata: {
			get() {
				return this.$store.state.tickets.showold;
			},
			set(value) {
				this.$store.dispatch("TICKETS_SHOWOLD", value);
			}
		}
	},
	mounted: function() {
		this.$store.dispatch("HELPERS_DEPARTMENTS");
	},
	methods: {
		toggleFilters() {
			this.showExtended = !this.showExtended;
			this.toggleFilterText = !this.showExtended
				? moreFilterText
				: lessFilterText;
		},
		setNewSearch() {
			this.$store.dispatch("TICKETS_REQUEST", true);

			return true;
		}
	}
};
</script>
