var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content", attrs: { id: "stats" } },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Tickets",
          description: "Ticket System"
        }
      }),
      _c("h1", [_vm._v("Stats & Data")]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-tabs",
            { attrs: { card: "", justified: "" } },
            [
              _c("ClientExportForm"),
              _c("BookingExportForm"),
              _c("TodoListForm")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }