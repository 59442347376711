/* eslint-disable no-unused-vars */
import {
	TICKETS_REQUEST,
	TICKETS_ERROR,
	TICKETS_SUCCESS,
	TICKETS_SHOWOLD,
	TICKETS_CHANGE,
	TICKETS_CHANGE_FIELD,
	TICKETS_SETFILTER,
	TICKETS_CLEARFILTER,
	TICKETS_REMOVE_ENTRY,
	TICKETS_SET_USER
} from "../actions/tickets";
import ticketSource from "@/lib/backend/tickets";

// localStorage.removeItem("tickets");

const state = {
	isBusy: true,
	showold: JSON.parse(localStorage.getItem("showold")) || 2,
	tickets: JSON.parse(localStorage.getItem("tickets")) || [],
	filters: JSON.parse(localStorage.getItem("ticket_filters")) || {}
};

const getters = {
	// showold: state => state.showold,
};

const actions = {
	[TICKETS_REQUEST]: ({ commit, dispatch }, force) => {
		commit(TICKETS_REQUEST);
		if (localStorage.getItem("tickets") && typeof force == "undefined") {
			commit(TICKETS_SUCCESS);
			return JSON.parse(localStorage.getItem("tickets"));
		} else {
			ticketSource
				.getTickets(state.showold)
				.then(resp => {
					localStorage.setItem("tickets", JSON.stringify(resp.data));
					commit(TICKETS_SUCCESS);
					return resp.data;
				})
				.catch(error => {
					return error;
				});
		}
	},
	[TICKETS_SHOWOLD]: ({ commit, dispatch }, showolddata) => {
		localStorage.setItem("showold", showolddata);
		commit(TICKETS_SHOWOLD, { showolddata: showolddata });
	},
	[TICKETS_CHANGE]: ({ commit, dispatch }, changedata) => {
		commit(TICKETS_CHANGE_FIELD, { changedata: changedata });
	},
	[TICKETS_SETFILTER]: ({ commit, dispatch }, filterdata) => {
		if (filterdata != {}) {
			localStorage.setItem("ticket_filters", JSON.stringify(filterdata));
			commit(TICKETS_SETFILTER);
		}
	},
	[TICKETS_CLEARFILTER]: ({ commit, dispatch }) => {
		localStorage.removeItem("ticket_filters");
		commit(TICKETS_CLEARFILTER);
	},
	[TICKETS_REMOVE_ENTRY]: ({ commit, dispatch }, id) => {
		commit(TICKETS_REMOVE_ENTRY, { TI_ID: id });
	},
	[TICKETS_SET_USER]: ({ commit, dispatch }, data) => {
		commit(TICKETS_SET_USER, data);
	}
};

const mutations = {
	[TICKETS_REQUEST]: state => {
		state.isBusy = true;
	},
	[TICKETS_SUCCESS]: state => {
		state.tickets = JSON.parse(localStorage.getItem("tickets"));
		state.isBusy = false;
	},
	[TICKETS_ERROR]: state => {
		state.isBusy = true;
	},
	[TICKETS_SHOWOLD]: (state, resp) => {
		state.showold = resp.showolddata;
	},
	[TICKETS_CHANGE_FIELD]: (state, resp) => {
		let changedata = resp.changedata;
		for (var i in state.tickets) {
			if (state.tickets[i].TI_ID == changedata.TI_ID) {
				state.tickets[i][changedata.field] = changedata.value;
				break; //Stop this loop, we found it!
			}
		}
		localStorage.setItem("tickets", JSON.stringify(state.tickets));
	},
	[TICKETS_SETFILTER]: state => {
		state.filters = JSON.parse(localStorage.getItem("ticket_filters"));
		// console.log(state.filters)
	},
	[TICKETS_CLEARFILTER]: state => {
		state.filters = {};
		console.log(state.filters);
	},
	[TICKETS_REMOVE_ENTRY]: (state, resp) => {
		let dd = JSON.parse(localStorage.getItem("tickets"));
		let ctrl = dd.splice(
			dd.findIndex(item => item.TI_ID == resp.TI_ID),
			1
		);
		console.log("found ", ctrl);
		state.tickets = dd;
		localStorage.setItem("tickets", JSON.stringify(state.tickets));
	},
	[TICKETS_SET_USER]: (state, resp) => {
		state.tickets[resp.idx].USR_LOGIN = resp.USR_LOGIN;
		state.tickets[resp.idx].TI_ASSIGNEDTO = resp.TI_ASSIGNEDTO;
		localStorage.setItem("tickets", JSON.stringify(state.tickets));
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
