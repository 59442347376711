var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid content mt-3",
      staticStyle: { "padding-top": "0", border: "1px solid #ccc" }
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.sendCCPDF($event)
            }
          }
        },
        [
          _c(
            "b-row",
            {
              staticClass: "single-head",
              staticStyle: { "background-color": "#ccc" }
            },
            [
              _c("b-col", { staticClass: "headline" }, [
                _vm._v(" Send Voucher ")
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "CC_TEXT_G",
                        label: "Your text",
                        "label-for": "CC_TEXT"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: { size: "sm", rows: "2" },
                        model: {
                          value: _vm.CC_TEXT,
                          callback: function($$v) {
                            _vm.CC_TEXT = $$v
                          },
                          expression: "CC_TEXT"
                        }
                      })
                    ],
                    1
                  ),
                  _c("p")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "CC_PDF_G",
                        label: "Recipient",
                        "label-for": "CC_PDF"
                      }
                    },
                    [
                      _c("b-form-select", {
                        staticClass: "inner",
                        attrs: { options: _vm.Recipients },
                        model: {
                          value: _vm.CC_PDF,
                          callback: function($$v) {
                            _vm.CC_PDF = $$v
                          },
                          expression: "CC_PDF"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-center mt-2" },
                [
                  _c("span", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Placeholder")
                  ]),
                  _c("br"),
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v(" Send")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "text-center mt-2" },
                [
                  _c("span", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Placeholder")
                  ]),
                  _c("br"),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        href: _vm.CC_VOUCHER,
                        target: "blank",
                        variant: "secondary"
                      }
                    },
                    [_vm._v(" Download")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }