// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getTickets(age) {
		let extra = "";
		if (typeof age != "undefined") {
			extra = "?age=" + parseInt(age);
		}
		return API.get("/v1/admin/tickets/" + extra);
	},
	getTicket(id) {
		return API.get("/v1/admin/tickets/" + id);
	},
	getPhone(id) {
		return API.get("/v1/admin/tickets/getphone/" + id);
	},
	getLastMessageFromClient(clid) {
		return API.get("/v1/admin/tickets/lastmsg/?clid=" + clid);
	},
	setStatus(statusChanger, value) {
		return API.post("/v1/admin/tickets/setStatus", {
			TI_ID: statusChanger,
			TI_STATUS: value
		});
	},
	setAssignedUser(userChanger, value) {
		return API.post("/v1/admin/tickets/setAssignedUser", {
			TI_ID: userChanger,
			TI_ASSIGNEDTO: value
		});
	},
	setSubject(subjectChanger, value) {
		return API.post("/v1/admin/tickets/setSubject", {
			TI_ID: subjectChanger,
			TI_SUBJECT: value
		});
	},
	setSendReminder(TI_ID, value) {
		return API.post("/v1/admin/tickets/setSendReminder", {
			TI_ID: TI_ID,
			TI_SENDREMINDER: value
		});
	},
	setLanguage(TI_ID, language) {
		return API.post("/v1/admin/tickets/setLanguage", {
			TI_ID: TI_ID,
			TI_LANG: language
		});
	},
	parseTemplate(TI_ID, template) {
		return API.post("/v1/admin/tickets/template/", {
			TI_ID: TI_ID,
			tpl: template
		});
	},
	postReply(formdata) {
		return API.post("/v1/admin/tickets/reply", formdata);
	},
	writeWhatsappMessage(TI_ID, data) {
		return API.post("/v1/admin/tickets/writewhatsappmessage", {
			TI_ID: TI_ID,
			message: data
		});
	},
	createTicket(formdata) {
		return API.post("/v1/admin/tickets/create", formdata);
	},
	deleteTicket(data) {
		return API.post("/v1/admin/tickets/delete", data);
	},
	clone(id) {
		return API.post("/v1/admin/tickets/clone", { TI_ID: id });
	}
};
