<template>
	<div id="messages">
		<b-table
			striped
			:items="messages"
			:fields="fields"
			head-variant="light"
			primary-key="MSG_ID"
			small
			tbody-tr-class="messagetable"
			@row-clicked="expandSingleMessage"
		>
			<template v-slot:cell(MSG_ID)="row">
				<div @click="row.toggleDetails" class="toggler">
					<v-icon
						v-if="!row.detailsShowing"
						name="angle-up"
						class="detailbutton"
						scale="1.5"
					/>
					<v-icon
						v-if="row.detailsShowing"
						name="angle-down"
						class="detailbutton"
						scale="1.5"
					/>
				</div>
			</template>
			<template v-slot:cell(MSG_FROM)="data">
				<sendername :row="data.item" />
			</template>

			<template v-slot:row-details="row">
				<b-card>
					<b-row class="mb-5">
						<b-col cols="12" class="messagetext">{{ row.item.MSG_TEXT }}</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-checkbox
								v-model="quotes[row.index]"
								name="check-button"
								switch
								@change="setQuotes"
							>
								<b>Quote</b>
							</b-form-checkbox>
						</b-col>
						<b-col class="text-right">
							<b-button size="sm" @click="row.toggleDetails"
								>Hide Details</b-button
							>
						</b-col>
					</b-row>
				</b-card>
			</template>
		</b-table>
		<b-container fluid class="topbar" id="reply">
			<b-row>
				<b-col cols="1">
					<b>Reply</b>
				</b-col>
				<b-col cols="2">
					<b-form-select
						size="sm"
						v-model="usetemplate"
						:options="templates"
						@change="parseTemplate"
					></b-form-select>
				</b-col>
				<b-col cols="1">
					<b-btn size="sm" @click="parseTemplate">Insert</b-btn>
				</b-col>

				<b-col cols="3" class="text-center">
					<b-button size="sm" variant="success" @click="sendReply(1)"
						>Send And Close</b-button
					>&nbsp;&nbsp;&nbsp;&nbsp;
					<b-button size="sm" variant="success" @click="sendReply(0)"
						>Send Reply</b-button
					>&nbsp;&nbsp;&nbsp;&nbsp;
					<b-button
						v-if="whatsappPhone != ''"
						size="sm"
						variant="success"
						@click="sendWhatsapp()"
						>Send Whatsapp</b-button
					>
				</b-col>

				<b-col cols="5">
					<b-form-group
						id="subjectfield"
						label-cols-sm="2"
						label-cols-lg="1"
						label="Subject"
						label-for="input-subject"
					>
						<b-form-input
							size="sm"
							id="input-horizontal"
							v-model="lastSubject"
							placeholder="Enter a subject"
						></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12">
					<b-form-textarea
						id="textarea"
						v-model="reply"
						placeholder="Enter something..."
						rows="14"
					>
					</b-form-textarea>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<b-form-checkbox
						v-if="PDF_URL"
						v-model="attachpdf"
						name="check-button"
						switch
					>
						<b>Attach PDF</b>
					</b-form-checkbox>
				</b-col>
			</b-row>
		</b-container>
		<div class="bblock"><h1></h1></div>
	</div>
</template>

<script>
import Vue from "vue"; //eslint-disable-line no-unused-vars
import ticketSource from "@/lib/backend/tickets";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
import "@/assets/scss/messages.scss";
import tableFields from "@/lib/misc";
import "vue-awesome/icons/angle-up";
import "vue-awesome/icons/angle-down";

export default {
	props: {
		messages: Array,
		templates: Array,
		PDF_URL: [String, Boolean],
		TI_ID: Number,
		updateTicket: Function
	},
	data() {
		return {
			fields: tableFields.messages,
			quotes: [],
			reply: "",
			whatsappPhone: "",
			attachpdf: false,
			usetemplate: null,
			templatelist: [],
			lastSubject: ""
		};
	},
	watch: {
		TI_ID: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null && newVal != 0) {
					console.log("get Phone for " + newVal);
					this.getPhone(newVal);
				}
			},
			deep: true
		},

		messages: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null && newVal != 0) {
					console.log("get Value for ", newVal);
					this.lastSubject =
						"RE: " +
						newVal[newVal.length - 1].MSG_SUBJECT.replace(
							/\[#[A-Z]*-?\d+\]/g,
							""
						);

					// this.getPhone(newVal);
				}
			},
			deep: true
		}
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token
		})
		// lastSubject: {
		// 	get: function() {
		// 		console.log(this.Subject, "!")
		// 		if (typeof this.messages == "undefined" || this.messages.length == 0)
		// 			return null;
		// 		else if(this.Subject == "") {
		// 			/* OLD REGEX							/\[#\d+\]/,  */
		// 			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		// 			console.log("Subject emtpy")
		// 			this.Subject =
		// 				"RE: " +
		// 				this.messages[this.messages.length - 1].MSG_SUBJECT.replace(
		// 					/\[#[A-Z]*-?\d+\]/g,
		// 					""
		// 				);
		// 		}
		// 		return this.Subject;
		// 	},
		// 	set: function(newval) {
		// 		console.log("set lastSDSubject to " + newval)
		// 		// this.Subject = newval;
		// 		return newval;
		// 	}
		// }
	},
	components: {
		sendername: {
			props: ["row"],
			template: "<div>{{a}}</div>",
			computed: {
				a() {
					// {{ data.item.MSG_FROM }} <{{ data.item.MSG_FROMMAIL }}>

					if (this.row.MSG_FROMMAIL.indexOf("<") > 0) {
						return this.row.MSG_FROMMAIL;
					}
					return this.row.MSG_FROM + " <" + this.row.MSG_FROMMAIL + ">";
				}
			}
		}
	},
	created: function() {
		// IF FORCE THEN USE THIS
		// this.$store.dispatch('TICKETS_REQUEST', {force: true})
	},

	methods: {
		// eslint-disable-next-line no-unused-vars
		expandSingleMessage(item, index, event) {
			item._showDetails = !item._showDetails;
		},
		setQuotes() {
			var inc = this.reply.replace(/> .*\n/g, "");
			this.$nextTick().then(() => {
				this.reply = inc;
				var out = [inc];
				for (let qq in this.quotes) {
					if (this.quotes[qq] == true) {
						let qtext = this.messages[qq].MSG_TEXT.split("\n")
							.map(line => "> " + line)
							.join("\n");
						out.push(qtext);
					}
				}
				console.log(this.quotes);
				this.reply = out.join("") + "\n";
			});
		},

		parseTemplate() {
			let that = this;
			console.log(this.usetemplate);
			if (this.usetemplate == null) {
				this.handleParseResponse({ data: { out: " " } });
				return true;
			}
			ticketSource
				.parseTemplate(this.TI_ID, this.usetemplate)
				.then(this.handleParseResponse)
				.catch(error => {
					that.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		},

		handleParseResponse(response) {
			if (!response.data || !response.data.out) {
				this.$toastr.Add({
					title: "Error", // Toast Title
					msg: "No reply for parsed Template",
					position: "toast-top-center",
					type: "error"
				});
			} else {
				this.reply = response.data.out;
			}
		},

		sendReply(close) {
			let formdata = {
				TI_ID: this.TI_ID,
				Subject: this.lastSubject,
				Message: this.reply,
				attachpdf: this.attachpdf
			};
			ticketSource
				.postReply(formdata)
				// eslint-disable-next-line no-unused-vars
				.then(resp => {
					this.updateTicket();
					// eslint-disable-next-line no-unused-vars
					if (close == 1) {
						ticketSource.setStatus(this.TI_ID, "closed").then(() => {
							this.$store.dispatch("TICKETS_CHANGE", {
								TI_ID: this.TI_ID,
								field: "TI_STATUS",
								value: "closed"
							});
						});
					}
					this.attachpdf = false;
					this.reply = "";
					this.$toastr.Add({
						title: "Message sent", // Toast Title
						msg: "",
						position: "toast-top-center",
						type: "success"
					});
					if (close == 1) {
						this.$router.back();
					}
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
			console.log(formdata);
		},

		sendWhatsapp() {
			let encoded_reply = this.reply.replace(/\n+/g, "%0a");
			let wastring =
				"https://api.whatsapp.com/send/?phone=" +
				this.whatsappPhone +
				"&text=" +
				encoded_reply;

			console.log(encoded_reply);
			// return true;

			ticketSource
				.writeWhatsappMessage(this.TI_ID, this.reply)
				// eslint-disable-next-line no-unused-vars
				.then(resp => {
					this.updateTicket();
					this.reply = "";
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
			window.open(wastring, "_blank").focus();
		},

		getPhone(TI_ID) {
			ticketSource
				.getPhone(TI_ID)
				.then(response => {
					this.whatsappPhone = response.data;
				})
				.catch(error => {
					this.$toastr.Add({
						title: "Error", // Toast Title
						msg: error,
						position: "toast-top-center",
						type: "error"
					});
				});
		}
	}
};
</script>
