// eslint-disable-next-line
import Vue from "vue";
import API from "./axios";
export default {
	// eslint-disable-next-line
	getClients(page, per_page) {
		return API.get("/v1/admin/clients");
	},
	getDropdown(query) {
		return API.get("/v1/admin/clients/query/" + query);
	},
	getSingleClient(clid) {
		return API.get("/v1/admin/clients/" + clid);
	},
	addClient(data) {
		return API.post("/v1/admin/clients/create", data);
	},
	updateClient(data) {
		return API.post("/v1/admin/clients/edit", data);
	},
	deleteClient(data) {
		return API.post("/v1/admin/clients/delete", data);
	},
	sendAvatar(clid, data) {
		return API.post("/v1/admin/clients/clientimage/set/" + clid, data);
	}
};
