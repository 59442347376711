<template>
	<div id="app" :class="bgColor">
		<div id="verticalNav">
			<VerticalNavigation />
		</div>
		<div id="router-content">
			<router-view :key="$route.path"></router-view>
		</div>
	</div>
</template>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
<script>
import VerticalNavigation from "@/components/VerticalNavigation.vue";

export default {
	components: {
		VerticalNavigation
	},
	data() {
		return {
			loggedin: false
		};
	},
	computed: {
		bgColor() {
			return this.$target;
		}
	},

	created: function() {
		this.$store.commit("HELPERS_DEPARTMENTS");
		if (localStorage.getItem("userData") && localStorage.getItem("userToken")) {
			this.$store.commit("AUTH_SUCCESS", {
				userdata: JSON.parse(localStorage.getItem("userData")),
				token: JSON.parse(localStorage.getItem("token"))
			});
			// console.log("set store")
		}

		localStorage.setItem("lastUrl", this.$route.query.page);
	}
};
</script>
